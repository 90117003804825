import React from "react";
import "./spinner.css";

export default function LoadingSpinner() {
  return (
    <div className="spinner-container" style={{height:500+'px',display:"flex",flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
      <div className="loading-spinner"></div>
    </div>
  );
}

