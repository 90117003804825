import React, { useEffect, useState } from "react";
import axios from "axios";
import LoadingSpinner from "../UIComponents/LoadingSpinner";

function QuickQuiz() {
  const [flag, setFlag] = useState(true);
  const [isLoading, setLoading] = useState(false);
  function createQuiz() {
    // const qbid = localStorage.getItem('subid')
    // if (!qbid) {

    //   localStorage.setItem("msg", 'Please Subscribe to Question bank');
    //   window.location.href = "/dashboard";
    // }
    const scode = localStorage.getItem('scode')
    var subs = []
    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    let separator = "/";
    let dt = `${date < 10 ? `0${date}` : date }${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${year}`;
    let tm = 10 * 60;
    const uid = localStorage.getItem('info');
    //console.log(tm);
    var val = localStorage.getItem('quick');
    val = JSON.parse(val);
    var sbdt = []

    var tmM = "Timed";
    var tmsc = parseInt(val.tm) * 60;
    const subData = {
      quiz_name: "Quiz-" + dt,
      mode: 'quick',
      difficulty: ['All'],
      subject: [],
      no_of_question: val.total,
      qstatus: 'All',
      time_mode: "Timed",
      time_type: tmsc,
      total_sec: 'sec',
      qbid: localStorage.getItem('subid'),
      scode: localStorage.getItem('scode'),
    };
    console.log(subData);
    axios.post('https://api.entmcq.com/api/quiz', subData, { headers: { "Authorization": `Bearer ${uid}` } })
      .then((res) => {
        console.log(res);
        //alert("Subject added successfully");
        const data = res.data;
        if (data[0].status == "success") {
          localStorage.setItem('quiz_id', data[0].qid);
          window.location.href = '/dashboard/quiz'
        }
        //alert("Quiz added successfully");
        else {
          alert("Quiz failed");
        }

      })
      .catch((err) => {
        console.log(err);
      })
  }
  useEffect(() => {
    var tscode = localStorage.getItem('scode');
    if (tscode == "no") {
      window.location.href = "/no-subscription";
    }
    else {
      if (!flag) {
        createQuiz();

      }
      setFlag(false)
      console.log(flag);
      //createQuiz();
    }
  },);
  return (
    <React.Fragment>
      <LoadingSpinner />
    </React.Fragment>
  )
}

export default QuickQuiz;