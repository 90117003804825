import React, { useState, useEffect } from 'react';
import Dashboard from "./Dashboard";
import axios from 'axios';
function UserQuiz() {

    function AddLibrary(urlOfTheLibrary) {
        const script = document.createElement('script');
        script.src = urlOfTheLibrary;
        script.async = true;
        document.body.appendChild(script);
    }
    const uid = localStorage.getItem('info');
    const [scode, setScode] = useState('')
    const [quizname, setQuizname] = useState('');
    const [noquestion, setQuestion] = useState('10');
    const [mode, setMode] = useState('');
    const [qstatus, setQstatus] = useState('');
    const [difficulty, setDifficulty] = useState([])
    const [Subjects, setSubjects] = useState([]);
    const [selectSubs, setSelectSubs] = useState([]);
    const [tselectSubs, setTSelectSubs] = useState([]);
    const [secs, setSecs] = useState(10);
    const [tmMode, setTmMode] = useState('');
    const [stype, setStype] = useState('trial');
    const [total, setTotal] = useState(50)
    const [days,setDays] = useState(localStorage.getItem('remaindays'))
    const [lvltotal, setlvltotal] = useState([0, 0, 0]);
    const [statustotal, setstatustotal] = useState([0, 0, 0]);
    const [maxError, setMaxError] = useState(false)
    const [levelcnt, setLevelCnt] = useState([
        { "hard": 4, "moderate": 3, "easy": 3 },
        { "hard": 3, "moderate": 2, "easy": 5 },
        { "hard": 5, "moderate": 3, "easy": 2 },
        { "hard": 3, "moderate": 3, "easy": 4 },
        { "hard": 4, "moderate": 4, "easy": 2 }])
    const [statuscnt, setStatusCnt] = useState([
        { "ans": 4, "wrong": 2, "unsen": 4 },
        { "ans": 2, "wrong": 3, "unsen": 5 },
        { "ans": 3, "wrong": 5, "unsen": 2 },
        { "ans": 2, "wrong": 4, "unsen": 4 },
        { "ans": 4, "wrong": 3, "unsen": 3 }])
    const [catecnt, setCatecnt] = useState([])
    const [anscnt, setanscnt] = useState(() => {
        const data = localStorage.getItem('ans')
        if (data != null) {
            return JSON.parse(data);
        }
        return [];
    })

    var cate_id = 0;

    function fetchSubjects() {
        const sid = localStorage.getItem('subid');
        //console.log(sid);
        axios.get('https://api.entmcq.com/api/fetchCategory')
            .then((res) => {
                const data = res.data;
                setSubjects(data);
                var info = []
                data.map((obj) => {
                    info.push(obj.name);
                })
                setSelectSubs(info);
                console.log(uid);
            })
    }
    function fetchMeta() {
        axios.get('https://api.entmcq.com/api/fetchMeta', { headers: { "Authorization": `Bearer ${uid}` } })
            .then((res) => {
                const data = res.data
                // console.log('info',data[1]);
                setLevelCnt(data);
            })

    }
    function fetchMis() {
        axios.get('https://api.entmcq.com/api/mis-infos', { headers: { "Authorization": `Bearer ${uid}` } })
            .then((res) => {
                const data = res.data;
                console.log(data)
                var lvl = data.lvl;
                setLevelCnt(lvl);
                var st = data.stat;
                setStatusCnt(st);
                var cate = data.cate;
                setCatecnt(data.cate);

                var tm = [0, 0, 0];
                lvl.map((obj) => {
                    tm[0] += obj.hard
                    tm[1] += obj.moderate
                    tm[2] += obj.easy
                })
                setlvltotal(tm)
                tm = [0, 0, 0];
                st.map((obj) => {
                    tm[0] += obj.ans
                    tm[1] += obj.wrong
                    tm[2] += obj.unsen
                })
                setstatustotal(tm);
                var t = 0;
                cate.map((obj) => {
                    t += parseInt(obj);
                })
                setTotal(t);
                fetchSubjects()
            })
    }
    useEffect(() => {
        // const qbid = localStorage.getItem('subid')
        // if(!qbid){

        //     localStorage.setItem("msg",'Please Subscribe to Question bank');
        //     window.location.href="/dashboard";
        // }
        var tscode = localStorage.getItem('scode');
        if (tscode == "no") {
            window.location.href = "/no-subscription";
        }



        fetchMis();
        // fetchMeta();
    }, [])


    const [userinfor, setUserInfor] = useState({

        subject: [],
        response: [],
    });

    const [userinforr, setUserInforr] = useState({

        questionstatus: [],
        response: [],
    });

    const [userinfotime, setUserInfotime] = useState({

        timemode: [],
        response: [],
    });

    const handleDifficulty = (e) => {
        const { value, checked } = e.target;
        const { subject } = userinfor;
        const vl = e.target.value;
        var t = 0
        const info = {
            name: vl
        }
        //console.log(info);
        if (checked) {
            difficulty.push(value)
            setDifficulty(difficulty)
            if (value == "All") {
                t += lvltotal[0] + lvltotal[1] + lvltotal[2]
            }
            else {
                difficulty.map((obj) => {
                    if (obj === "hard") {
                        t += lvltotal[0];
                    }
                    else if (obj === "moderate") {
                        t += lvltotal[1];
                    }
                    else if (obj === "easy") {
                        t += lvltotal[2];
                    }
                })
            }
            setTotal(t);
        }
        else {
            if (difficulty.length > 0) {
                var temp = []

                difficulty.map((v) => {

                    if (v != value) {
                        temp.push(v)
                        if (v === "hard") {
                            t += lvltotal[0];
                        }
                        else if (v === "moderate") {
                            t += lvltotal[1];
                        }
                        else if (v === "easy") {
                            t += lvltotal[2];
                        }

                    }

                })
                setTotal(t);
                setDifficulty(temp)
            }


        }
        console.log(difficulty);
    }
    function handleSubjectType(e) {
        const { value, checked } = e.target;
        if (value == "trial") {
            var t = 0;
            catecnt.map((obj) => {
                t += parseInt(obj);
            })
            setTotal(t);
            var tm = [0, 0, 0];
            levelcnt.map((obj) => {
                tm[0] += obj.hard
                tm[1] += obj.moderate
                tm[2] += obj.easy
            })
            setlvltotal(tm)
            tm = [0, 0, 0];
            statuscnt.map((obj) => {
                tm[0] += obj.ans
                tm[1] += obj.wrong
                tm[2] += obj.unsen
            })
            setstatustotal(tm);
        }
        else {
            setTotal(50);
        }
        if (checked) {

            setStype(value);
        }
        else {
            setStype(value);
        }
    }

    const handleSubjects = (e) => {
        const { value, checked } = e.target;
        var t = 0;
        if (checked) {
            selectSubs.push(value)
            setSelectSubs(selectSubs)
            t = 10 * selectSubs.length
            var tm = [0, 0, 0];
            var tm1 = [0, 0, 0];
            var cnt = 0;
            console.log(selectSubs.length)
            selectSubs.map((obj) => {
                tm[0] += levelcnt[cnt].hard
                tm[1] += levelcnt[cnt].moderate
                tm[2] += levelcnt[cnt].easy

                tm1[0] += statuscnt[cnt].ans
                tm1[1] += statuscnt[cnt].wrong
                tm1[2] += statuscnt[cnt].unsen
                cnt++


            })
            setlvltotal(tm)
            setstatustotal(tm1);
        }
        else {
            if (selectSubs.length > 0) {
                var temp = []
                var tm = [0, 0, 0];
                var tm1 = [0, 0, 0];
                var cnt = 0;
                selectSubs.map((v) => {
                    if (v != value) {
                        temp.push(v)
                        t += 10

                        tm[0] += levelcnt[cnt].hard
                        tm[1] += levelcnt[cnt].moderate
                        tm[2] += levelcnt[cnt].easy

                        tm1[0] += statuscnt[cnt].ans
                        tm1[1] += statuscnt[cnt].wrong
                        tm1[2] += statuscnt[cnt].unsen

                    }
                    cnt++
                })
                setlvltotal(tm)
                setstatustotal(tm1);
                setSelectSubs(temp)
            }
        }
        setTotal(t);
        // console.log(selectSubs);
    }

    const questionstatus = (e) => {

        //const v2=e.target.value;
        //const {questionstatus} = userinforr;
        const v2 = e.target.value;
        setQstatus(v2);


    }
    const timemode = (e) => {
        const { value, checked } = e.target;
        const { timemode } = userinfotime;
        setTmMode(e.target.value);
        //setMode(e.target.value);

    }
    // console.log(userinfo.response);
    // const difficultyinfo=userinfo.response;
    // const subjectinfo=userinfor.response;
    const questionstatueinfo = userinforr.response;
    const timemodeinfo = userinfotime.response;

    function Addquiz() {
        // alert('test');
        //alert(difficultyinfo);

        let tm = parseInt(secs) * 60;
        if (tmMode == "Untimed") {
            tm = 0
        }
        var sdt = ""
        console.log(tm);
        if (stype === 'trial') {
            //sdt = tselectSubs;
            var sbdt = []
            sbdt.push("trial");
            // Subjects.map((obj)=>{

            // })
            sdt = sbdt;

            //setSelectSubs('trial');
            //console.log(selectSubs);
        }
        else {

            sdt = selectSubs;
        }
        const subData = {
            quiz_name: quizname,
            mode: mode,
            difficulty: difficulty,
            subject: sdt,
            no_of_question: noquestion,
            qstatus: qstatus,
            time_mode: tmMode,
            time_type: tm,
            total_sec: 'sec',
            qbid: localStorage.getItem('subid')
        };
        // console.log(subData);
        axios.post('https://api.entmcq.com/api/quiz', subData, { headers: { "Authorization": `Bearer ${uid}` } })
            .then((res) => {
                console.log(res.data);
                //alert("Subject added successfully");
                const data = res.data;
                if (data[0].status == "success") {
                    localStorage.setItem('quiz_id', data[0].qid);
                    window.location.href = '/dashboard/quiz'
                }
                //alert("Quiz added successfully");
                else {
                    // alert("Quiz failed");
                }

            })
    }

    return (
        <React.Fragment>
            <Dashboard nm="Dashboard / Custom Quiz" />
            <main>

                <div class="row">
                    <div class="col-sm-12">
                        <div class="data-container form-container">
                            <div class="card plain-card">
                                <div class="card-header">Quiz Mode</div>
                                <div class="card-body">
                                    <div class="row border-bottom mb-2 pb-2">
                                        <div class="col-sm-3">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="flexRadioDefault"
                                                    id="flexRadioDefault1" value="exam" onChange={mode => setMode(mode.target.value)} />
                                                <label class="mb-0 fw-bold" for="flexRadioDefault1">
                                                    Exam Mode
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-sm-9 border-start">
                                            <p class="mb-0"><i class='bx bx-help-circle me-2'></i>Answers and explanations
                                                available
                                                <strong class="text-uppercase"> after </strong> the quiz
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <div class="form-check mb-3">
                                                <input class="form-check-input" type="radio" name="flexRadioDefault"
                                                    id="flexRadioDefault2" value="Tutor" onChange={mode => setMode(mode.target.value)} />
                                                <label class="form-check-label" for="flexRadioDefault2">
                                                    <p class="mb-0 fw-bold">Tutorial</p>
                                                    <small><b>First</b> response to a question is used to determine
                                                        score</small>
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="flexRadioDefault"
                                                    id="flexRadioDefault3" value="Study" onChange={mode => setMode(mode.target.value)} />
                                                <label class="form-check-label" for="flexRadioDefault3">
                                                    <p class="mb-0 fw-bold">Study / Open Book</p>
                                                    <small><b> Final </b> response to a question is used to determine
                                                        score</small>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-sm-9 border-start">
                                            <p class="mb-0"><i class='bx bx-help-circle me-2'></i>Answers and explanations
                                                available
                                                <strong class="text-uppercase"> during </strong> the quiz
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-header">Select Speciality</div>
                                <div class="card-body">
                                    <div class="form-check mb-3">
                                        <input class="form-check-input" type="radio" name="subjs" value="trial" onChange={handleSubjectType} id="flexCheckDefault" />
                                        <label class="form-check-label fw-bold" for="flexCheckDefault">
                                            Random Sample Questions {stype == 'trial' && <font style={{ color: 'blue' }}>({total})</font>}
                                        </label>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <div class="form-check me-5">
                                            <input class="form-check-input" type="radio" name="subjs" value="All" onChange={handleSubjectType} id="flexCheckDefault" />
                                            <label class="form-check-label fw-bold" for="flexCheckDefault">
                                                Speciality
                                            </label>
                                        </div>
                                        {/* <button class="btn bg-danger text-danger bg-opacity-10 btn-sm py-1">
                                    <i class='bx bx-x-circle me-2'></i> Clear
                                </button> */}
                                    </div>
                                    {stype == 'All' && <ul class="list-group list-group-flush column-2">
                                        {
                                            Subjects.map((obj) => {
                                                cate_id += 1
                                                return (
                                                    <li class="list-group-item">
                                                        <input class="form-check-input me-1" type="checkbox" onChange={(e) => { handleSubjects(e) }} value={obj.name}
                                                            id={"firstCheckboxStretched" + obj.id} defaultChecked={true} />
                                                        <label class="form-check-label stretched-link" htmlFor={"firstCheckboxStretched" + obj.id}>{obj.name} <font style={{ color: 'blue' }}>({catecnt[cate_id - 1]})</font></label>
                                                    </li>
                                                )
                                            })
                                        }

                                    </ul>}
                                </div>
                                <div class="card-header">Difficulty Level</div>
                                <div class="card-body">
                                    <div class="d-flex align-items-center">
                                        <div class="border-end pe-3">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="All" onChange={handleDifficulty} />
                                                <label class="form-check-label" for="flexCheckDefault">
                                                    All
                                                </label>
                                            </div>
                                        </div>
                                        <div class="ps-3">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" value="hard" onChange={handleDifficulty} />
                                                <label class="form-check-label" for="flexCheckDefault1">
                                                    Hard <font style={{ color: 'blue' }}>({lvltotal[0]})</font>
                                                </label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" value="moderate" name="difficultylevel" onChange={handleDifficulty} />
                                                <label class="form-check-label" for="flexCheckDefault2">
                                                    Moderate <font style={{ color: 'blue' }}>({lvltotal[1]})</font>
                                                </label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" value="easy" onChange={handleDifficulty} name="difficultylevel" />
                                                <label class="form-check-label" for="flexCheckDefault3">
                                                    Easy <font style={{ color: 'blue' }}>({lvltotal[2]})</font>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-header">Question Status</div>
                                <div class="card-body">
                                    <div class="row align-items-center">
                                        <div class="col-sm-5">
                                            <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                                                <input type="radio" class="btn-check" name="btnradio" id="btnradio1"
                                                    autocomplete="off" value="All " onChange={questionstatus} />
                                                <label class="btn btn-outline-primary" for="btnradio1">All </label>

                                                {/* <input type="radio" class="btn-check" name="btnradio" id="btnradio2"
                                            autocomplete="off" value="Unanswered" onChange={questionstatus}/>
                                        <label class="btn btn-outline-primary" for="btnradio2">Unanswered</label> */}

                                                <input type="radio" class="btn-check" name="btnradio" id="btnradio3"
                                                    autocomplete="off" value="Incorrect" onChange={questionstatus} />
                                                <label class="btn btn-outline-primary" for="btnradio3">Incorrect<font >({statustotal[0]})</font></label>

                                                <input type="radio" class="btn-check" name="btnradio" id="btnradio4"
                                                    autocomplete="off" value="Correct" onChange={questionstatus} />
                                                <label class="btn btn-outline-primary" for="btnradio4">Correct <font >({statustotal[1]})</font></label>

                                                <input type="radio" class="btn-check" name="btnradio" id="btnradio5"
                                                    autocomplete="off" value="Unseen" onChange={questionstatus} />
                                                <label class="btn btn-outline-primary" for="btnradio5">Unanswered <font >({statustotal[2]})</font></label>
                                            </div>
                                        </div>
                                        {/* <div class="col-sm-2 border-start border-end">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault0"/>
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Marked
                                        </label>
                                    </div>
                                </div>
                                <div class="col-sm-5">
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Keyword Search Filter"/>
                                        <button class="btn btn-light" type="button">
                                            <i class='bx bx-search-alt-2'></i>
                                        </button>
                                    </div>
                                </div> */}
                                    </div>
                                </div>




                                <div class="card-header">Number of Questions (Max {total})</div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-2">

                                            <div class="d-flex">
                                                {/* <div class="btn-group me-3 pe-3 border-end" role="group"
                                            aria-label="Basic radio toggle button group">
                                            <input type="radio" class="btn-check" name="btnradio" id="btnradio1"
                                                autocomplete="off" />
                                            <label class="btn btn-outline-primary" for="btnradio1">10</label>

                                            <input type="radio" class="btn-check" name="btnradio"  id="btnradio2"
                                                autocomplete="off"/>
                                            <label class="btn btn-outline-primary" for="btnradio2">25</label>
                                        </div> */}
                                                <input type="number" class="form-control" value={noquestion}
                                                    onChange={(noquestion) => {
                                                        setQuestion(noquestion.target.value)
                                                        if (parseInt(noquestion.target.value) > total) {
                                                            setMaxError(true)
                                                            setQuestion(total)
                                                        }
                                                        else {
                                                            setMaxError(false)
                                                        }
                                                    }} />

                                            </div>
                                        </div>
                                        {maxError && (<span style={{ color: 'red' }}>Only 120 Questions are allowed</span>)}
                                    </div>
                                </div>

                                <div class="card-header">Timed Mode</div>
                                <div class="card-body">
                                    <div class="btn-group me-3 pe-3 border-end" role="group"
                                        aria-label="Basic radio toggle button group">
                                        <input type="radio" class="btn-check" name="btntimemode" id="btntimemode1" value="Untimed" onChange={timemode} autocomplete="off"
                                        />
                                        <label class="btn btn-outline-primary" for="btntimemode1">Untimed</label>

                                        <input type="radio" class="btn-check" name="btntimemode" id="btntimemode2" value="Timed" onChange={timemode} autocomplete="off" />
                                        <label class="btn btn-outline-primary" for="btntimemode2">Timed</label>
                                        {tmMode == "Timed" ? (
                                            <React.Fragment>
                                                <input type="number" class="form-control" placeholder='in minutes' style={{ width: 80, }} value={secs} onChange={secs => setSecs(secs.target.value)} /><label class="btn btn-outline-primary">(in Mins.)</label>
                                            </React.Fragment>

                                        ) : ""}
                                    </div>
                                </div>

                                <div class="card-header">Quiz Name</div>
                                <div class="card-body">
                                    <input type="text" class="form-control" placeholder="Quiz Name" value={quizname} onChange={quizname => setQuizname(quizname.target.value)} />
                                </div>

                                <div class="card-footer border-top">
                                    <div class="d-flex justify-content-between">
                                        <button type="button" class="btn btn-secondary" onClick={()=>{
                                            window.location.href="/dashboard";
                                        }}>Cancel</button>
                                        <button type="button" class="btn btn-main" onClick={Addquiz}>Start Quiz</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {AddLibrary('/assets/js/main.js')}
        </React.Fragment>
    )
}
export default UserQuiz;