import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { useParams } from "react-router-dom";
import Pricing from "./Pricing";
import "./Pricing.css";
import { BiCheckboxSquare } from "react-icons/bi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PricingTable, PricingSlot, PricingDetail } from 'react-pricing-table';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
var CryptoJS = require("crypto-js");


function PaymentPage() {
  const [loginError, setLoginError] = useState(false)
  const [uid, setUid] = useState(() => {
    const st = localStorage.getItem("info");
    return st || ""
  });
  const [service, setServices] = useState([]);
  const [packages, setPackage] = useState([]);
  const [isShow, setShow] = useState(true);
  const [pckg, setPckg] = useState('');
  const [drn, setDrn] = useState(null);

  const [logs, setLogs] = useState(() => {
    const st = localStorage.getItem("login");

    return st || ""
  });
  const initialOptions = {
    "client-id": "AWMmviigXSMiCI5iEh4Lb2C_p3iztV71nAr_r1mt60LHmJ9MJmJnrAVa-OxqB4g5Q7Knz_sB4zPffOFb",
    currency: "USD",
  };

  //const sid = useParams();
  function handleLogin(scode, days) {

    // if (logs == "") {
    //   //setLoginError(true);
    //   toast.error('Please login to subscribe plan');
    //   sdata = {
    //     "scode":scode,
    //     "days":days
    //   }
    //   localStorage.setItem('scodedata',JSON.stringify(sdata));
    //   window.location.href = "/register";
    //   return false;
    // }
    // setPckg(scode);
    // setDrn(days);
    // setShow(true);
    //alert('testing');
    // var bytes = CryptoJS.AES.decrypt(sid.id.replace(/__/g,'/'), 'QB@123');
    // var pid = bytes.toString(CryptoJS.enc.Utf8);
    // console.log(pid)
    // var pid = "";

    // if (logs == "") {
    //   //setLoginError(true);
    //   toast.error('Please login to subscribe plan');
    //   return false;
    // }

    // let newDate = new Date()
    // let date = newDate.getDate();
    // let month = newDate.getMonth() + 1;
    // let year = newDate.getFullYear();

    // let separator = "/";
    // let dt = `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`;

    // let expdt = new Date(newDate.getTime() + days*24*60*60*1000)

    // date = expdt.getDate();
    // month = expdt.getMonth() + 1;
    // year = expdt.getFullYear();

    // let exp = `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`;
    // const sdata = {
    //   package_id: pid,
    //   sub_startdate:dt,
    //   sub_enddate:exp,
    //   sub_code: scode,
    // }
    // console.log(sdata);

    // axios.post("https://api.entmcq.com/api/subscription", sdata,{headers:{"Authorization" : `Bearer ${uid}`}})
    //   .then((res) => {
    //     const data = res.data;
    //     console.log(data);
    //     if (data.status == "success") {
    //       localStorage.setItem('subid', pid);
    //       localStorage.setItem('scode', scode);
    //       window.location.href = "/dashboard"
    //     }
    //     console.log(res);
    //   })
  }
  function featchPackage() {
    // var bytes = CryptoJS.AES.decrypt(sid.id.replace(/__/g,'/'), 'QB@123');
    //   var pid = bytes.toString(CryptoJS.enc.Utf8);
    axios.get('https://api.entmcq.com/api/packages')
      .then((res) => {
        const data = res.data;
        console.log(data);
        setPackage(data);
      })
  }
  function featchService() {
    axios.get('https://api.entmcq.com/api/getservice')
      .then((res) => {
        const data = res.data;
        setServices(data);
      })
  }
  function AddCC(urlOfTheLibrary) {
    const script = document.createElement('link');
    script.href = urlOfTheLibrary;
    script.rel = "stylesheet";
    script.type = "text/css";
    document.head.appendChild(script);
  }
  const whitebg = {
    backgroundColor:'white'
  }
  const recommend = {
    backgroundColor:'#0d5c632c'
  }
  useEffect(() => {
    featchService();
    featchPackage();
    var scode = JSON.parse(localStorage.getItem("scodedata"))
    setPckg(scode.scode);
    setDrn(scode.days);
  }, [])
  return (

    <React.Fragment>
      {AddCC('/assets/css/pricing.css')}
      {AddCC('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css')}
      <ToastContainer />
      <div class="container-fluid">
        <div class="row">
          <Header />

        </div>
      </div>

      {/* <div class="container-fluid" id="aboutus">
        <div class="row">
          <div class="col-sm-12">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-sm-12 d-flex justify-content-between">
                  <h1>Services Available</h1>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0" id="breadcrumb">
                      <li class="breadcrumb-item"><a href="/">Home</a></li>
                      <li class="breadcrumb-item active" aria-current="page">Subscribe</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div class="container pb-5" id="main-content">
        {isShow ? <PayPalScriptProvider options={initialOptions}>
          <PayPalButtons style={{ layout: "horizontal" }}
            createOrder={(data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: "10.99",
                    },
                  },
                ],
              });
            }}
            onApprove={(data, actions) => {
              return actions.order.capture().then((details) => {
                var pid = "";

                if (logs == "") {
                  //setLoginError(true);
                  toast.error('Please login to subscribe plan');
                  return false;
                }

                let newDate = new Date()
                let date = newDate.getDate();
                let month = newDate.getMonth() + 1;
                let year = newDate.getFullYear();

                let separator = "/";
                let dt = `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`;

                let expdt = new Date(newDate.getTime() + drn * 24 * 60 * 60 * 1000)

                date = expdt.getDate();
                month = expdt.getMonth() + 1;
                year = expdt.getFullYear();

                let exp = `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`;
                const sdata = {
                  package_id: pid,
                  sub_startdate: dt,
                  sub_enddate: exp,
                  sub_code: pckg,
                }
                console.log(sdata);

                axios.post("https://api.entmcq.com/api/subscription", sdata, { headers: { "Authorization": `Bearer ${uid}` } })
                  .then((res) => {
                    const data = res.data;
                    console.log(data);
                    if (data.status == "success") {
                      localStorage.setItem('subid', pid);
                      localStorage.setItem('scode', pckg);
                      window.location.href = "/dashboard"
                    }
                    console.log(res);
                  })
                alert(`Transaction completed by `);
              });
            }} />
        </PayPalScriptProvider> :
          <section>
            <div class="container py-5">

              <header class="text-center mb-5 text-white">
                <div class="row">
                  <div class="col-lg-7 mx-auto" style={{ color: 'black' }}>
                    <h1>Subscription Available</h1>
                    <p>Prepare For The FRCS(ORL-HNS) Section One Examination.</p>
                  </div>
                </div>
              </header>



              <div class="row text-center align-items-end">


                {
                  packages.map((obj) => {

                    return (
                      <div class="col-lg-4 mb-5 mb-lg-0">
                        <div class="p-5 rounded-lg shadow" style={obj.package_name == "Premium" ? recommend : whitebg}>
                          {obj.package_name == "Premium" && <h2 class="h2 text-uppercase font-weight-bold mb-4">Recommended</h2>}
                          <h1 class="h6 text-uppercase font-weight-bold mb-4">{obj.package_name}</h1>
                          <h2 class="h1 font-weight-bold">£{obj.pricing}<span class="text-small font-weight-normal ml-2">/ {obj.duration} days</span></h2>

                          {obj.package_name == "Premium" ? <div class="custom-separator my-4 mx-auto bg-primary"></div> :
                            <div class="custom-separator my-4 mx-auto bg-primary-color"></div>}

                          <ul class="list-unstyled my-5 text-small text-left">
                            {
                              service.map((objs) => {
                                var ids = obj.service_include;
                                if (ids.includes(objs.id)) {
                                  return (
                                    <li class="mb-3">
                                      <i class="fa fa-check mr-2 text-primary"></i> {objs.service_name}</li>
                                  )
                                }
                                else {
                                  return (<li class="mb-3 text-muted">
                                    <i class="fa fa-times mr-2"></i>
                                    <del>{objs.service_name}</del>
                                  </li>)
                                }
                              })
                            }
                            {/* <li class="mb-3">
                              <i class="fa fa-check mr-2 text-primary"></i> Lorem ipsum dolor sit amet</li>
                            <li class="mb-3">
                              <i class="fa fa-check mr-2 text-primary"></i> Sed ut perspiciatis</li>
                            <li class="mb-3">
                              <i class="fa fa-check mr-2 text-primary"></i> At vero eos et accusamus</li>
                            <li class="mb-3 text-muted">
                              <i class="fa fa-times mr-2"></i>
                              <del>Nam libero tempore</del>
                            </li>
                            <li class="mb-3 text-muted">
                              <i class="fa fa-times mr-2"></i>
                              <del>Sed ut perspiciatis</del>
                            </li>
                            <li class="mb-3 text-muted">
                              <i class="fa fa-times mr-2"></i>
                              <del>Sed ut perspiciatis</del>
                            </li> */}
                          </ul>
                          <button class="btn btn-main btn-block p-2 shadow " onClick={()=>handleLogin()}>Subscribe</button>
                        </div>
                      </div>
                    )
                  })}
                {/* <div class="col-lg-4 mb-5 mb-lg-0">
                  <div class="bg-white p-5 rounded-lg shadow">
                    <h1 class="h6 text-uppercase font-weight-bold mb-4">Pro</h1>
                    <h2 class="h1 font-weight-bold">£399<span class="text-small font-weight-normal ml-2">/ month</span></h2>

                    <div class="custom-separator my-4 mx-auto bg-primary-color"></div>

                    <ul class="list-unstyled my-5 text-small text-left font-weight-normal">
                      <li class="mb-3">
                        <i class="fa fa-check mr-2 text-primary"></i> Lorem ipsum dolor sit amet</li>
                      <li class="mb-3">
                        <i class="fa fa-check mr-2 text-primary"></i> Sed ut perspiciatis</li>
                      <li class="mb-3">
                        <i class="fa fa-check mr-2 text-primary"></i> At vero eos et accusamus</li>
                      <li class="mb-3">
                        <i class="fa fa-check mr-2 text-primary"></i> Nam libero tempore</li>
                      <li class="mb-3">
                        <i class="fa fa-check mr-2 text-primary"></i> Sed ut perspiciatis</li>
                      <li class="mb-3 text-muted">
                        <i class="fa fa-times mr-2"></i>
                        <del>Sed ut perspiciatis</del>
                      </li>
                    </ul>
                    <a href="#" class="btn btn-primary btn-block p-2 shadow rounded-pill">Subscribe</a>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="bg-white p-5 rounded-lg shadow">
                    <h1 class="h6 text-uppercase font-weight-bold mb-4">Enterprise</h1>
                    <h2 class="h1 font-weight-bold">£899<span class="text-small font-weight-normal ml-2">/ month</span></h2>

                    <div class="custom-separator my-4 mx-auto bg-primary"></div>

                    <ul class="list-unstyled my-5 text-small text-left font-weight-normal">
                      <li class="mb-3">
                        <i class="fa fa-check mr-2 text-primary"></i> Lorem ipsum dolor sit amet</li>
                      <li class="mb-3">
                        <i class="fa fa-check mr-2 text-primary"></i> Sed ut perspiciatis</li>
                      <li class="mb-3">
                        <i class="fa fa-check mr-2 text-primary"></i> At vero eos et accusamus</li>
                      <li class="mb-3">
                        <i class="fa fa-check mr-2 text-primary"></i> Nam libero tempore</li>
                      <li class="mb-3">
                        <i class="fa fa-check mr-2 text-primary"></i> Sed ut perspiciatis</li>
                      <li class="mb-3">
                        <i class="fa fa-check mr-2 text-primary"></i> Sed ut perspiciatis</li>
                    </ul>
                    <a href="#" class="btn btn-primary btn-block p-2 shadow rounded-pill">Subscribe</a>
                  </div>
                </div> */}

              </div>
            </div>
          </section>


        }
      </div>
      <Footer />

    </React.Fragment>
  )
}

export default PaymentPage;

