import axios from "axios";
import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { isEmail, isName, isMob, isPasss } from "./validators/Validations";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from "./UIComponents/LoadingSpinner";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import SpinnerLoad from "./UIComponents/SpinnerLoad";


function AutherLaunch() {
    const [show, setShow] = useState(true);
    const [fname, setFname] = useState('');
    const [em, setEm] = useState('');
    const [job, setJob] = useState('');
    const [prof, setProf] = useState('');
    const [yr, setYr] = useState('');
    const [nameError, setNameError] = useState(false)
    const [emError, setEmError] = useState(false)
    const [termsconditions, setTermsConditions] = useState(false);
    const [termsError, setTermsError] = useState(false);
    const [isSumit,setSumit] = useState(false);


    function handleClose() {
        setShow(!show);
    }

    function handleCheck(e) {
        //alert(e.target.checked);
        //console.log(e.target.checked);
        setTermsConditions(e.target.checked);
    }

    function handleRegister() {
        if (!fname) {
            toast.error("Please enter First Name");
            setNameError(true)
        }
        else if (!isEmail(em)) {
            toast.error("Please enter valid Email");
            setEmError(true);
        }
        else if (!prof) {
            toast.error("some fields are empty");
            // setTermsError(true)
        }
        else if (!job) {
            toast.error("some fields are empty");
            // setTermsError(true)
        }

        else {
            setSumit(true)
            const data = {
                name: fname,
                email: em,
                job: job,
                prof: prof,
                anti: yr,
                type: 'author'
            }
            axios.post('https://api.entmcq.com/api/launch-reg', data)
                .then((res) => {
                    const rdata = res.data;
                    if (rdata.status == 'success') {
                        // toast.success(rdata.msg);
                        toast.success("Thank you for submitting your pre-registration form for our website. We will be in touch very soon with early bird access and a discount code to use our question bank.")
                        setShow(false);

                    }
                    setSumit(false)
                })
        }
    }
    return (
        <React.Fragment>
            <ToastContainer />

            <div class="container-fluid">
                <div class="row">
                    <Header />
                    <Modal show={show} onHide={handleClose}
                        size={'lg'}
                    >

                        <Modal.Body>
                            <span style={{ float: 'right', background: '#000', color: '#fff', borderRadius: 5, padding: 5, }} onClick={handleClose}>X</span>
                            {/* <p>Quiz Completed!</p> */}

                            <div class="col-sm-12 " style={{ background: '#188ccc' }}>
                                <img src="/assets/images/bg_lightbox.png" class="img-fluid" />
                            </div>
                            <div class="col-sm-12" style={{ padding: 10, }}>

                                <h1 style={{ fontWeight: 'bold', fontSize: 40, marginTop: 15, textAlign: 'left' }}>Exciting News - Coming Soon!</h1>
                                <p style={{ marginTop: 20, }}>This unique feature of our website is due to go live very soon. To pre-register as an author and be eligible to submit questions for review and receive payment in the near future please complete the below pre-registration form.</p>
                                <div class="row">
                                    <div class="col-sm-6 mb-3">
                                        <label class="form-label">Full Name <span class="text-danger">* {nameError ? ("Invalid Name") : ("")}</span></label>
                                        <input type="text" class="form-control" placeholder="Full Name" value={fname} onChange={(fname) => {
                                            setFname(fname.target.value)
                                            setNameError(false)
                                        }} />
                                    </div>
                                    <div class="col-sm-6 mb-3">
                                        <label class="form-label">Email ID <span class="text-danger">* {emError ? ("Invalid Email") : ("")}</span></label>
                                        <input type="email" class="form-control" placeholder="Email ID" value={em} onChange={(em) => {
                                            setEm(em.target.value)
                                            setEmError(false)
                                        }} />
                                    </div>
                                    <div class="col-sm-6 mb-3">
                                        <label class="form-label">Hospital <span class="text-danger">* </span></label>
                                        <input type="text" class="form-control" placeholder="Hospital" value={prof} onChange={(fname) => {
                                            setProf(fname.target.value)
                                            // setNameError(false)
                                        }} />
                                    </div>
                                    <div class="col-sm-6 mb-3">
                                        <label class="form-label">Job Title <span class="text-danger">* </span></label>
                                        <input type="text" class="form-control" placeholder="Job Title" value={job} onChange={(fname) => {
                                            setJob(fname.target.value)
                                            // setNameError(false)
                                        }} />
                                    </div>
                                    {/* <div class="col-sm-6 mb-3">
                                        <label class="form-label">Region of Training<span class="text-danger">*</span></label>
                                        <select class="form-select" value={prof} onChange={(prof) => {
                                            setProf(prof.target.value)
                                            // setPract("");
                                        }}>

                                            <option value="Region (UK)">Region (UK)</option>
                                            <option value="International">International</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-6 mb-3">
                                        <label class="form-label">Anticipated Exam Sitting<span class="text-danger">*</span></label>
                                        <select class="form-select" value={yr} onChange={(yr) => {
                                            setYr(yr.target.value)
                                            // setPract("");
                                        }}>

                                            <option value="Jan 2024/March 2024 (International)">Jan 2024/March 2024 (International)</option>
                                            <option value="July 2024,Undecided">July 2024,Undecided</option>
                                        </select>
                                    </div> */}
                                </div>
                                <div class="mb-3">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" onChange={handleCheck} id="flexCheckDefault" />
                                        <label class="form-check-label" for="flexCheckDefault">
                                            I agree with the <a href="/terms-conditions" class="text-main" >Terms & Conditions</a>
                                            <span class="text-danger">* {termsError ? ("Please accept terms & conditions") : ("")}</span>
                                        </label>
                                    </div>
                                </div>
                                {isSumit?<SpinnerLoad/> : <button type="button" class="btn btn-main" onClick={handleRegister}>Register</button>}
                            </div>

                        </Modal.Body>

                    </Modal>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default AutherLaunch;