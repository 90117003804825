import React, { useState, useEffect } from "react";
import Dashboard from "./Dashboard";
import { ArcElement } from 'chart.js';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import axios from 'axios';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,

);



function PerformanceTimeline() {
    const [subinfo, setSubInfo] = useState([]);
    const [gall, setGall] = useState(0);
    const [ans, setAns] = useState(0)
    const [unsen, setUnsen] = useState(0);
    const [wrong, setWrong] = useState(0);
    const [total, setTotal] = useState(0);
    const [title, setTitle] = useState('')
    const [alld, setAlld] = useState([]);
    const [ansd, setAnsd] = useState([]);
    const [unsend, setUnsend] = useState([]);
    const [wrongd, setWrongd] = useState([]);
    const [quiz, setQuiz] = useState([]);
    const [month, setMonth] = useState(1);
    const [lbls, setLbls] = useState([]);
    const [gdataset, setGdataset] = useState([]);
    const [fromDate, setFromDate] = useState(() => {
        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();

        let separator = "-";
        let dt = `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`;
        return dt;
    })
    const [toDate, setToDate] = useState(localStorage.getItem('rdate'))

    const [percentage, setPercentage] = useState(0);
    const sdata = {
        lbls,
        datasets: quiz,
    };

    const data = {
        labels: lbls,

        ansData: {
            label: "ans",
            dataSet: ansd
        },
        wrongData: {
            label: "wrong",
            dataSet: wrongd
        },
        unsenData: {
            label: "unsen",
            dataSet: unsend
        }
    };
    const gdata = {
        labels: ['Correct', 'Incorrect', 'Unanswered'],
        datasets: gdataset,
    };

    function fetchData() {
        const uid = localStorage.getItem("info");
        const qid = localStorage.getItem('subid');
        axios.get('https://api.entmcq.com/api/fetchSubjectTimeline', { headers: { "Authorization": `Bearer ${uid}` } })
            .then((resp) => {
                console.log(resp.data);
                setSubInfo(resp.data);

                var tmp = []
                var t = resp.data;
                var ta = 0;
                var tw = 0;
                var tu = 0;
                t.map((obj) => {
                    var ttitle = obj.subname
                    ta += parseInt(obj.ans)
                    tw += parseInt(obj.wrong)
                    tu += parseInt(obj.unsen)

                })
                tmp.push(
                    {
                        label: 'All',
                        data: [ta, tw, tu],
                        backgroundColor: [
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(154, 189, 242, 0.2)',
                            'rgba(211, 95, 255, 1)',
                            'rgba(200, 92, 99, 1)',
                        ],
                        borderColor: [
                            'rgba(75, 192, 192, 1)',
                            'rgba(255, 99, 132, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(211, 95, 255, 1)',
                            'rgba(200, 92, 99, 1)',

                        ],
                        borderWidth: 1,
                    }
                )
                setGall([ta, tw, tu, (ta + tw + tu)])
                setGdataset(tmp);

                // var t = resp.data;
                // var ta = 0;
                // var tu = 0;
                // var tw = 0;
                // var tt = 0;
                // t.map((obj)=>{
                //     ta+=parseInt(obj.ans)
                //     tu+=parseInt(obj.unsen)
                //     tw+=parseInt(obj.wrong)
                //     tt+=parseInt(obj.total);
                // })
                // var tmp = []
                // tmp.push(ta)
                // tmp.push(tu)
                // tmp.push(tw)
                // tmp.push(tt);

                // setGall(tmp);
                // setAns(ta)
                // setUnsen(tu)
                // setWrong(tw)
                // setTotal(tt);
                // setTitle("All")
                // console.log(resp.data);
            })
    }

    function handleObj(obj) {
        // console.log(obj);
        var tmp = [];
        if (obj == "All") {
            // subinfo.map((obj) => {
            //     var ttitle = obj.subname
            //     tmp.push(
            //         {
            //             label: '' + ttitle,
            //             data: [obj.ans, obj.wrong, obj.unsen],
            //             backgroundColor: [
            //                 'rgba(75, 192, 192, 0.2)',
            //                 'rgba(255, 99, 132, 0.2)',
            //                 'rgba(255, 206, 86, 0.2)',
            //             ],
            //             borderColor: [
            //                 'rgba(75, 192, 192, 1)',
            //                 'rgba(255, 99, 132, 1)',
            //                 'rgba(255, 206, 86, 1)',

            //             ],
            //             borderWidth: 1,
            //         }
            //     )
            // })
            tmp.push(
                {
                    label: 'All',
                    data: [gall[0], gall[1], gall[2]],
                    backgroundColor: [
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(154, 189, 242, 0.2)',
                    ],
                    borderColor: [
                        'rgba(75, 192, 192, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 206, 86, 1)',

                    ],
                    borderWidth: 1,
                }
            )
        }
        else {
            tmp.push(
                {
                    label: '' + obj.subname,
                    data: [obj.ans, obj.wrong, obj.unsen],
                    backgroundColor: [
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(154, 206, 242, 0.2)',
                    ],
                    borderColor: [
                        'rgba(75, 192, 192, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 206, 86, 1)',

                    ],
                    borderWidth: 1,
                }
            )
        }
        setGdataset(tmp);
        // setAns(obj.ans)
        // setUnsen(obj.unsen)
        // setWrong(obj.wrong)
        // var t = []

        // setTotal(obj.total);
        // setTitle(obj.subname)
    }
    function fetchQuiz() {
        const id = localStorage.getItem('info');
        var sid = localStorage.getItem('subid');
        if (!sid) {
            sid = "";
        }
        axios.get('https://api.entmcq.com/api/fetchQuizzesTime/' + toDate + "X" + fromDate + "--0--" + sid, { headers: { "Authorization": `Bearer ${id}` } })
            .then((res) => {
                const gdata = res.data;
                // setQuiz(data);
                console.log(gdata);
                var keys = Object.keys(gdata[0]);
                console.log(keys);
                setLbls(keys);

                var info = [];
                var ansdata = [];
                var unsendata = [];
                var wrondata = [];
                var alldata = [];
                // tmpdata = []
                for (var key in keys) {
                    // console.log(key);
                    var total = parseFloat(gdata[0][keys[key]].ans) + parseFloat(gdata[0][keys[key]].unsen) + parseFloat(gdata[0][keys[key]].wrong);
                    var ansper = (parseFloat(gdata[0][keys[key]].ans) / total) * 100;
                    var unseper = (parseFloat(gdata[0][keys[key]].unsen) / total) * 100;
                    var wrongper = (parseFloat(gdata[0][keys[key]].wrong) / total) * 100;
                    var allper = ansper + unseper + wrongper;
                    alldata.push(allper)
                    ansdata.push(ansper)
                    unsendata.push(unseper)
                    wrondata.push(wrongper)
                    info.push(gdata[0][keys[key]])
                }
                setQuiz(info)
                // console.log('label', ansdata);
                setAnsd(ansdata)
                setUnsend(unsendata);
                setWrongd(wrondata);
                setAlld(alldata);

            })
    }
    function handleChange(e) {
        setFromDate(e.target.value);
        console.log(e.target.value);
        const id = localStorage.getItem('info');
        var sid = localStorage.getItem('subid');
        if (!sid) {
            sid = "";
        }
        // console.log('https://api.entmcq.com.com/api/fetchQuizzesTime/' + e.target.value + "X" + toDate + "--" + id + "--" + sid)
        axios.get('https://api.entmcq.com/api/fetchQuizzesTime/' + toDate + "X" + e.target.value + "--0--" + sid, { headers: { "Authorization": `Bearer ${id}` } })
            .then((res) => {
                const gdata = res.data;
                // setQuiz(data);
                console.log(gdata);
                var keys = Object.keys(gdata[0]);
                console.log(keys);
                setLbls(keys);

                var info = [];
                var ansdata = [];
                var unsendata = [];
                var wrondata = [];
                // tmpdata = []
                for (var key in keys) {
                    // console.log(key);
                    var total = parseFloat(gdata[0][keys[key]].ans) + parseFloat(gdata[0][keys[key]].unsen) + parseFloat(gdata[0][keys[key]].wrong);
                    var ansper = (parseFloat(gdata[0][keys[key]].ans) / total) * 100;
                    var unseper = (parseFloat(gdata[0][keys[key]].unsen) / total) * 100;
                    var wrongper = (parseFloat(gdata[0][keys[key]].wrong) / total) * 100;
                    ansdata.push(ansper)
                    unsendata.push(unseper)
                    wrondata.push(wrongper)
                    info.push(gdata[0][keys[key]])

                }
                setQuiz(info)
                setAnsd(ansdata)
                setUnsend(unsendata);
                setWrongd(wrondata);
            })

    }

    function handleTChange(e) {
        setToDate(e.target.value);
        // console.log(e.target.value);
        const id = localStorage.getItem('info');
        var sid = localStorage.getItem('subid');
        if (!sid) {
            sid = "";
        }
        console.log('https://api.entmcq.com/api/fetchQuizzesTime/' + fromDate + "X" + e.target.value + "--" + id + "--" + sid)
        axios.get('https://api.entmcq.com/api/fetchQuizzesTime/' + fromDate + "X" + e.target.value + "--0--" + sid, { headers: { "Authorization": `Bearer ${id}` } })
            .then((res) => {
                const gdata = res.data;
                // setQuiz(data);
                console.log(gdata);
                var keys = Object.keys(gdata[0]);
                console.log(keys);
                setLbls(keys);

                var info = [];
                var ansdata = [];
                var unsendata = [];
                var wrondata = [];
                // tmpdata = []
                for (var key in keys) {
                    // console.log(key);
                    ansdata.push(parseInt(gdata[0][keys[key]].ans))
                    unsendata.push(parseInt(gdata[0][keys[key]].unsen))
                    wrondata.push(parseInt(gdata[0][keys[key]].wrong))
                    info.push(gdata[0][keys[key]])

                }
                setQuiz(info)
                console.log('label', ansdata);
                setAnsd(ansdata)
                setUnsend(unsendata);
                setWrongd(wrondata);
            })

    }

    useEffect(() => {
        fetchData();
        fetchQuiz();
    }, []);

    function AddLibrary(urlOfTheLibrary) {
        const script = document.createElement('script');
        script.src = urlOfTheLibrary;
        script.async = true;
        document.body.appendChild(script);
    }
    function handleClick(val) {
        console.log(val);
        localStorage.setItem('quiz_id', val);
        window.location.href = '/dashboard/quiz'
    }
    return (
        <React.Fragment>
            <Dashboard nm="Dashboard / Performance Timeline" />
            <main>

                <div class="card card-body bg-danger border-0 bg-opacity-10 text-danger mb-3">
                    <div class="d-flex justify-content-between align-items-center">



                    </div>
                    <div class="row gx-2">
                        <div class="col-sm-6">
                            <div class="card h-100 plain-card">

                                <div class="card-header">Performance
                                    <div class="row">
                                        <div className="header__search">
                                            From &nbsp;&nbsp;&nbsp; <input class="form-control" type="date" value={toDate} id="html5-date-input" onChange={handleTChange} />


                                        </div>
                                        <div className="header__search">
                                            To &nbsp; &nbsp;<input class="form-control" type="date" value={fromDate} id="html5-date-input" onChange={handleChange} />

                                        </div>

                                    </div>
                                </div>
                                <div class="card-body">
                                    {/* <Line data={data} /> */}
                                    {/* {quiz.length > 0 && <Bar options={options} data={sdata} />} */}
                                    <Bar
                                        pointStyle="star"
                                        data={{
                                            labels: data.labels,
                                            responsive: true,
                                            offset: true,
                                            datasets: [

                                                {
                                                    label: "Correct",
                                                    pointStyle: "rectRounded",
                                                    backgroundColor: "#e8f3ee",
                                                    barThickness: 40,
                                                    categoryPercentage: 1,
                                                    data: data.ansData.dataSet //From API
                                                },

                                                {
                                                    label: "Incorrect",
                                                    backgroundColor: "#fbeaec",
                                                    barThickness: 40,
                                                    categoryPercentage: 1,
                                                    pointStyle: "triangle",
                                                    data: data.wrongData.dataSet //From API
                                                },
                                                {
                                                    label: "Unanswered",
                                                    backgroundColor: "#e6f0ff",
                                                    barThickness: 40,
                                                    categoryPercentage: 1,
                                                    pointStyle: "triangle",
                                                    data: data.unsenData.dataSet //From API
                                                }
                                            ]
                                        }}
                                        height={220}
                                        options={{
                                            offsetGridLines: true,
                                            drawTicks: true,
                                            layout: {
                                                padding: {
                                                    top: 30,
                                                    right: 40,
                                                    bottom: 40
                                                }
                                            },
                                            legend: {
                                                display: true,
                                                position: "right",
                                                align: "start",
                                                labels: {
                                                    usePointStyle: true
                                                }
                                            },
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            scales: {
                                                x: {
                                                    stacked: true,
                                                },
                                                y: {
                                                    stacked: true,
                                                },
                                            },
                                        }}
                                    />
                                </div>

                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="card h-100 plain-card">
                                <div class="card-header">Speciality</div>
                                <div class="card-body">
                                    <table class="table dashboard-table">
                                        <thead>
                                            <tr>
                                                <th>Speciality</th>
                                                <th>Correct</th>
                                                <th>Incorrect</th>
                                                <th>Unanswered</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* <tr>
                                                <td>
                                                    <p class="mb-2">All</p>

                                                </td>
                                                <td>{alld[0]}%</td>
                                                <td>{alld[1]}%</td>
                                                <td>{alld[2]}%</td>

                                            </tr> */}
                                            {
                                                quiz.map((obj) => {
                                                    var total = parseFloat(obj.ans) + parseFloat(obj.unsen) + parseFloat(obj.wrong);

                                                    var ansper = (parseFloat(obj.ans) / total) * 100;
                                                    var unseper = (parseFloat(obj.unsen) / total) * 100;
                                                    var wrongper = (parseFloat(obj.wrong) / total) * 100;
                                                    if (total == 0) {
                                                        ansper = 0;
                                                        unseper = 0;
                                                        wrongper = 0;
                                                    }
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <p class="mb-2">{obj.min}</p>

                                                            </td>
                                                            <td>{ansper.toFixed()}%</td>
                                                            <td>{wrongper.toFixed()}%</td>
                                                            <td>{unseper.toFixed()}%</td>

                                                        </tr>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div class="row gx-2 mb-2">
                    <div class="col-sm-5">
                        <div class="card h-100 plain-card">
                            <div class="card-header">Speciality</div>
                            <div class="card-body">
                                <table class="table dashboard-table">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Correct</th>
                                            <th>Incorrect</th>
                                            <th>Unanswered</th>
                                            <th>No. of Q's</th>
                                            {/* <th>Actions</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <button type="button"
                                                    class="btn bg-main-light text-main btn-sm" onClick={() => { handleObj("All") }}>All</button>

                                            </td>
                                            <td>{gall[0]}</td>
                                            <td>{gall[1]}</td>
                                            <td>{gall[2]}</td>
                                            <td>{gall[3]}</td>
                                            {/* <td>
                                                            <button type="button"
                                                                class="btn bg-main-light text-main btn-sm" onClick={() => { handleObj(obj) }}>show</button>
                                                        </td> */}
                                        </tr>
                                        {
                                            subinfo.map((obj) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            <button type="button"
                                                                class="btn bg-main-light text-main btn-sm" onClick={() => { handleObj(obj) }}>{obj.subname}</button>

                                                        </td>
                                                        <td>{obj.ans}</td>
                                                        <td>{obj.wrong}</td>
                                                        <td>{obj.unsen}</td>
                                                        <td>{obj.total}</td>
                                                        {/* <td>
                                                            <button type="button"
                                                                class="btn bg-main-light text-main btn-sm" onClick={() => { handleObj(obj) }}>show</button>
                                                        </td> */}
                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    {/* <div class="col-sm-4">
                        <div class="card h-100 plain-card">
                            <div class="card-header">Performance</div>
                            <div class="card-body">
                                <Line data={gdata} />
                                
                            </div>
                        </div>
                    </div> */}
                    <div class="col-sm-3">
                        <div class="card h-100 plain-card">
                            <div class="card-header">Performance</div>
                            <div class="card-body">
                                {/* <Line data={gdata} /> */}
                                <Doughnut data={gdata} />
                            </div>
                        </div>
                    </div>

                </div>
            </main>
            {AddLibrary('/assets/js/main.js')}
        </React.Fragment>
    )
}
export default PerformanceTimeline;