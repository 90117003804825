import React, { useState, useEffect,useRef } from 'react';
import Dashboard from "./Dashboard";
import Countdown, { zeroPad, calcTimeDelta, formatTimeDelta } from 'react-countdown';

const CountdownTimer = (props) =>
{
    return(
        <React.Fragment>
            <Countdown date={Date.now() + props.qtimer} ref={props.countdownRef} onTick={(res)=>props.OnTick(res.total)}><props.CompleteQuiz /></Countdown>
        </React.Fragment>
    )
}

export default CountdownTimer;