import React from "react";
import Dashboard from "./Dashboard";
import { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import axios from 'axios';


ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale,
  LinearScale,
  PointElement,
  LineElement, Title,);



function QuestionerDashboard() {
  const [approved, setApproved] = useState(0);
  const [notApproved, setNotApproved] = useState(0);
  const [pending,setPending] = useState(0);

  const [catedata,setCatedata] = useState([]);

  
  function fetchQuestions() {
    var id = localStorage.getItem('info');
    axios.get('https://api.entmcq.com/api/myquestion',{headers:{"Authorization" : `Bearer ${id}`}})
      .then((res) => {
        const data = res.data;
        // setQuesitons(data);
        var ap = 0;
        var nap = 0;
        var pap = 0;
        data.map((obj) => {
          if (obj.status == "active") {
            ap++;
          }
          else if(obj.status == 'pending'){
            pap++;
          }
          else {
            nap++;
          }
        })
        setApproved(ap);
        setNotApproved(nap);
        setPending(pap);
      });
  }
  function fetchQuestionCategory() {
    var id = localStorage.getItem('info');
    axios.get('https://api.entmcq.com/api/myquestcategory', {headers:{"Authorization" : `Bearer ${id}`}})
      .then((res) => {
        const data = res.data;
        // setQuesitons(data);
        setCatedata(data);
      });
  }
  useEffect(() => {
    fetchQuestions();
    fetchQuestionCategory();
  }, [])
  function AddLibrary(urlOfTheLibrary) {
    const script = document.createElement('script');
    script.src = urlOfTheLibrary;
    script.async = true;
    document.body.appendChild(script);
  }
  return (
    <React.Fragment>
      <Dashboard nm="Welcome to your Dashboard" />
      <main>


        <div class="row mb-3 gx-2">

          <div class="col-sm-12">
            <div class="card plain-card h-100">
              <div class="card-header">Question Status</div>
              <div class="card-body text-center">
                <div class="row">
                  <div class="col-sm-3">
                    <div class="card card-body bg-success bg-opacity-10 text-success border-0">
                      <h2 class="mb-1">{approved}</h2>
                      <p class="mb-0">Approved</p>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="card card-body bg-danger bg-opacity-10 text-danger border-0">
                      <h2 class="mb-1">{notApproved}</h2>
                      <p class="mb-0">Not Approved</p>
                    </div>
                  </div>

                  <div class="col-sm-3">
                    <div class="card card-body bg-danger bg-opacity-10 text-danger border-0">
                      <h2 class="mb-1">{pending}</h2>
                      <p class="mb-0">Pending</p>
                    </div>
                  </div>

                  <div class="col-sm-3">
                    <div class="card card-body bg-primary bg-opacity-10 text-primary border-0">
                      <h2 class="mb-1"><span className="pond" style={{ fontSize: 20,fontWeight:'bold' }}>{approved * 5}</span></h2>
                      <p class="mb-0">Total Earning <span className="pond" style={{ fontSize: 12 }}>5/Question</span></p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="row gx-2">
          <div class="col-sm-6">
            <div class="card h-100 plain-card">
              <div class="card-header">Speciality</div>
              <div class="card-body">
                <table class="table dashboard-table">
                  <thead>
                    <tr>
                      <th>Specialist</th>
                      <th>Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      catedata.map((obj) => {
                        
                        return (
                          <tr>
                            
                            <td>{obj.cname}</td>
                            <td>{obj.cnt}</td>
                            
                          </tr>
                        )
                      })
                    }
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          
        </div>

      </main>
      {AddLibrary('/assets/js/main.js')}
    </React.Fragment>
  )
}
export default QuestionerDashboard;