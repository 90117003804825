import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
function Dataprotection() {
    return (
        <React.Fragment>
            <div class="container-fluid">
                <div class="row">
                    <Header />

                </div>
            </div>

            <div class="container-fluid" id="aboutus">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-sm-12 d-flex justify-content-between">
                                    <h1>Data Protection</h1>
                                    {/* <nav aria-label="breadcrumb">
                                <ol class="breadcrumb mb-0" id="breadcrumb">
                                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Data Protection</li>
                                </ol>
                            </nav> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container pb-5" id="main-content">
                <div class="row">
                    <div class="col-sm-12">
                        <h3 class="mb-4">ENT Education Courses Ltd
                            Data Protection Policy
                            January 2023
                        </h3>
                        <ol>
                            <p class="fw-bold"><li>Introduction</li></p>
                            <p>This Policy sets out the obligations of ENT Education Courses Ltd, a company
                                registered in England whose registered office is at 61, Bridge Street, Kington,
                                Herefordshire, HR5 3DJ (“the Company”) regarding data protection and the rights
                                of in respect of their personal data under UK Data Protection Legislation
                                (defined below).</p>
                            <p>This Policy sets out the Company’s obligations regarding the collection, processing,
                                transfer, storage, and disposal of personal data. The procedures and principles set
                                out herein must be followed at all times by the Company, its employees, agents,
                                contractors, or other parties working on behalf of the Company</p>
                            <p class="fw-bold"><li>Definitions</li></p>
                            <table>
                                <tr><th><p>“consent”</p></th><td><p style={{ marginLeft: "10%" }}>means the consent of the data subject which
                                    must be a freely given, specific, informed, and
                                    unambiguous indication of the data subject’s
                                    wishes by which they (by a statement or by a
                                    clear affirmative action) signify their agreement
                                    to the processing of personal data relating to
                                    them;
                                </p></td></tr>
                                <tr><th style={{ width: "20%" }}><p>“data controller”</p></th><td><p style={{ marginLeft: "10%" }}>means the person or organisation which, alone
                                    or jointly with others, determines the purposes
                                    and means of the processing of personal data.
                                    For the purposes of this Policy, the Company
                                    is the data controller of all personal data
                                    relating to data subjects, customers and
                                    business contacts used in our business;
                                </p></td></tr>
                                <tr><th><p>“data processor</p></th><td><p style={{ marginLeft: "10%" }}>means a person or organisation which
                                    processes personal data on behalf of a data
                                    controller;
                                </p></td></tr>
                                <tr><th><p>“Data Protection Legislation”</p></th><td><p style={{ marginLeft: "10%" }}>means all applicable legislation in force from
                                    time to time in the United Kingdom applicable
                                    to data protection and privacy including, but
                                    not limited to, the UK GDPR, the Data
                                    Protection Act 2018 (and regulations made
                                    thereunder), and the Privacy and Electronic
                                    Communications Regulations 2003 as
                                    amended, and any successor legislation;
                                </p></td></tr>
                                <tr><th><p>“data subject”</p></th><td><p style={{ marginLeft: "10%" }}>means a living, identified, or identifiable
                                    individual about whom the Company holds
                                    personal data;
                                </p></td></tr>
                                <tr><th><p>“EEA”</p></th><td><p style={{ marginLeft: "10%" }}>means the European Economic Area,
                                    consisting of all EU Member States, Iceland, Liechtenstein, and Norway;</p></td></tr>
                                <tr>
                                    <th><p>“personal data” </p></th><td><p style={{ marginLeft: "10%" }}>means any information relating to a data
                                        subject who can be identified, directly or
                                        indirectly, in particular by reference to an
                                        identifier such as a name, identification
                                        number, location data, an online identifier, or
                                        to one or more factors specific to the physical,
                                        physiological, genetic, mental, economic,
                                        cultural, or social identity of that data subject;
                                    </p></td>
                                </tr>
                                <tr><th><p>“personal data breach” </p></th><td><p style={{ marginLeft: "10%" }}>means a breach of security leading to the
                                    accidental or unlawful destruction, loss,
                                    alteration, unauthorised disclosure of, or
                                    access to, personal data transmitted, stored,
                                    or otherwise processed;</p></td></tr>
                                <tr><th><p>“processing”</p></th><td><p style={{ marginLeft: "10%" }}>means any operation or set of operations
                                    performed on personal data or sets of
                                    personal data, whether or not by automated
                                    means, such as collection, recording,
                                    organisation, structuring, storage, adaptation
                                    or alteration, retrieval, consultation, use,
                                    disclosure by transmission, dissemination or
                                    otherwise making available, alignment or
                                    combination, restriction, erasure or
                                    destruction;</p></td></tr>
                                <tr><th><p>“pseudonymisation” </p></th><td><p style={{ marginLeft: "10%" }}>means the processing of personal data in such
                                    a manner that the personal data can no longer
                                    be attributed to a specific data subject without
                                    the use of additional information, provided that
                                    such additional information is kept separately
                                    and is subject to technical and organisational
                                    measures to ensure that the personal data is
                                    not attributed to an identified or identifiable
                                    natural person; and
                                </p></td></tr>
                                <tr><th><p>“special category personal data”</p></th><td><p style={{ marginLeft: "10%" }}>means personal data revealing racial or ethnic
                                    origin, political opinions, religious or
                                    philosophical beliefs, trade union membership,
                                    health, sexual life, sexual orientation,
                                    biometric, or genetic data.
                                </p></td></tr>
                            </table>
                            <p class="fw-bold"><li>Data Protection Officer & Scope of Policy</li></p>
                            <ol>
                                <p><li>The Data Protection Officer is responsible for administering this Policy and for
                                    developing and implementing any applicable related policies (including those
                                    referred to in this Policy), procedures, and/or guidelines.</li></p>
                                <p><li>All directors, managers, and administrative staff are responsible for ensuring
                                    that all employees, agents, contractors, or other parties working on behalf of
                                    the Company comply with this Policy and, where applicable, must implement
                                    such practices, processes, controls, and training as are reasonably necessary
                                    to ensure such compliance.
                                </li></p>
                                <p><li>Any questions relating to this Policy, the Company’s collection, processing, or
                                    holding of personal data, or to the Data Protection Legislation should be referred to the Data Protection Officer.</li></p>
                            </ol>
                            <p class="fw-bold"><li>The Data Protection Principles</li></p>
                            <p>The Data Protection Legislation sets out the following principles with which any party
                                handling personal data must comply. All personal data must be:</p>
                            <ol>
                                <p><li>processed lawfully, fairly, and in a transparent manner in relation to the data
                                    subject;</li></p>
                                <p><li>collected for specified, explicit, and legitimate purposes and not further
                                    processed in a manner that is incompatible with those purposes. Further
                                    processing for archiving purposes in the public interest, scientific or historical
                                    research purposes or statistical purposes shall not be considered to be
                                    incompatible with the initial purposes;
                                </li></p>
                                <p><li>adequate, relevant, and limited to what is necessary in relation to the
                                    purposes for which it is processed;</li></p>
                                <p><li>accurate and, where necessary, kept up to date. Every reasonable step must
                                    be taken to ensure that personal data that is inaccurate, having regard to the
                                    purposes for which it is processed, is erased, or rectified without delay;</li></p>
                                <p><li>kept in a form which permits identification of data subjects for no longer than is
                                    necessary for the purposes for which the personal data is processed. Personal
                                    data may be stored for longer periods insofar as the personal data will be
                                    processed solely for archiving purposes in the public interest, scientific or
                                    historical research purposes, or statistical purposes, subject to implementation
                                    of the appropriate technical and organisational measures required by the Data
                                    Protection Legislation in order to safeguard the rights and freedoms of the
                                    data subject;</li></p>
                                <p><li>processed in a manner that ensures appropriate security of the personal data,
                                    including protection against unauthorised or unlawful processing and against
                                    accidental loss, destruction, or damage, using appropriate technical or
                                    organisational measures.
                                </li></p>
                            </ol>
                            <p class="fw-bold"><li>The Rights of Data Subjects</li></p>
                            <p>The Data Protection Legislation sets out the following key rights applicable to data subjects:</p>
                            <ol>
                                <p><li>The right to be informed;</li></p>
                                <p><li>the right of access;</li></p>
                                <p><li>the right to rectification;</li></p>
                                <p><li>the right to erasure (also known as the ‘right to be forgotten’);</li></p>
                                <p><li>the right to restrict processing;</li></p>
                                <p><li>the right to data portability;</li></p>
                                <p><li>the right to object; and</li></p>
                                <p><li>rights with respect to automated decision-making and profiling.</li></p>
                            </ol>
                            <p class="fw-bold"><li>Lawful, Fair, and Transparent Data Processing</li></p>
                            <ol>
                                <p><li>The Data Protection Legislation seeks to ensure that personal data is processed lawfully, fairly, and transparently, without adversely affecting the
                                    rights of the data subject. Specifically, the processing of personal data shall be
                                    lawful if at least one of the following applies:</li></p>
                                <ol type="a">
                                    <p><li>the data subject has given consent to the processing of their personal
                                        data for one or more specific purposes;</li></p>
                                    <p><li>the processing is necessary for the performance of a contract to which
                                        the data subject is a party, or in order to take steps at the request of
                                        the data subject prior to entering into a contract;</li></p>
                                    <p><li>the processing is necessary for compliance with a legal obligation to
                                        which the data controller is subject;</li></p>
                                    <p><li>the processing is necessary to protect the vital interests of the data
                                        subject or of another natural person;
                                    </li></p>
                                    <p><li>the processing is necessary for the performance of a task carried out in
                                        the public interest or in the exercise of official authority vested in the
                                        data controller; or</li></p>
                                    <p><li>the processing is necessary for the purposes of the legitimate interests
                                        pursued by the data controller or by a third party, except where such
                                        interests are overridden by the fundamental rights and freedoms of the
                                        data subject which require protection of personal data, in particular
                                        where the data subject is a child.</li></p>
                                </ol>
                            </ol>
                            <p class="fw-bold"><li>Consent</li></p>
                            <p>If consent is relied upon as the lawful basis for collecting, holding, and/or processing
                                personal data, the following shall apply:</p>
                            <ol>
                                <p><li>Consent is a clear indication by the data subject that they agree to the
                                    processing of their personal data. Such a clear indication may take the form of
                                    a statement or a positive action. Silence, pre-ticked boxes, or inactivity are
                                    unlikely to amount to consent.</li></p>
                                <p><li>Where consent is given in a document which includes other matters, the
                                    section dealing with consent must be kept clearly separate from such other
                                    matters.</li></p>
                                <p><li>Data subjects are free to withdraw consent at any time and it must be made
                                    easy for them to do so. If a data subject withdraws consent, their request must
                                    be honoured promptly.
                                </li></p>
                                <p><li>If personal data is to be processed for a different purpose that is incompatible
                                    with the purpose or purposes for which that personal data was originally
                                    collected that was not disclosed to the data subject when they first provided
                                    their consent, consent to the new purpose or purposes may need to be
                                    obtained from the data subject.</li></p>
                                <p><li>In all cases where consent is relied upon as the lawful basis for collecting,
                                    holding, and/or processing personal data, records must be kept of all consents
                                    obtained in order to ensure that the Company can demonstrate its compliance
                                    with consent requirements.
                                </li></p>
                            </ol>
                            <p class="fw-bold"><li>Specified, Explicit, and Legitimate Purposes</li></p>
                            <ol>
                                <p><li>The Company collects and processes the personal data set out in insert location(s). This includes:
                                    <ol type="a">
                                        <li>personal data collected directly from data subjects[.] OR [; and]</li>
                                        <li>[personal data obtained from third parties.]</li>
                                    </ol>
                                </li></p>
                                <p><li>The Company only collects, processes, and holds personal data for the
                                    specific purposes set out in insert location(s) (or for other purposes
                                    expressly permitted by the Data Protection Legislation).</li></p>
                                <p><li>Data subjects must be kept informed at all times of the purpose or purposes
                                    for which the Company uses their personal data. Please refer to Part 15 for
                                    more information on keeping data subjects informed.
                                </li></p>
                            </ol>
                            <p class="fw-bold"><li>Adequate, Relevant, and Limited Data Processing</li></p>
                            <ol>
                                <p><li>The Company will only collect and process personal data for and to the extent
                                    necessary for the specific purpose or purposes of which data subjects have
                                    been informed (or will be informed) as under Part 8, above, and as set out in
                                    insert location(s).</li></p>
                                <p><li>Employees, agents, contractors, or other parties working on behalf of the
                                    Company may collect personal data only to the extent required for the
                                    performance of their job duties and only in accordance with this Policy.
                                    Excessive personal data must not be collected.</li></p>
                                <p><li>Employees, agents, contractors, or other parties working on behalf of the
                                    Company may process personal data only when the performance of their job
                                    duties requires it. Personal data held by the Company cannot be processed
                                    for any unrelated reasons.
                                </li></p>
                            </ol>
                            <p class="fw-bold"><li>Accuracy of Data and Keeping Data Up-to-Date</li></p>
                            <ol>
                                <p><li>The Company shall ensure that all personal data collected, processed, and
                                    held by it is kept accurate and up-to-date. This includes, but is not limited to,
                                    the rectification of personal data at the request of a data subject, as set out in
                                    Part 17, below.</li></p>
                                <p><li>The accuracy of personal data shall be checked when it is collected and at
                                    [regular] OR [insert interval] intervals thereafter. If any personal data is
                                    found to be inaccurate or out-of-date, all reasonable steps will be taken
                                    without delay to amend or erase that data, as appropriate.</li></p>
                            </ol>
                            <p class="fw-bold"><li>Data Retention</li></p>
                            <ol>
                                <p><li>The Company shall not keep personal data for any longer than is necessary in
                                    light of the purpose or purposes for which that personal data was originally
                                    collected, held, and processed.</li></p>
                                <p><li>When personal data is no longer required, all reasonable steps will be taken to
                                    erase or otherwise dispose of it without delay.</li></p>
                                <p><li>For full details of the Company’s approach to data retention, including
                                    retention periods for specific personal data types held by the Company, please
                                    refer to our Data Retention Policy.
                                </li></p>
                            </ol>
                            <p class="fw-bold"><li>Secure Processing</li></p>
                            <ol>
                                <p><li>The Company shall ensure that all personal data collected, held, and processed is kept secure and protected against unauthorised or unlawful
                                    processing and against accidental loss, destruction, or damage. Further
                                    details of the technical and organisational measures which shall be taken are
                                    provided in the Company’s [Data Security Policy] AND/OR [IT Security
                                    Policy].</li></p>
                                <p><li>All technical and organisational measures taken to protect personal data shall
                                    be regularly reviewed and evaluated to ensure their ongoing effectiveness and
                                    the continued security of personal data.
                                </li></p>
                                <p>
                                    <li>Data security must be maintained at all times by protecting the confidentiality,
                                        integrity, and availability of all personal data as follows:
                                        <ol type="a">
                                            <li>only those with a genuine need to access and use personal data and who are authorised to do so may access and use it;</li>
                                            <li>personal data must be accurate and suitable for the purpose or purposes for which it is collected, held, and processed; and</li>
                                            <li>authorised users must always be able to access the personal data as required for the authorised purpose or purposes.</li>
                                        </ol>
                                    </li>

                                </p>
                            </ol>
                            <p class="fw-bold"><li>Accountability and Record-Keeping</li></p>
                            <ol>
                                <p><li>The Data Protection Officer is responsible for administering this Policy and for
                                    developing and implementing any applicable related policies, procedures,
                                    and/or guidelines.</li></p>
                                <p><li>The Company shall follow a privacy by design approach at all times when
                                    collecting, holding, and processing personal data. Data Protection Impact
                                    Assessments shall be conducted if any processing presents a significant risk
                                    to the rights and freedoms of data subjects (please refer to Part 14 for further
                                    information).</li></p>
                                <p><li>All employees, agents, contractors, or other parties working on behalf of the
                                    Company shall be given appropriate training in data protection and privacy,
                                    addressing the relevant aspects of the Data Protection Legislation, this Policy,
                                    and all other applicable Company policies.</li></p>
                                <p><li>The Company’s data protection compliance shall be regularly reviewed and
                                    evaluated by means of Data Protection Audits.
                                </li></p>
                                <p><li>The Company shall keep written internal records of all personal data
                                    collection, holding, and processing, which shall incorporate the following:

                                    <ol type="a">
                                        <li>the name and details of the Company, its Data Protection Officer, and
                                            any applicable third-party data transfers (including data processors and
                                            other data controllers with whom personal data is shared);</li>
                                        <li>the purposes for which the Company collects, holds, and processes
                                            personal data;</li>
                                        <li>the Company’s legal basis or bases (including, but not limited to,
                                            consent, the mechanism(s) for obtaining such consent, and records of
                                            such consent) for collecting, holding, and processing personal data;</li>
                                        <li>details of the categories of personal data collected, held, and
                                            processed by the Company, and the categories of data subject to
                                            which that personal data relates;
                                        </li>
                                        <li>details of any transfers of personal data to non-UK countries including
                                            all mechanisms and security safeguards;
                                        </li>
                                        <li>details of how long personal data will be retained by the Company
                                            (please refer to the Company’s Data Retention Policy);
                                        </li>
                                        <li>details of personal data storage, including location(s); and
                                        </li>
                                        <li>detailed descriptions of all technical and organisational measures
                                            taken by the Company to ensure the security of personal data.</li>
                                    </ol></li></p>
                            </ol>
                            <p class="fw-bold"><li>Data Protection Impact Assessments and Privacy by Design</li></p>
                            <ol>
                                <p><li>In accordance with privacy by design principles, the Company shall carry out
                                    Data Protection Impact Assessments for any and all new projects and/or new
                                    uses of personal data which involve the use of new technologies and where
                                    the processing involved is likely to result in a high risk to the rights and
                                    freedoms of data subjects.
                                </li></p>
                                <p><li>The principles of privacy by design should be followed at all times when
                                    collecting, holding, and processing personal data. The following factors should
                                    be taken into consideration:
                                    <ol type="a">
                                        <li>the nature, scope, context, and purpose or purposes of the collection,
                                            holding, and processing;
                                        </li>
                                        <li>the state of the art of all relevant technical and organisational
                                            measures to be taken;</li>
                                        <li>the cost of implementing such measures; and</li>
                                        <li>the risks posed to data subjects and to the Company, including their
                                            likelihood and severity.
                                        </li>
                                    </ol>
                                </li></p>
                                <p><li>Data Protection Impact Assessments shall be overseen by the Data Protection
                                    Officer and shall address the following:
                                    <ol type="a">
                                        <li>the type(s) of personal data that will be collected, held, and processed;</li>
                                        <li>the purpose(s) for which personal data is to be used;</li>
                                        <li>the Company’s objectives;</li>
                                        <li>how personal data is to be used;</li>
                                        <li>the parties (internal and/or external) who are to be consulted;</li>
                                        <li>the necessity and proportionality of the data processing with respect to
                                            the purpose(s) for which it is being processed;</li>
                                        <li>risks posed to data subjects;</li>
                                        <li>risks posed both within and to the Company; and</li>
                                        <li>proposed measures to minimise and handle identified risks.
                                        </li>
                                    </ol>
                                </li></p>
                            </ol>
                            <p class="fw-bold"><li>Keeping Data Subjects Informed</li></p>
                            <ol>
                                <p><li>The Company shall provide the information set out in Part 15.2 to every data subject:
                                    <ol type="a">
                                        <li>where personal data is collected directly from data subjects, those data subjects will be informed of its purpose at the time of collection; and</li>
                                        <li>where personal data is obtained from a third party, the relevant data subjects will be informed of its purpose:</li>
                                        <ol type="i">
                                            <li>if the personal data is used to communicate with the data
                                                subject, when the first communication is made; or</li>
                                            <li>if the personal data is to be transferred to another party, before
                                                that transfer is made; or</li>
                                            <li>as soon as reasonably possible and in any event not more than
                                                one month after the personal data is obtained.
                                            </li>
                                        </ol>
                                    </ol>
                                </li></p>
                                <p><li>The following information shall be provided in the form of a privacy notice:
                                    <ol type="a">
                                        <li>details of the Company including, but not limited to, contact details, and
                                            the names and contact details of any applicable representatives and its
                                            Data Protection Officer;
                                        </li>
                                        <li>the purpose(s) for which the personal data is being collected and will
                                            be processed (as detailed in insert location(s)) and the lawful
                                            basis justifying that collection and processing;</li>
                                        <li>where applicable, the legitimate interests upon which the Company is
                                            justifying its collection and processing of the personal data;
                                        </li>
                                        <li>where the personal data is not obtained directly from the data subject,
                                            the categories of personal data collected and processed;</li>
                                        <li>where the personal data is to be transferred to one or more third
                                            parties, details of those parties;
                                        </li>
                                        <li>where the personal data is to be transferred to a third party that is
                                            located outside of the UK, details of that transfer, including but not
                                            limited to the safeguards in place (see Part 25 of this Policy for further
                                            details);</li>
                                        <li>details of applicable data retention periods;</li>
                                        <li>details of the data subject’s rights under the Data Protection
                                            Legislation;</li>
                                        <li>details of the data subject’s right to withdraw their consent to the
                                            Company’s processing of their personal data at any time;</li>
                                        <li>details of the data subject’s right to complain to the Information
                                            Commissioner’s Office;</li>
                                        <li>where the personal data is not obtained directly from the data subject,
                                            details about the source of that personal data;</li>
                                        <li>where applicable, details of any legal or contractual requirement or
                                            obligation necessitating the collection and processing of the personal
                                            data and details of any consequences of failing to provide it; and</li>
                                        <li>details of any automated decision-making or profiling that will take
                                            place using the personal data, including information on how decisions
                                            will be made, the significance of those decisions, and any
                                            consequences.</li>
                                    </ol>
                                </li></p>
                            </ol>
                            <p class="fw-bold"><li>Data Subject Access</li></p>
                            <ol>
                                <p><li>Data subjects may make subject access requests (“SARs”) at any time to find
                                    out more about the personal data which the Company holds about them, what
                                    it is doing with that personal data, and why.</li></p>
                                <p><li>Employees wishing to make a SAR should do so using a Subject Access Request Form, sending the form to the Company’s Data Protection Officer at
                                    insert contact details.</li></p>
                                <p><li>Responses to SARs must normally be made within one month of receipt,
                                    however, this may be extended by up to two months if the SAR is complex
                                    and/or numerous requests are made. If such additional time is required, the
                                    data subject shall be informed.
                                </li></p>
                                <p><li>All SARs received shall be handled by the Company’s Data Protection Officer
                                    [and in accordance with the Company’s Data Subject Access Request Policy
                                    & Procedure].
                                </li></p>
                                <p><li>The Company does not charge a fee for the handling of normal SARs. The
                                    Company reserves the right to charge reasonable fees for additional copies of
                                    information that has already been supplied to a data subject, and for requests
                                    that are manifestly unfounded or excessive, particularly where such requests
                                    are repetitive.
                                </li></p>
                            </ol>
                            <p class="fw-bold"><li>Rectification of Personal Data</li></p>
                            <ol>
                                <p><li>Data subjects have the right to require the Company to rectify any of their
                                    personal data that is inaccurate or incomplete.</li></p>
                                <p><li>The Company shall rectify the personal data in question, and inform the data
                                    subject of that rectification, within one month of the data subject informing the
                                    Company of the issue. The period can be extended by up to two months in the
                                    case of complex requests. If such additional time is required, the data subject
                                    shall be informed.
                                </li></p>
                                <p><li>In the event that any affected personal data has been disclosed to third
                                    parties, those parties shall be informed of any rectification that must be made
                                    to that personal data.</li></p>
                            </ol>
                            <p class="fw-bold"><li>Erasure of Personal Data</li></p>
                            <ol>
                                <p><li>Data subjects have the right to request that the Company erases the personal
                                    data it holds about them in the following circumstances:
                                    <ol type="a">
                                        <li>it is no longer necessary for the Company to hold that personal data
                                            with respect to the purpose(s) for which it was originally collected or
                                            processed;</li>
                                        <li>the data subject wishes to withdraw their consent to the Company
                                            holding and processing their personal data;</li>
                                        <li>the data subject objects to the Company holding and processing their
                                            personal data (and there is no overriding legitimate interest to allow the
                                            Company to continue doing so) (see Part 21 of this Policy for further
                                            details concerning the right to object);
                                        </li>
                                        <li>the personal data has been processed unlawfully;</li>
                                        <li>the personal data needs to be erased in order for the Company to
                                            comply with a particular legal obligation[;] OR [.]</li>
                                        <li>[the personal data is being held and processed for the purpose of
                                            providing information society services to a child.]</li>
                                    </ol>
                                </li></p>
                                <p><li>Unless the Company has reasonable grounds to refuse to erase personal
                                    data, all requests for erasure shall be complied with, and the data subject informed of the erasure, within one month of receipt of the data subject’s
                                    request. The period can be extended by up to two months in the case of
                                    complex requests. If such additional time is required, the data subject shall be
                                    informed.</li></p>
                                <p><li>In the event that any personal data that is to be erased in response to a data
                                    subject’s request has been disclosed to third parties, those parties shall be
                                    informed of the erasure (unless it is impossible or would require
                                    disproportionate effort to do so).</li></p>
                            </ol>
                            <p class="fw-bold"><li>Restriction of Personal Data Processing</li></p>
                            <ol>
                                <p><li>Data subjects may request that the Company ceases processing the personal
                                    data it holds about them. If a data subject makes such a request, the
                                    Company shall retain only the amount of personal data concerning that data
                                    subject (if any) that is necessary to ensure that the personal data in question
                                    is not processed further</li></p>
                                <p><li>In the event that any affected personal data has been disclosed to third
                                    parties, those parties shall be informed of the applicable restrictions on
                                    processing it (unless it is impossible or would require disproportionate effort to
                                    do so).</li></p>
                            </ol>
                            <p class="fw-bold"><li>Objections to Personal Data Processing</li></p>
                            <ol>
                                <p><li>Data subjects have the right to object to the Company processing their
                                    personal data based on legitimate interests, for direct marketing (including
                                    profiling), [and processing for scientific and/or historical research and statistics
                                    purposes].
                                </li></p>
                                <p><li>Where a data subject objects to the Company processing their personal data
                                    based on its legitimate interests, the Company shall cease such processing
                                    immediately, unless it can be demonstrated that the Company’s legitimate
                                    grounds for such processing override the data subject’s interests, rights, and
                                    freedoms, or that the processing is necessary for the conduct of legal claims.
                                </li></p>
                                <p><li>Where a data subject objects to the Company processing their personal data
                                    for direct marketing purposes, the Company shall cease such processing
                                    promptly.</li></p>
                                <p><li>[Where a data subject objects to the Company processing their personal data
                                    for scientific and/or historical research and statistics purposes, the data
                                    subject must, under the Data Protection Legislation, “demonstrate grounds
                                    relating to his or her particular situation”. The Company is not required to
                                    comply if the research is necessary for the performance of a task carried out
                                    for reasons of public interest.]
                                </li></p>
                            </ol>
                            <p class="fw-bold"><li>[Direct Marketing</li></p>
                            <ol>
                                <p><li>The Company is subject to certain rules and regulations when marketing its
                                    [products] <b>AND/OR</b> [services].
                                </li></p>
                                <p><li>The prior consent of data subjects is required for electronic direct marketing
                                    including email, text messaging, and automated telephone calls subject to the
                                    following limited exception:
                                    <ol type="a">
                                        <p><li>The Company may send marketing text messages or emails to a
                                            customer provided that that customer’s contact details have been
                                            obtained in the course of a sale, the marketing relates to similar
                                            products or services, and the customer in question has been given the
                                            opportunity to opt-out of marketing when their details were first
                                            collected and in every subsequent communication from the Company.
                                        </li></p>
                                    </ol></li></p>
                                <p><li>The right to object to direct marketing shall be explicitly offered to data
                                    subjects in a clear and intelligible manner and must be kept separate from
                                    other information in order to preserve its clarity.</li></p>
                                <p><li>If a data subject objects to direct marketing, their request must be complied
                                    with promptly. A limited amount of personal data may be retained in such
                                    circumstances to the extent required to ensure that the data subject’s
                                    marketing preferences continue to be complied with.]
                                </li></p>
                            </ol>
                            <p class="fw-bold"><li>Personal Data Collected, Held, and Processed</li></p>
                            <p>Full details of the personal data collected, held, and processed by the Company are
                                located in insert location(s). For details of data retention, please refer to the
                                Company’s Data Retention Policy.</p>
                            <p class="fw-bold"><li>Transferring Personal Data to a Country Outside the UK</li></p>
                            <ol>
                                <p><li>The Company may, from time to time, transfer (‘transfer’ includes making
                                    available remotely) personal data to countries outside of the UK. The Data
                                    Protection Legislation restricts such transfers in order to ensure that the level
                                    of protection given to data subjects is not compromised.</li></p>
                                <p><li>Personal data may only be transferred to a country outside the UK if one of
                                    the following applies:
                                    <ol type="a">
                                        <li>The UK has issued regulations confirming that the country in question
                                            ensures an adequate level of protection (referred to as ‘adequacy
                                            decisions’ or ‘adequacy regulations’). Since 1 January 2021, transfers
                                            of personal data from the UK to EEA countries have continued to be
                                            permitted. Pre-existing EU Commission adequacy decisions in effect
                                            as at 31 December 2020 are also recognised, subject to ongoing
                                            review by the UK Government.</li>
                                        <li>Appropriate safeguards are in place including binding corporate rules,
                                            standard contractual clauses approved for use in the UK, an approved
                                            code of conduct, or an approved certification mechanism. Standard
                                            contractual clauses include the International Data Transfer Agreement
                                            issued by the Information Commissioner’s Office and the International
                                            Data Transfer Addendum to the current EU Commission Standard
                                            Contractual Clauses (set out in the Annex of Commission
                                            Implementing Decision (EU) 2021/914 of 4 June 2021), issued by the
                                            Information Commissioner’s Office. (Contracts entered into on the
                                            basis of the old EU Commission Standard Contractual Clauses prior to
                                            21 September 2022 will continue to provide appropriate safeguards
                                            until 21 March 2024.)
                                        </li>
                                        <li>The transfer is made with the informed and explicit consent of the
                                            relevant data subject(s).</li>
                                        <li>The transfer is necessary for one of the other reasons set out in the UK
                                            GDPR including the performance of a contract between the data subject and the Company; public interest reasons; for the
                                            establishment, exercise, or defence of legal claims; to protect the vital
                                            interests of the data subject where the data subject is physically or
                                            legally incapable of giving consent; or, in limited circumstances, for the
                                            Company’s legitimate interests.

                                        </li>
                                    </ol>
                                </li></p>
                            </ol>
                            <p class="fw-bold"><li>Data Breach Notification</li></p>
                            <ol>
                                <p><li>All personal data breaches must be reported immediately to the Company’s
                                    Data Protection Officer.</li></p>
                                <p><li>If an employee, agent, contractor, or other party working on behalf of the
                                    Company becomes aware of or suspects that a personal data breach has
                                    occurred, they must not attempt to investigate it themselves. Any and all
                                    evidence relating to the personal data breach in question should be carefully
                                    retained.
                                </li></p>
                                <p><li>If a personal data breach occurs and that breach is likely to result in a risk to
                                    the rights and freedoms of data subjects (e.g. financial loss, breach of
                                    confidentiality, discrimination, reputational damage, or other significant social
                                    or economic damage), the Data Protection Officer must ensure that the
                                    Information Commissioner’s Office is informed of the breach without delay,
                                    and in any event, within 72 hours after having become aware of it.
                                </li></p>
                                <p><li>In the event that a personal data breach is likely to result in a high risk (that is,
                                    a higher risk than that described under Part 26.3) to the rights and freedoms of
                                    data subjects, the Data Protection Officer must ensure that all affected data
                                    subjects are informed of the breach directly and without undue delay.</li></p>
                                <p><li>Data breach notifications shall include the following information:
                                    <ol type="a">
                                        <li>The categories and approximate number of data subjects concerned;</li>
                                        <li>The categories and approximate number of personal data records
                                            concerned;</li>
                                        <li>The name and contact details of the Company’s data protection officer
                                            (or other contact point where more information can be obtained);</li>
                                        <li>The likely consequences of the breach;</li>
                                        <li>Details of the measures taken, or proposed to be taken, by the
                                            Company to address the breach including, where appropriate,
                                            measures to mitigate its possible adverse effects.
                                        </li>
                                    </ol>
                                </li></p>
                            </ol>
                            <p class="fw-bold"><li>Implementation of Policy</li></p>
                            <p>This Policy shall be deemed effective as of January 2023. No part of this Policy shall
                                have retroactive effect and shall thus apply only to matters occurring on or after this
                                date.</p>
                            {/* <p>This Policy has been approved and authorised by:</p>
                            <table>
                                <tr><th><p>Name</p></th><td><p style={{ marginLeft: "10%" }}>Insert Full Name
                                </p></td></tr>
                                <tr><th style={{ width: "20%" }}><p>Position</p></th><td><p style={{ marginLeft: "10%" }}>Insert Position
                                </p></td></tr>
                                <tr><th style={{ width: "20%" }}><p>Date</p></th><td><p style={{ marginLeft: "10%" }}>Insert Date
                                </p></td></tr>
                                <tr><th style={{ width: "20%" }}><p>Due for Review by</p></th><td><p style={{ marginLeft: "10%" }}>Insert Date
                                </p></td></tr>
                                <tr><th><p>Signature:</p></th></tr>
                            </table> */}
                        </ol>

                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
}

export default Dataprotection;
