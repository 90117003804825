import axios from "axios";
import React,{useEffect} from "react";
import LoadingSpinner from "../UIComponents/LoadingSpinner";


function Logout()
{
    function logout()
    {
        var id = localStorage.getItem('info');
        axios.post('https://api.entmcq.com/api/logout',{},{headers:{"Authorization" : `Bearer ${id}`}})
            .then((res)=>{
                console.log(res.data);
                localStorage.removeItem("login");
                localStorage.clear();
                window.location.href="/login";
            })
        

    }
    useEffect(()=>{
        logout();
    },[])
    return(
        <LoadingSpinner/>
    )
}
export default Logout;