import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
var CryptoJS = require("crypto-js");
function Category() {
    const [category, setCategory] = useState([])
    function fetchCategory() {
        axios.get('https://api.entmcq.com/api/fetchCategory')
            .then((res) => {
                const data = res.data;
                console.log(data);
                setCategory(data);
            })
    }
    useEffect(() => {
        fetchCategory()
    }, [])


    return (

        <React.Fragment>
            <div class="container-fluid">
                <div class="row">
                    <Header />

                </div>
            </div>

            <div class="container-fluid" id="aboutus">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-sm-12 d-flex justify-content-between">
                                    <h1>Specialist</h1>
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb mb-0" id="breadcrumb">
                                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                                            <li class="breadcrumb-item active" aria-current="page">specialist</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container pb-5" id="main-content">
                <div class="row">
                    

                    <div className="container" id="categories">
                        <div className="section-heading text-center">
                            <h3>Specialist</h3>
                        </div>
                        <div className="row gy-3">
                            {
                                category.map((obj) => {
                                    var id = CryptoJS.AES.encrypt("" + obj.id, 'my-secret-key@123').toString();
                                    id = id.replace(/\//g, '__')
                                    return (
                                        <div className="col-sm-3 col-6">
                                            <a href={"/specialist/" + id} style={{ textDecoration: 'none' }}><div className="card card-body h-100 shadow-lg">
                                                <img src={"https://api.entmcq.com/image/" + obj.image} />
                                                <p style={{ color: '#000', textDecoration: 'none' }}>{obj.name}</p>
                                            </div></a>
                                        </div>
                                    )
                                })
                            }

                        </div>
                        {/* <div style={{marginTop:40,}}>
                            <a href="/subscribe" style={{ textDecoration: 'none' }}>
                                <div className="col-sm-2 col-6">
                                    <div className="card card-body h-100 shadow-lg" style={{ padding: 10 + 'px', backgroundColor: '#0d5c63' }}>
                                        <p style={{ color: '#fff', fontWeight: 'bold' }}>Subscribe</p>
                                    </div>
                                </div>
                            </a>
                        </div> */}
                    </div>
                </div>
                <div className="row">

                </div>
            </div>
            <Footer />

        </React.Fragment>
    )
}

export default Category;