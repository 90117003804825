import axios from "axios";
import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { isEmail, isName, isMob, isPasss } from "./validators/Validations";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from "./UIComponents/LoadingSpinner";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import SpinnerLoad from "./UIComponents/SpinnerLoad";

function Error(){

    return(
        <React.Fragment>
            <ToastContainer />

            <div class="container-fluid">
                <div class="row">
                    <Header />
                    <h1>Page not Found 404</h1>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default Error;