import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import MetaTags from 'react-meta-tags';

function Prepare() {
    return (
        <React.Fragment>

            <MetaTags>
                <title>Prepare for Medical exam FRCS (ORL-HNS) with EntMCQ</title>
                <meta name="description" content="Prepare for the FRCS (ORL-HNS) Section One examination with our comprehensive question bank. Maximize your study time and enhance your chances of success." />
                <meta property="og:title" content="Prepare for Medical exam FRCS (ORL-HNS) with EntMCQ" />
                <meta property="keywords" content="test preparation,exam help uk,exam preparation,help with exam questions,study for exams,exam tips,medical board questions,question bank,medical exam questions,medical question bank,make an exam,frcs question bank,medical exam preparation,1 month study plan,exam preparation questions,to study for an exam,frcs books,exam materials,time management for exam preparationent clinical practice guidelines, ent resources for medical students, theoretical knowledge, mcqs for ent preparation for the frcs orl hns" />
                <link rel="canonical" href={window.location.href}/>
            </MetaTags>

            <div class="container-fluid">
                <div class="row">
                    <Header />

                </div>
            </div>

            <div class="container-fluid" id="aboutus">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-sm-12 col-12 d-flex justify-content-between">
                                    <h2>Preparing for the Exam</h2>

                                </div>
                                {/* <div class="col-sm-6 col-12 d-flex justify-content-between">
                                    
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb mb-0" id="breadcrumb">
                                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                                            <li class="breadcrumb-item active" aria-current="page">Preparing for the Exam</li>
                                        </ol>
                                    </nav>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container pb-5" id="main-content">
                <div class="row">
                    <div class="col-sm-12">
                        <h3 class="mb-4" style={{ fontWeight: 'bold', }}>Preparing for the Exam</h3>
                        <p style={{ textAlign: 'justify' }}>The journey of preparing for the FRCS (ORL-HNS) can be a long, boring, tiring and lonely one. We have all
                            been through it and want others to have a smoother, less turbulent and more enjoyable experience –
                            hence this website. Try to maximise your time when available and particularly utilise spare time during
                            the working day so that your evenings and weekends do not suffer longer or more often than they need
                            to. Between theatre cases or between clinical commitments are often perfect opportunities to do a few
                            practice questions, we often do not fully utilize the ‘dead time’ we have during our working day.</p>

                        <p style={{ textAlign: 'justify' }}>It is important to undertake some baseline theoretical learning and consolidate what you have previously learnt, many candidates use key textbooks which help build and improve theoretical knowledge and to start with that can often be helpful. Most candidates start preparing for this examination 3-6 months in advance, depending on their lifestyle and personal commitments or anticipated life events. A combination of reading and practicing questions such as on our website/app really is the key to maximising your chances of success.</p>
                        <p style={{ textAlign: 'justify' }}>Be careful with inherited resources and question books and mock papers, many circulating are very old
                            and not so relevant anymore based on the new ISCP and JCST curriculum.</p>
                        <p style={{ textAlign: 'justify' }}>As you undertake practice questions, you will highlight areas for improvement, notice patterns and themes related to question styles and will improve your confidence, ability to answer, your thought processes related to questions, and enhance your exam technique related to timings. Remember, you should really answer all questions in the timeframe, for both papers, to maximise your chances of success.</p>
                        <p style={{ textAlign: 'justify' }}>To date, there is no other website in existence that specifically provides you with a question bank for the FRCS (ORL-HNS) Section One examination, we hope you find this resource useful and we know that closer to the time, practicing questions is the best way to increase your chances of successfully passing a multiple choice answer/single best answer style ‘written’ examination. You may find some questions strange or not clinically relevant or different to your practice, please remember you still need to answer it with your single best answer, as one answer will be better than others, albeit possibly not ideal or incorrect by your standards.</p>
                    </div>
                    <div class="col-lg-6">
                        <p style={{ textAlign: 'justify' }}>In the last six weeks leading up to the examination, practicing questions daily is imperative to enhance and focus your learning and revision. Our website will afford you the opportunity to:</p>
                        <ul>
                            <li><p style={{ textAlign: 'justify' }}>Learn by repetition, both of your successes and areas for improvement</p></li>
                            <li><p style={{ textAlign: 'justify' }}>Have a chance to find links to resources to explain answers further</p></li>
                            <li><p style={{ textAlign: 'justify' }}>Undertake timed mock examinations as per the real-life examination</p></li>
                            <li><p style={{ textAlign: 'justify' }}>Answer by sub-specialty, random choices, or all, depending on your preference(s)</p></li>
                            <li><p style={{ textAlign: 'justify' }}>Review those questions you answered incorrectly only, to further analyse them and undertake self-directed learning prior to re-attempting them</p></li>
                            <li><p style={{ textAlign: 'justify' }}>Have 24/7 unlimited access to our question bank, reset the question bank and learn again throughout your whole subscription period</p></li>
                            <li><p style={{ textAlign: 'justify' }}>Have access to exactly the same format and question bank on our Mobile App, which will link to your account, so you can continue where you left off and revert back to your computer/tablet in an instant, without losing your stored and saved information</p></li>
                        </ul>
                    </div>
                    <div class="col-lg-6">
                        <img src="/assets/images/4119036.jpg" class="img-fluid" style={{ height: 500 }} />
                    </div>
                    <div class="col-sm-12">
                        <p style={{ textAlign: 'justify' }}>We wish you all the best in your learning and examination journey and we are confident that this website will significantly contribute to enhancing your preparation to successfully pass the FRCS (ORL-HNS) Section One examination. We all wish we had access to a unique resource like this when preparing for our examinations, it would have saved hours of reading through inherited notes, papers, books and access to never ending and often unhelpful shared online files and folders etc.</p>
                        <p style={{ textAlign: 'justify' }}>If you would like further information, please do not hesitate to contact us anytime.</p>
                        <p class="fw-bold">Good Luck!</p>
                    </div>
                </div>
            </div>
            <Footer />

        </React.Fragment>
    )
}

export default Prepare;