import React, { useState, useEffect } from "react";
import axios from "axios";
var CryptoJS = require("crypto-js");
function Footer() {
    const [category, setCategory] = useState([])
    const tdt = new Date();
    function fetchCategory() {
        axios.get('https://api.entmcq.com/api/fetchCategory')
            .then((res) => {
                const data = res.data;
                setCategory(data);
                // console.log(data);
            })
    }

    useEffect(() => {
        fetchCategory()
    }, [])
    return (
        <React.Fragment>
            <footer>
                <div class="container">
                    <div class="row">
                        <div class="col-sm-4 col-6">
                            <h5>Website Categories</h5>
                            <ul>
                                {
                                    category.map((obj) => {
                                        var id = CryptoJS.AES.encrypt("" + obj.id, 'my-secret-key@123').toString();
                                        id = id.replace(/\//g, '__')
                                        return (
                                            <li><a href={"/specialist/" + id}>{obj.name}</a></li>
                                        )
                                    })
                                }
                                {/* <li><a href="#">Otology</a></li>
                        <li><a href="#">Head and Neck</a></li>
                        <li><a href="#">Rhinology and Facial Plastics</a></li>
                        <li><a href="#">Paediatric ENT</a></li> */}
                            </ul>
                        </div>
                        <div class="col-sm-4 col-6 text-start text-lg-center">
                            <h5>Quick Links</h5>
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li><a href="/about-us">About Us</a></li>
                                <li><a href="/subscribe">Subscriptions</a></li>
                                {/* <li><a href="#">Contact Us</a></li> */}
                                <li><a href="/terms-conditions">Terms and conditions</a></li>
                                <li><a href="/privacy-policy">Privacy Policy</a></li>
                                <li><a href="/data-protection-policy">Data Protection Policy</a></li>
                            </ul>
                        </div>
                        <div class="col-sm-4 col-12 text-start text-lg-end">
                            <h5>Get In Touch</h5>
                            <ul>
                                {/* <li><a href="#"><i class="bi bi-telephone"></i>(000) 0000 0000</a></li> */}
                                {/* <li><a href="#"><i class="bi bi-geo-alt"></i>4517 UK - 37</a></li> */}
                                <li><a href="/contact-us">Contact Us</a></li>
                                <li><a href="mailto:support@entmcq.com"><i class="bi bi-envelope"></i>support@entmcq.com</a></li>
                                <li>
                                    <div class="text-end col">
                                        <a href="https://www.facebook.com/profile.php?id=100088949200739" target="_blank"><i class="bi bi-facebook" style={{ fontSize: 20 }}></i></a>
                                        <a href="https://www.instagram.com/entmcq/" target="_blank"><i class="bi bi-instagram" style={{ fontSize: 20 }}></i></a>
                                        <a href="https://twitter.com/EntMcq" target="_blank"><i class="bi bi-twitter" style={{ fontSize: 20 }}></i></a>
                                        {/* <a href="https://www.linkedin.com/in/vikas-acharya-62a76325b/" target="_blank"><i class="bi bi-linkedin" style={{ fontSize: 20 }}></i></a> */}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>

            <div id="footer-bottom">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-6 col-auto">
                            Copyright {tdt.getFullYear()}. All Right Reserved
                        </div>
                        <div class="col-sm-6 text-end col">
                            <a href="https://www.facebook.com/profile.php?id=100088949200739" target="_blank"><i class="bi bi-facebook" style={{ fontSize: 20 }}></i></a>
                            <a href="https://www.instagram.com/entmcq/" target="_blank"><i class="bi bi-instagram" style={{ fontSize: 20 }}></i></a>
                            <a href="https://twitter.com/EntMcq" target="_blank"><i class="bi bi-twitter" style={{ fontSize: 20 }}></i></a>
                            {/* <a href="https://www.linkedin.com/in/vikas-acharya-62a76325b/" target="_blank"><i class="bi bi-linkedin" style={{ fontSize: 20 }}></i></a> */}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Footer;