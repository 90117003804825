import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import MetaTags from 'react-meta-tags';
function Privacypolicy() {
    return (
        <React.Fragment>
            <MetaTags>
                <title>Privacy Policy for ENT MCQ - FRCS (ORL-HNS) Preparation</title>
                <meta name="description" content="Protecting your privacy at ENT MCQ. Learn how we handle personal information, ensure data security, and provide cookie preferences. Explore our FRCS Exam Privacy Policy" />
                <meta property="og:title" content="Privacy Policy for ENT MCQ - FRCS (ORL-HNS) Prep" />
                <link rel="canonical" href={window.location.href}/>
                <meta property="keywords" content="dysphagia ent,
bell's palsy ent, frcs ent section one exam, frcs mcq exam, frcs sba exam" />
            </MetaTags>
            <div class="container-fluid">
                <div class="row">
                    <Header />

                </div>
            </div>

            <div class="container-fluid" id="aboutus">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-sm-12 d-flex justify-content-between">
                                    <h1>Privacy Policy</h1>
                                    {/* <nav aria-label="breadcrumb">
                                <ol class="breadcrumb mb-0" id="breadcrumb">
                                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Privacy Policy</li>
                                </ol>
                            </nav> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container pb-5" id="main-content">
                <div class="row">
                    <div class="col-sm-12">
                        {/* <h3 class="mb-4">About Us</h3> */}
                        <h5><b>BACKGROUND:</b></h5>
                        <p>ENT Educational Courses Ltd understands that your privacy is important to you and that you
                            care about how your personal data is used. We respect and value the privacy of everyone
                            who visits this website, www.entmcq.com (“Our Site”) and only collect and use your personal
                            data as described in this Privacy Policy. Any personal data we collect will only be used as
                            permitted by law.</p>
                        <p>Please read this Privacy Policy carefully and ensure that you understand it.</p>
                        <p>Information About Us:</p>
                        <ul>
                            <p><li>Our Site is owned and operated by ENT Educational Courses Ltd, a limited company
                                registered in England. Registered address 61, Bridge Street, Kington, Herefordshire,
                                HR5 3DJ </li></p>
                            <p>Email address: support@entmcq.com.</p>
                            <p>Postal address: 61, Bridge Street, Kington, Herefordshire, HR5 3DJ</p>
                        </ul>
                        <ol>
                            <p class="fw-bold"><li>What Does This Policy Cover?</li></p>
                            <p>This Privacy Policy applies only to your use of Our Site. Our Site may contain links to
                                other websites. Please note that we have no control over how your data is collected,
                                stored, or used by other websites and we advise you to check the privacy policies of
                                any such websites before providing any data to them.
                            </p>
                            <p class="fw-bold"><li>What Is Personal Data?</li></p>
                            <p>Personal data is defined by the UK GDPR and the Data Protection Act 2018
                                (collectively, “the Data Protection Legislation”) as ‘any information relating to an
                                identifiable person who can be directly or indirectly identified in particular by reference
                                to an identifier’.
                            </p><p>Personal data is, in simpler terms, any information about you that enables you to be
                                identified. Personal data covers obvious information such as your name and contact
                                details, but it also covers less obvious information such as identification numbers,
                                electronic location data, and other online identifiers.
                            </p>
                            <p class="fw-bold"><li>What Are My Rights?</li></p>
                            <p>Under the Data Protection Legislation, you have the following rights, which we will
                                always work to uphold:</p>
                            <ol type="a">
                                <p><li>The right to be informed about our collection and use of your personal data.
                                    This Privacy Policy should tell you everything you need to know, but you can
                                    always contact us to find out more or to ask any questions using the details in
                                    Part 10.</li></p>
                                <p><li>The right to access the personal data we hold about you. Part 9 will tell you
                                    how to do this.
                                </li></p>
                                <p><li>The right to have your personal data rectified if any of your personal data held
                                    by us is inaccurate or incomplete. Please contact us using the details in Part
                                    10 to find out more.</li></p>
                                <p><li>The right to be forgotten, i.e. the right to ask us to delete or otherwise dispose
                                    of any of your personal data that we hold. Please contact us using the details
                                    in Part 10 to find out more.</li></p>
                                <p><li>The right to restrict (i.e. prevent) the processing of your personal data.</li></p>
                                <p><li>The right to object to us using your personal data for a particular purpose or
                                    purposes.</li></p>
                                <p><li>The right to withdraw consent. This means that, if we are relying on your
                                    consent as the legal basis for using your personal data, you are free to
                                    withdraw that consent at any time.</li></p>
                                <p><li>The right to data portability. This means that, if you have provided personal
                                    data to us directly, we are using it with your consent or for the performance of
                                    a contract, and that data is processed using automated means, you can ask
                                    us for a copy of that personal data to re-use with another service or business
                                    in many cases.
                                </li></p>
                                <p><li>Rights relating to automated decision-making and profiling. We do not use
                                    your personal data in this way.</li></p>
                            </ol>
                            <p>For more information about our use of your personal data or exercising your rights as
                                outlined above, please contact us using the details provided in Part 10.</p>
                            <p>It is important that your personal data is kept accurate and up-to-date. If any of the
                                personal data we hold about you changes, please keep us informed as long as we
                                have that data.</p>
                            <p>Further information about your rights can also be obtained from the Information
                                Commissioner’s Office or your local Citizens Advice Bureau.</p>
                            <p>If you have any cause for complaint about our use of your personal data, you have
                                the right to lodge a complaint with the Information Commissioner’s Office. We would
                                welcome the opportunity to resolve your concerns ourselves, however, so please
                                contact us first, using the details in Part 10.</p>
                            <p class="fw-bold"><li>What Personal Data Do You Collect and How?</li></p>
                            <p>Subject to the following, we do not collect any personal data from you. We do not
                                place cookies on your computer or device, nor do we use any other means of data
                                collection.</p>
                            <p>Our Site collects certain information automatically, including your IP address, the type
                                of browser you are using, and certain other non-personal data about your computer or
                                device such as your operating system type or version, and display resolution.</p>
                            <p>If you send us an email, we may collect your name, your email address, and any
                                other information which you choose to give us. For the purposes of the Data
                                Protection Legislation, We are the data controller responsible for such personal data.
                            </p>
                            <p>The lawful basis under the Data Protection Legislation that allows us to use such
                                information is article 6(1)(f) of the UK GDPR which allows us to process personal data
                                when it is necessary for the purposes of our legitimate interests, in this case, the
                                proper operation and functionality of Our Site. If you contact us as described above,
                                you will be required to consent to our use of your personal data to contact you. In this
                                case, our lawful basis for using your personal data will be article 6(1)(a) of the UK
                                GDPR, which allows us to use your personal data with your consent for a particular
                                purpose or purposes.</p>
                            <p class="fw-bold"><li>How Do You Use My Personal Data?</li></p>
                            <p>Where we collect any personal data, it will be processed and stored securely, for no onger than is necessary in light of the reason(s) for which it was first collected. We
                                will comply with our obligations and safeguard your rights under the Data Protection
                                Legislation at all times. For more details on security see Part 7, below.</p>
                            <p>As stated above, we do not generally collect any personal data directly from you, but
                                if you contact us and we obtain your personal details from your email, we may use
                                them to respond to your email. The other technical data referred to above is
                                necessary for the technical operation of Our Site.</p>
                            <p>Any and all emails containing your personal data will be deleted no later than three
                                years (3) after and no other personal data will be retained for any longer than is
                                necessary.</p>
                            <p>We will not share any of your personal data with any third parties for any purposes
                                other than storage on an email and/or web hosting server.</p>
                            <p class="fw-bold"><li>How and Where Do You Store My Data?</li></p>
                            <p>We will store some of your personal data in the UK. This means that it will be fully
                                protected under the Data Protection Legislation.
                            </p>
                            <p><b>AND</b></p>
                            <p>We will only store your personal data within the European Economic Area (the
                                “EEA”). The EEA consists of all EU member states, plus Norway, Iceland, and
                                Liechtenstein. This means that your personal data will be fully protected under the EU
                                GDPR and/or to equivalent standards by law. Transfers of personal data to the EEA
                                from the UK are permitted without additional safeguards.
                            </p>
                            <p class="fw-bold"><li>Use of Cookies</li></p>
                            <p>This website uses cookies to better the users experience while visiting the website.
                                As required by legislation, where applicable this website uses a cookie control
                                system, allowing the user to give explicit permission or to deny the use of /saving of
                                cookies on their computer / device.
                            </p>
                            <p><b>What are cookies?</b></p>
                            <p>Cookies are small files saved to the user's computer’s hard drive that track, save and
                                store information about the user's interactions and usage of the website. This allows
                                the website, through its server to provide the users with a tailored experience within
                                this website. Users are advised that if they wish to deny the use and saving of cookies
                                from this website on to their computers hard drive they should take necessary steps
                                within their web browsers security settings to block all cookies from this website and its
                                external serving vendors or use the cookie control system if available upon their first
                                visit. </p>
                            <p class="fw-bold"><li>Do You Share My Personal Data?</li></p>
                            <p>We will not share any of your personal data with any third parties for any purposes,
                                subject to the following exception[s].</p>
                            <p>In some limited circumstances, we may be legally required to share certain personal
                                data, which might include yours, if we are involved in legal proceedings or complying
                                with legal obligations, a court order, or the instructions of a government authority.
                            </p>
                            <p class="fw-bold"><li>How Can I Access My Personal Data?</li></p>
                            <p>If you want to know what personal data we have about you, you can ask us for details of that personal data and for a copy of it (where any such personal data is held). This
                                is known as a “subject access request”.
                            </p>
                            <p>All subject access requests should be made in writing and sent to the email or postal
                                addresses shown in Part 10. (To make this as easy as possible for you, a Subject
                                Access Request Form is available for you to use.) You do not have to use this form,
                                but it is the easiest way to tell us everything we need to know to respond to your
                                request as quickly as possible.</p>
                            <p>There is not normally any charge for a subject access request. If your request is
                                ‘manifestly unfounded or excessive’ (for example, if you make repetitive requests) a
                                fee may be charged to cover our administrative costs in responding.</p>
                            <p>We will respond to your subject access request within thirty days and, in any case,
                                not more than one month of receiving it. Normally, we aim to provide a complete
                                response, including a copy of your personal data within that time. In some cases,
                                however, particularly if your request is more complex, more time may be required up
                                to a maximum of three months from the date we receive your request. You will be
                                kept fully informed of our progress.
                            </p>
                            <p class="fw-bold"><li>How Do I Contact You?</li></p>
                            <p>To contact us about anything to do with your personal data and data protection,
                                including to make a subject access request, please use the following details:
                            </p>
                            <ul>
                                <p>Email address: support@entmcq.com</p>
                                <p>Postal Address:</p>
                                <p>ENT Educational Courses Ltd. 61, Bridge Street, Kington, Herefordshire,
                                    HR5 3DJ
                                </p>
                            </ul>
                            <p class="fw-bold"><li>Changes to this Privacy Policy</li></p>
                            <p>We may change this Privacy Policy from time to time. This may be necessary, for
                                example, if the law changes, or if we change our business in a way that affects
                                personal data protection.</p>
                            <p>Any changes will be immediately posted on Our Site and you will be deemed to have
                                accepted the terms of the Privacy Policy on your first use of Our Site following the
                                alterations. We recommend that you check this page regularly to keep up-to-date.
                                This Privacy Policy was last updated on January 2023.</p>
                        </ol>
                    </div>

                </div>
            </div>
            <Footer />
        </React.Fragment>

    );
}

export default Privacypolicy;
