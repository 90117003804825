import React, { useState } from 'react';

function Header() {

    const [search, setSearch] = useState('');
    const [logstatus, setLogstatus] = useState(() => {
        const st = localStorage.getItem("login");
        return st || ""
    });

    function handleClick() {
        console.log(search);
        window.location.href = "/specialists/" + search;
    }
    //console.log(logstatus);
    return (
        <React.Fragment>
            <div className="col-sm-12 px-0">
                <nav className="navbar navbar-expand-lg bg-light">
                    <div className="container">
                        <a className="navbar-brand d-flex align-items-center d-lg-block" href="/">
                            <img src="/assets/images/ENT-Final-Logo.png" alt="logo" />
                            <p>The FRCS (ORL-HNS) Revision Aid</p>
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar"
                            aria-labelledby="offcanvasNavbarLabel">
                            <div className="offcanvas-header">
                                <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Navigation</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas"
                                    aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body">
                                <ul className="navbar-nav mx-auto">
                                    <li class="nav-item" style={{ marginTop: 5 }}>
                                        <a class="nav-link" aria-current="page" href="/">Home</a>
                                    </li>
                                    <li class="nav-item" style={{ marginTop: 5 }}>
                                        <a class="nav-link" href="/about-us/">About ENT MCQ</a>
                                    </li>
                                    <li class="nav-item" style={{ marginTop: 5 }}>
                                        <a class="nav-link" href="/medical-student">About the Exam</a>
                                    </li>
                                    <li class="nav-item" style={{ marginTop: 5 }}>
                                        <a class="nav-link" href="/preparing-exam">Preparing for the Exam</a>
                                    </li>
                                    <li class="nav-item" style={{ marginTop: 5 }}>
                                        <a class="nav-link" href="/subscribe">Subscriptions</a>
                                    </li>
                                    {logstatus === "" ?
                                        (
                                            <React.Fragment>
                                                <li class="nav-item" style={{ marginTop: 5 }}>
                                                    <a class="nav-link" href="/trial-quiz">Free Trial</a>
                                                </li>
                                                <li class="nav-item" style={{ marginTop: 5 }}>
                                                    <a class="nav-link login" href="/login">Login</a>
                                                </li>
                                                <li class="nav-item" style={{ marginTop: 5 }}>
                                                    <a class="nav-link register" href="/register">Register</a>
                                                </li>
                                            </React.Fragment>

                                        )

                                        :

                                        (<li class="nav-item">
                                            {localStorage.getItem('user') === "user" ? <a class="nav-link login" href="/dashboard">My Dashboard</a>
                                                : <a class="nav-link login" href="/questioner_dashboard">My Dashboard</a>
                                            }
                                        </li>)
                                    }


                                </ul>
                                {/* <div className="d-flex" role="search">
                                    <div className="input-group">
                                        <span class="input-group-text"><i class="bi bi-search"></i></span>
                                        <input class="form-control me-2" type="search" placeholder="Search"
                                            aria-label="Search" value={search} onChange={(search)=>{setSearch(search.target.value)}}/>
                                        <button type='button' className="nav-link login" style={{borderTopLeftRadius:30,borderBottomLeftRadius:30,marginRight:0,}} onClick={()=>{handleClick()}}>Find</button>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </React.Fragment>
    )
}

export default Header;