import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isEmail, isName, isMob, isPasss } from "./validators/Validations";
import LoadingSpinner from "./UIComponents/LoadingSpinner";
import MetaTags from 'react-meta-tags';

function Login() {
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [passError, setPassError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [remember, setRemember] = useState(true);
    const [isForgot, setForgot] = useState(false);

    function checkRegister() {
        const regi = localStorage.getItem('register')
        if (regi) {
            toast.success("Registered Successfull please activate account from mail");
            localStorage.removeItem('register');
        }
    }

    function handleLogin() {
        if (!isEmail(email)) {
            setEmailError(true)
            setEmail("");
            setPass("");
            toast.error("Invalid Details");
        }
        else {
            setEmailError(false);
        }
        if (!pass) {
            setPassError(true);
            //setEmail("");
            setPass("");
            toast.error("Invalid Details");
        }
        else {
            const logData = {
                "email": email,
                "password": pass,
            }
            setLoading(true);
            axios.post('https://api.entmcq.com/api/login', logData)
                .then((resp) => {
                    console.log(resp.data);
                    const data = resp.data;
                    if (data.status == "success") {
                        if (data.data.usertype == "Register") {
                            localStorage.setItem('login', "success");
                            localStorage.setItem('status', true);
                            localStorage.setItem('user', "user");
                            localStorage.setItem('name', data.data.name)
                            localStorage.setItem('info', data.data.token);
                            var rdt = new Date(data.data.rdate);
                            let date = rdt.getDate();
                            let month = rdt.getMonth() + 1;
                            let year = rdt.getFullYear();

                            let separator = "-";
                            let dt = `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`;
                            localStorage.setItem('rdate', dt);
                            window.location.href = "/dashboard";
                        }
                        else if (data.data.usertype == "questioner_register") {
                            /* localStorage.setItem('login', "success");
                            localStorage.setItem('status', true);
                            localStorage.setItem('info', data.data.token);
                            window.location.href = "/questioner_dashboard"; */
                            toast.error("Invalid login details")
                        }
                    }
                    else {

                        //alert(data.msg);
                        toast.error(data.message);
                    }
                    setLoading(false);
                })
            //console.log(logData);
        }

    }

    function handleForgot() {
        setForgot(true)
    }

    function handleSubmit() {
        if (!isEmail(email)) {
            setEmailError(true)
            setEmail("");
            setPass("");
            toast.error("Invalid Details");
        }
        else {
            const pData = {
                email: email
            }

            axios.post("https://api.entmcq.com/api/forgot-password", pData)
                .then((res) => {
                    const data = res.data;
                    if (data.status == "success") {
                        toast.success('Password sent to registered mail id');
                        setForgot(false)
                    }
                    else {
                        toast.error(data.msg);
                    }
                })
        }
    }

    useEffect(() => {
        checkRegister();
    }, [])

    return (
        <React.Fragment>
            <MetaTags>
                <title>Welcome to FRCS (ORL-HNS) MCQ Preparation Platform</title>
                <meta name="description" content="Access comprehensive FRCS (ORL-HNS) MCQ preparation tools and resources at entmcq.com. Achieve excellence in Otorhinolaryngology and Head & Neck Surgery. Join us now!" />
                <meta property="og:title" content="Unlock Your FRCS (ORL-HNS) Success with entmcq.com" />
                <meta property="keywords" content="uk ent,
define ent,
ent website,
ear nose and throat diagnosis,
ent means,
ear nose and throat uk,
free Frcs practice questions,
ent question bank,frcs ent section one exam, frcs mcq exam, frcs sba exam" />
                <link rel="canonical" href={window.location.href}/>
            </MetaTags>
            {isLoading ? <LoadingSpinner /> : (<React.Fragment>

                <ToastContainer />
                <div class="container-fluid">
                    <div class="row">
                        <Header />
                    </div>
                </div>
                {/* <div class="container my-5">
        <div class="row align-items-center">
            
            <div class="col-sm-9" style={{marginLeft: 'auto',marginRight:'auto'}}>
                <div class="card border-0 shadow">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-6 p-5 d-none d-sm-none d-lg-block">
                                <img src="assets/images/login.svg" class="img-fluid"/>
                            </div>
                            <div class="col-sm-6 p-5">
                                <h3 class="fw-bold mb-4">Sign In</h3>
                                <div class="form-container">
                                    <div class="mb-3">
                                        <label class="form-label">User Name <span class="text-danger">*</span></label>
                                        <input type="text" placeholder="UserName" class="form-control" value={email} onChange={em=>setEmail(em.target.value)}/>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Password <span class="text-danger">*</span></label>
                                        <div class="input-group">
                                            <input placeholder="Password" class="form-control" type="password" value={pass} onChange={ps=>setPass(ps.target.value)} />
                                            <button type="button" class="btn btn-light bg-transparent">
                                                <i class="bi bi-eye"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value=""
                                                    id="flexCheckDefault"/>
                                                <label class="form-check-label" for="flexCheckDefault">
                                                    Remeber Me
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 text-end">
                                            <a href="#" class="text-main text-decoration-none">Forget Password</a>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <button type="submit" class="btn btn-main px-4 py-2" onClick={handleLogin}> Log In </button>
                                    </div>
                                    <div class="text-center small">
                                        New User? <a href="register.html" class="text-main text-decoration-none">Register Here</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
                <div class="container my-1 my-lg-5">
                    <div class="row align-items-center">

                        <div class="col-sm-9" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <div class="card border-0 shadow">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-6 py-3  px-5  d-none d-sm-none d-lg-block">
                                            <img src="/assets/images/login.svg" class="img-fluid" />
                                        </div>
                                        {!isForgot ? <div class="col-sm-6 p-5">
                                            <h3 class="fw-bold mb-4">Sign In</h3>
                                            <div class="form-container">
                                                <div class="mb-3">
                                                    <label class="form-label">User Name <span class="text-danger">* {emailError ? ("Invalid Email") : ("")}</span></label>
                                                    <input type="text" placeholder="UserName" class="form-control" value={email} onChange={em => setEmail(em.target.value)} />
                                                </div>
                                                <div class="mb-3">
                                                    <label class="form-label">Password <span class="text-danger">* {passError ? ("Invalid Password") : ("")}</span></label>
                                                    <div class="input-group">
                                                        <input placeholder="Password" class="form-control" type={showPass ? "text" : "password"} value={pass} onChange={ps => setPass(ps.target.value)} />
                                                        <button type="button" class="btn btn-light bg-transparent" onClick={() => {
                                                            setShowPass(!showPass)
                                                        }}>
                                                            {showPass ? <i class="bi bi-eye"></i> : <i class="bi bi-eye-slash"></i>}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col-sm-6">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value=""
                                                                id="flexCheckDefault" checked={remember} onChange={() => {
                                                                    setRemember(!remember);
                                                                }} />
                                                            <label class="form-check-label" for="flexCheckDefault">
                                                                Remeber Me
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 text-end">
                                                        <button class="btn text-main text-decoration-none" onClick={() => { handleForgot() }}>Forgot Password</button>
                                                    </div>
                                                </div>
                                                <div class="mb-3">
                                                    <button type="submit" class="btn btn-main px-4 py-2" onClick={handleLogin}> Log In </button>
                                                </div>
                                                <div class="text-center small">
                                                    New User? <a href="/register" class="text-main text-decoration-none">Register Here</a>
                                                </div>


                                                <div class="text-center small mt-5">

                                                    Question Author <button className="btn login text-white" type="button" style={{ backgroundColor: '#0d5c63' }}
                                                        onClick={() => {
                                                            window.location.href = "/question-auther-login";
                                                        }}
                                                    >Login Here</button>
                                                </div>

                                                <div className="row align-items-center justify-content-between">

                                                </div>

                                            </div>
                                        </div>
                                            :
                                            <div class="col-sm-6 p-5">
                                                <h3 class="fw-bold mb-4">Forgot Password</h3>
                                                <div class="form-container">
                                                    <div class="mb-3">
                                                        <label class="form-label">User Name <span class="text-danger">* {emailError ? ("Invalid Email") : ("")}</span></label>
                                                        <input type="text" placeholder="UserName" class="form-control" value={email} onChange={em => setEmail(em.target.value)} />
                                                    </div>
                                                    <div class="mb-3">
                                                        <button type="submit" class="btn btn-main px-4 py-2" onClick={handleSubmit}> Submit </button>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />

            </React.Fragment>)

            }
        </React.Fragment>
    )
}

export default Login;