import React, { useState, useEffect, useRef } from 'react';

import Countdown, { zeroPad, calcTimeDelta, formatTimeDelta } from 'react-countdown';
import axios from 'axios';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import Header from '../Header';
import Footer from '../Footer';
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement, Title,);

function DemoReview() {
    function AddLibrary(urlOfTheLibrary) {
        const script = document.createElement('script');
        script.src = urlOfTheLibrary;
        script.async = true;
        document.body.appendChild(script);
    }
    //const qid = 1;
    let i = 0;
    const [qdata, setQdata] = useState(() => {
        return JSON.parse(localStorage.getItem('qdata'))
    });
    const [bank, setBank] = useState(() => {
        return JSON.parse(localStorage.getItem('bank'))
    })
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [question, setQuestion] = useState(bank[currentQuestion].question);
    const [answer, setAnswer] = useState(bank[currentQuestion].answers);
    const [cans, setCAns] = useState(0);
    const [wans, setWAns] = useState(0);
    const [showExplain, setExplain] = useState(false);
    const [cates, setCates] = useState(JSON.parse(sessionStorage.getItem('cates')))
    const [selCat, setCat] = useState(cates[0].id);

    const [btnname, setBtnname] = useState('Next');
    const countdownRef = useRef();
    const [isPause, setIsPause] = useState(false);
    const [ans, setAns] = useState(0);
    const [unsen, setUnsen] = useState(0);
    const [wrong, setWrong] = useState(0);
    const [lbls, setLbls] = useState(['A', 'B', 'C', 'D', 'E']);
    const [ansd, setAnsd] = useState([]);
    const [unsend, setUnsend] = useState([]);
    const [wrongd, setWrongd] = useState([]);

    const [gdata, setGData] = useState();
    const [showPer, setPer] = useState(false);
    const ref = useRef(null);

    const bar_data = {
        labels: lbls,

        ansData: {
            label: "ans",
            dataSet: ansd
        },
        wrongData: {
            label: "wrong",
            dataSet: wrongd
        },
        unsenData: {
            label: "unsen",
            dataSet: unsend
        }
    };
    var charid = 64;
    let cnt = 0;
    const [category, setCategory] = useState([])
    function fetchCategory() {
        axios.get('https://api.entmcq.com/api/fetchCategory')
            .then((res) => {
                const data = res.data;
                setCategory(data);
                // console.log(data);
            })
    }
    const data = {
        labels: ['Answered', 'Unanswered', 'Incorrect'],
        datasets: [
            {
                label: 'Performance',
                data: [ans, unsen, wrong],
                backgroundColor: [
                    'rgba(75, 192, 192, 0.2)',

                    'rgba(255, 206, 86, 0.2)',
                    'rgba(255, 99, 132, 0.2)',
                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)',

                    'rgba(255, 206, 86, 1)',
                    'rgba(255, 99, 132, 1)',

                ],
                borderWidth: 1,
            },
        ],
    };

    function handlepause(isPau) {
        //setQTimer(qtimer)
        //calcTimeDelta.onPause
        if (isPau) {
            countdownRef.current.start();
        } else {
            countdownRef.current.pause();
        }
        // setIsPause(!isPause)
        //console.log('clicked');
        // countdownRef.current.pause();
        //window.location.href = '/dashboard/my-quiz'

    }
    const handlePos = (e, pos) => {

        pos = e.target.value - 1;
        if (bank.length > pos) {
            var cnt = pos;
            setCurrentQuestion(pos)
            setQuestion(bank[cnt].question);
            setAnswer(bank[cnt].answers);

        }
    }
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state

        } else {
            // setQTimer(qtimer-1);
            // Render a countdown
            return (
                <span>
                    {hours}:{minutes}:{seconds}
                </span>
            );
        }
    };

    function handleComplete() {
        window.location.href = '/subscribe';
    }
    const CompleteQuiz = () => {
        // return (<span>You are good to go!</span>)
        setShow(true)
    }

    const [show, setShow] = useState(true);

    const handleClose = () => {
        setShow(false)
        window.location.href = '/dashboard/my-quiz'
    };
    const handleYes = () => {
        setShow(false)
        handlepause(true)
    };

    function handleObj(tlinedata) {
        return false;
        setGData({
            labels: ['Answered (' + tlinedata.ans + ')', 'Incorrect (' + tlinedata.wrng + ')', 'Unanswered (0)'],
            datasets: [
                {
                    label: 'Performance',
                    data: [tlinedata.ans, tlinedata.wrng, 0],
                    backgroundColor: [
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(13, 110, 253, 0.2)',
                    ],
                    borderColor: [
                        'rgba(75, 192, 192, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(13, 110, 253, 1)',


                    ],
                    borderWidth: 1,
                },
            ],
        })
    }
    useEffect(() => {
        var tans = 0
        var twrong = 0
        var tunsen = 0;
        var tlinedata = [];
        // console.log(bank);
        bank.map((obj) => {

            if (obj.question.answer_status == "correct") {
                tans++;

            }
            else {
                twrong++;
            }

        })
        tlinedata.push(); //{ 'id': 0, 'name': 'All', 'ans': tans, 'wrng': twrong, }
        var bar_ans = [];
        var bar_wr = [];
        var bar_un = [];
        var tdata = []
        var t=65
        cates.map((obj) => {

            var cnt = bank.filter((b) => b.question.sub_id == obj.id && b.question.answer_status == "correct")
            var cnt1 = bank.filter((b) => b.question.sub_id == obj.id && b.question.answer_status != "correct")
            // tdata.push('A')
            bar_ans.push(cnt.length);
            bar_wr.push(cnt1.length);
            bar_un.push(0)
            tlinedata.push({ "id": obj.id, 'name': "("+String.fromCharCode(t)+") "+ obj.name, "ans": cnt.length, "wrng": cnt1.length });
            t++;
            /*tdata.push({
                label: obj.name,
                data: [cnt.length, cnt1.length, 0],
                backgroundColor: [
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(130, 110, 253, 0.2)',
                    'rgba(130, 99, 132, 1)',
                    'rgba(255, 110, 253, 1)',
                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    'rgba(255, 99, 132, 1)',
                    'rgba(130, 110, 253, 1)',
                    'rgba(130, 99, 132, 1)',
                    'rgba(255, 110, 253, 1)',


                ],
                borderWidth: 1,
            }) */
        })

        // setLbls(tdata);
        setAnsd(bar_ans);
        setWrongd(bar_wr);
        setUnsend(bar_un);

        // console.log(tlinedata[0]);
        setCategory(tlinedata);
        setGData({
            labels: ['Answered (' + tans + ')', 'Incorrect (' + twrong + ')', 'Unanswered (0)'],
            datasets: tdata, /* [
                {
                    label: 'Performance',
                    data: [tans, wans, 0],
                    backgroundColor: [
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(13, 110, 253, 0.2)',
                    ],
                    borderColor: [
                        'rgba(75, 192, 192, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(13, 110, 253, 1)',


                    ],
                    borderWidth: 1,
                },
            ], */
        })

        // console.log('cnt',tlinedata);



        var p = (parseFloat(tans) / parseFloat(parseFloat(bank.length))) * 100;
        var uper = (parseFloat(tunsen) / parseFloat(parseFloat(bank.length))) * 100;
        var wp = (parseFloat(twrong) / parseFloat(parseFloat(bank.length))) * 100;

        setCAns(tans)
        setWAns(twrong)
        setAns(p.toFixed(2));
        setUnsen(uper.toFixed(2));
        setWrong(wp.toFixed(2));
        // fetchCategory();
        /* setGData({
            labels: ['Answered', 'Incorrect', 'Unanswered'],
            datasets: [
                {
                    label: 'Performance',
                    data: [tans, twrong, 0],
                    backgroundColor: [
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(13, 110, 253, 0.2)',
                    ],
                    borderColor: [
                        'rgba(75, 192, 192, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(13, 110, 253, 1)',


                    ],
                    borderWidth: 1,
                },
            ],
        }) */
    }, [])
    return (
        <React.Fragment>
            {/* <Dashboard nm="Dashboard / Quiz Review" /> */}
            <Header />
            {/* <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title ><p>{qdata.quiz_name}</p></Modal.Title>
                </Modal.Header>
                <Modal.Body><p>Are You sure want to start quiz</p></Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleYes}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal> */}
            <main>
                <div class="card plain-card border-0 rounded-0">
                    <div class="card-header border-bottom pt-0">
                        <div class="d-flex justify-content-between align-items-center">
                            <span>[Trial] Demo Quiz</span>
                            <span class="small">Total Questions: {bank.length}</span>
                            {/* <p class="mb-0 fw-bold" style={{ fontSize: 16 }}>Time - {qtimer > 0 ? <CountdownTimer qtimer={qtimer} countdownRef={countdownRef} CompleteQuiz={CompleteQuiz}></CountdownTimer> : "Untimed"}</p> */}
                            {/* <p class="mb-0 fw-bold" style={{ fontSize: 16 }}>Time - {qtimer > 0 ? <Countdown date={Date.now() + qtimer} ref={countdownRef}><CompleteQuiz /></Countdown> : "Untimed"}</p> */}
                            {/* <button class="btn btn-light text-main btn-sm ms-4" data-bs-toggle="offcanvas"
                        data-bs-target="#QuestionListCanvas" aria-controls="QuestionListCanvas">List of Questions</button> */}
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-3" id="left-buttons">
                                <nav aria-label="Page navigation example " id="que-pages">
                                    <h4 class="d-sm-none">Performance Score</h4>
                                    <ul class="pagination mb-0 d-sm-none mb-3">
                                        <li class="page-item" style={{ width: 230, }}>
                                            <Doughnut data={data} options={{
                                                plugins: {
                                                    legend: {
                                                        position: 'top',
                                                        align: 'start',
                                                    }
                                                }
                                            }} />
                                        </li>
                                        <li class="page-item">
                                            <div class="card card-body bg-primary bg-opacity-10 text-primary border-0 p-2">
                                                <p class="mb-1">{ans} %</p>
                                                <p class="mb-0">Result</p>
                                            </div>
                                            <br />
                                            <div class="card card-body bg-success bg-opacity-10 text-success border-0 p-2">
                                                <p class="mb-1">{cans} </p>
                                                <p class="mb-0">Correct</p>
                                            </div>
                                            <br />
                                            <div class="card card-body bg-danger bg-opacity-10 text-danger border-0 p-2">
                                                <p class="mb-1">{wans} </p>
                                                <p class="mb-0">Incorrect</p>
                                            </div>

                                        </li>
                                        {/* <li class="page-item" >
                                                <table class="table dashboard-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Correct</th>
                                                            <th>Incorrect</th>
                                                            <th>No. of Q's</th>
                                                            
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            category.map((obj) => {
                                                                return (
                                                                    <tr>
                                                                        <td>
                                                                            <button type="button"
                                                                                class="btn bg-main-light text-main btn-sm" onClick={() => { handleObj(obj) }}>{obj.name}</button>

                                                                        </td>
                                                                        <td>0</td>
                                                                        <td>0</td>
                                                                        <td>0</td>
                                                                        
                                                                    </tr>
                                                                )
                                                            })
                                                        }

                                                    </tbody>
                                                </table>
                                            </li> 
                                            <li class="page-item" style={{marginLeft:55,}}>
                                                Speciality: <select>
                                                    {
                                                        cates.map((obj)=>{
                                                            return(
                                                                <option value={obj.id}>{obj.name}</option>
                                                            )
                                                        })
                                                        
                                                    }
                                                </select>
                                                {gdata && <Line data={gdata} />}
                                            </li>*/}
                                    </ul>
                                    <div class="d-sm-none">
                                        <table class="table dashboard-table">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Correct</th>
                                                    <th>Incorrect</th>
                                                    <th>No. of Q's</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    category.map((obj) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <span class="text-main" onClick={() => { handleObj(obj) }}>{obj.name}</span>

                                                                </td>
                                                                <td>{obj.ans}</td>
                                                                <td>{obj.wrng}</td>
                                                                <td>{obj.ans + obj.wrng}</td>

                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="d-sm-none mb-3 h-100">

                                        {/* <table class="table dashboard-table">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Correct</th>
                                                    <th>Incorrect</th>
                                                    <th>No. of Q's</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    category.map((obj) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <span class="text-main" onClick={() => { handleObj(obj) }}>{obj.name}</span>

                                                                </td>
                                                                <td>{obj.ans}</td>
                                                                <td>{obj.wrng}</td>
                                                                <td>{obj.ans + obj.wrng}</td>

                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table> */}

                                        {/* <select value={selCat} onChange={(obj) => {
                                        var tlinedata = category.filter((b) => b.id == obj.target.value)
                                        setCat(obj.id)
                                        // console.log('obj',tlinedata[0]);
                                        // console.log(category);
                                        setGData({
                                            labels: ['Answered ('+tlinedata[0].ans+')', 'Incorrect ('+tlinedata[0].wrng+')', 'Unanswered (0)'],
                                            datasets: [
                                                {
                                                    label: 'Performance',
                                                    data: [tlinedata[0].ans, tlinedata[0].wrng, 0],
                                                    backgroundColor: [
                                                        'rgba(75, 192, 192, 0.2)',
                                                        'rgba(255, 99, 132, 0.2)',
                                                        'rgba(13, 110, 253, 0.2)',
                                                    ],
                                                    borderColor: [
                                                        'rgba(75, 192, 192, 1)',
                                                        'rgba(255, 99, 132, 1)',
                                                        'rgba(13, 110, 253, 1)',


                                                    ],
                                                    borderWidth: 1,
                                                },
                                            ],
                                        })
                                    }}>
                                        {
                                            cates.map((obj) => {
                                                return (
                                                    <option value={obj.id}>{obj.name}</option>
                                                )
                                            })

                                        }
                                    </select> */}
                                        {/* {gdata && <Line data={gdata} options={{
                                            plugins: {
                                                legend: {
                                                    position: 'top',
                                                    align: 'start',
                                                }
                                            }
                                        }} />} */}
                                        <Bar
                                            pointStyle="star"

                                            data={{

                                                labels: bar_data.labels,
                                                responsive: true,
                                                offset: true,
                                                datasets: [

                                                    {
                                                        label: "Correct",
                                                        pointStyle: "rectRounded",
                                                        backgroundColor: "#e8f3ee",
                                                        barThickness: 40,
                                                        categoryPercentage: 1,
                                                        data: bar_data.ansData.dataSet //From API
                                                    },

                                                    {
                                                        label: "Incorrect",
                                                        backgroundColor: "#fbeaec",
                                                        barThickness: 40,
                                                        categoryPercentage: 1,
                                                        pointStyle: "triangle",
                                                        data: bar_data.wrongData.dataSet //From API
                                                    },
                                                    {
                                                        label: "Unanswered",
                                                        backgroundColor: "#e6f0ff",
                                                        barThickness: 40,
                                                        categoryPercentage: 1,
                                                        pointStyle: "triangle",
                                                        data: bar_data.unsenData.dataSet //From API
                                                    }
                                                ]
                                            }}
                                            height={'230px'}

                                            options={{
                                                offsetGridLines: true,
                                                drawTicks: true,
                                                // indexAxis:'y',
                                                layout: {
                                                    padding: {
                                                        top: 30,
                                                        right: 40,
                                                        bottom: 40
                                                    }
                                                },
                                                legend: {
                                                    display: true,
                                                    position: "right",
                                                    align: "start",
                                                    labels: {
                                                        usePointStyle: true,
                                                    }
                                                },
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                scales: {
                                                    x: {
                                                        stacked: true,
                                                    },
                                                    y: {
                                                        stacked: true,
                                                    },
                                                },
                                            }}
                                        />
                                    </div>

                                </nav>

                                <button type='button' class="btn btn-main btn-sm me-4 mb-2 d-sm-none" onClick={() => {
                                    setPer(!showPer)
                                }}>{showPer ? "Close Review" : "Review Quiz"}</button>

                                <button type='button' class="btn btn-main btn-sm me-4 mb-2 d-sm-none" onClick={handleComplete}>Subscribe Now</button>

                                <div class={showPer ? "d-flex align-items-start flex-column h-100 mt-3" : "d-flex align-items-start flex-column h-100 mt-3 d-none d-md-block"}>
                                    <div class="btn-group-vertical mb-auto" role="group" aria-label="Vertical button group">
                                        <div class="row align-items-center w-100 gx-2">
                                            {/* <div class="col-sm-6">
                                        <button type="button" class="btn btn-light w-100" data-bs-toggle="modal"
                                            data-bs-target="#GradeModal"><i
                                                class='bx bxs-graduation me-2'></i>Grade</button>
                                    </div> */}
                                            {/* {isPause ? <div class="col-sm-6">
                                                <button type="button" class="btn btn-light w-100" onClick={() => {

                                                    pros.handlepause(true)
                                                    setPause(false)
                                                }}><i
                                                    class='bx bx-play me-2' ></i>Start</button>
                                            </div> :
                                                <div class="col-sm-6">
                                                    <button type="button" class="btn btn-light w-100" onClick={() => {
                                                        pros.handlepause(false)
                                                        setPause(true)
                                                    }}><i
                                                        class='bx bx-pause me-2' ></i>Pause</button>
                                                </div>}
                                            <div class="col-sm-6">
                                                <button type="button" class="btn btn-light w-100" onClick={() => { handlepause() }}><i
                                                    class='bx bx-stop me-2' ></i>Stop</button>
                                            </div> */}

                                            {
                                                bank.map((obj) => {
                                                    i++
                                                    return (
                                                        <div class="col-lg-3 col-4">

                                                            {obj.question.answer_status == "correct" ?
                                                                (<button type="button" class="btn btn-light bg-success text-white w-100" value={i} onClick={(e) => { handlePos(e, i) }} style={{ fontWeight: 'bold', textAlign: 'center' }}>{i}</button>
                                                                )
                                                                :
                                                                (<button type="button" class="btn btn-light bg-danger text-white w-100" value={i} onClick={(e) => { handlePos(e, i) }} style={{ fontWeight: 'bold', textAlign: 'center' }}>{i}</button>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>


                                        {/* <div class="btn-group" role="group">
                                    <button type="button" class="btn btn-light dropdown-toggle"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class='bx bx-edit-alt me-2'></i>Highlight
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li><a class="dropdown-item" href="#">Highlight</a></li>
                                        <li><a class="dropdown-item" href="#">Remove Highlight</a></li>
                                    </ul>
                                </div>
                                <button type="button" class="btn btn-light"><i
                                        class='bx bx-strikethrough me-2'></i>Strikeout</button>
                                <button type="button" class="btn btn-light" data-bs-toggle="modal"
                                    data-bs-target="#CalculatorModal"><i
                                        class='bx bx-calculator me-2'></i>Calculator</button>
                                <button type="button" class="btn btn-light" data-bs-toggle="modal"
                                    data-bs-target="#LabValuesModal"><i class='bx bx-test-tube me-2'></i>Lab
                                    Values</button>
                                <button type="button" class="btn btn-light" data-bs-toggle="modal"
                                    data-bs-target="#NoteModal"><i class='bx bx-notepad me-2'></i>Note</button>
                                <button type="button" class="btn btn-light">
                                    <div class="form-check mb-0">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                        <label class="form-check-label mb-0" for="flexCheckDefault">
                                            Mark
                                        </label>
                                    </div>
                                </button> */}
                                    </div>

                                    {/* <div class="small w-100 border-top pt-2">
                                <a href="#" class="text-main">Challenge a Friend</a>
                                <p class="mb-0 fw-bold mt-1">QID: 56906</p>
                            </div> */}
                                </div>

                            </div>
                            <div class={showPer ? "col-sm-9 border-start" : "col-sm-9 d-none d-md-block border-start"}>

                                <div class="questions-section" ref={ref}>
                                    <div class="question">
                                        <p style={{ color: '#0d5c63' }}>Question {currentQuestion + 1}: </p> <p style={{ fontSize: 16 }} dangerouslySetInnerHTML={{ __html: question.qname }}></p>
                                    </div>
                                    <div class="answers">
                                        {
                                            answer.map((obj) => {
                                                charid++;
                                                // return (<RadioOpt obj={obj} handleAnsChange={handleAnsChange}/>)
                                                // setCAns(question.user_ans)
                                                return (
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="radio" name={"flexRadioDefault1" + obj.q_id}
                                                            id={"flexRadioDefault1" + obj.id} value={obj.id} checked={obj.id == question.user_ans} disabled={true} />{String.fromCharCode(charid)}.&nbsp;
                                                        {question.answer_option == obj.id ? <label class="form-check-label" for={"flexRadioDefault1" + obj.id} style={{ color: 'green', fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: obj.answer }}>

                                                        </label> :
                                                            <label class="form-check-label" for={"flexRadioDefault1" + obj.id} style={{ color: 'black' }} dangerouslySetInnerHTML={{ __html: obj.answer }}>

                                                            </label>
                                                        }

                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-12">
                                            <button class="btn btn-light btn-sm text-main mb-2 me-4" onClick={() => setExplain(!showExplain)}>Show Explanation</button>
                                            {/*</div>
                                         <button class="btn btn-main btn-sm me-4" data-bs-toggle="modal"
                                    data-bs-target="#CheckAnswerModal">Check Answer</button> */}
                                            {/* <button type='button' class="btn btn-main btn-sm me-4" >Submit</button> 
                                        <div class="col-lg-3 col-6">*/}
                                            {bank[currentQuestion].question.answer_status == 'correct' ? (<span class="text-success fw-bold">Correct</span>)
                                                : (<span class="text-danger fw-bold">Incorrect</span>)
                                            }
                                        </div>
                                        <div class="col-lg-6 col-12">
                                            <p class="mb-0" style={{ fontSize: 16, textTransform: 'capitalize', marginLeft: 5, textAlign: 'right' }}>Difficulty Level: {question.qmode}</p>
                                        </div>
                                    </div>

                                    {/* <p>
                                <span class="fw-bold">Graded Response : </span> Correct
                            </p> */}
                                    <hr class="my-2" />

                                    {showExplain && (
                                        <React.Fragment><div class="row">

                                            <div class="col-sm-12 border-end">
                                                <p style={{ fontSize: 16 }} dangerouslySetInnerHTML={{ __html: question.explanation }}></p>
                                            </div>
                                            <div class="col-sm-6 ms-3 me-3" >
                                                <div class="row">
                                                    <button type='button' class="btn btn-main col-4" onClick={() => {
                                                        var c = currentQuestion - 1;
                                                        if (c >= 0) {
                                                            setCurrentQuestion(c)
                                                            setQuestion(bank[c].question);
                                                            setAnswer(bank[c].answers);
                                                            ref.current?.scrollIntoView({ behavior: 'smooth' });
                                                        }
                                                    }}><i class="bi bi-arrow-left"></i> Previous</button>
                                                    <span class="col-4" style={{ color: '#0d5c63', fontWeight: 'bold', textAlign: 'center' }} >{currentQuestion + 1} of {bank.length}</span>
                                                    <button type='button' class="btn btn-main col-4" style={{ marginLeft: 'auto' }} onClick={() => {
                                                        var c = currentQuestion + 1;
                                                        if (c < bank.length) {
                                                            setCurrentQuestion(c)
                                                            setQuestion(bank[c].question);
                                                            setAnswer(bank[c].answers);
                                                            ref.current?.scrollIntoView({ behavior: 'smooth' });
                                                        }
                                                    }}>Next <i class="bi bi-arrow-right"></i></button>
                                                </div>

                                            </div>
                                        </div>
                                            <hr class="my-2" /></React.Fragment>)}
                                    <button type='button' class="btn btn-main btn-sm me-4 mb-2 d-none d-sm-flex" onClick={handleComplete}>Subscribe Now</button>


                                    {/* <div class="progress my-3" style={{ height: 8 }}>
                        <div class="progress-bar" role="progressbar" aria-label="Example 1px high"
                            style={{ width: {percent} + "%" }} aria-valuenow={percent} aria-valuemin="0" aria-valuemax="100">
                        </div>
                    </div> */}
                                    <nav aria-label="Page navigation example " id="que-pages">
                                        <h4 class="d-none d-sm-flex">Performance Score</h4>
                                        <ul class="pagination mb-0 d-none d-sm-flex flex-wrap mb-3">
                                            <li class="page-item" style={{ width: 230, marginTop: 10, }}>
                                                <Doughnut data={data} options={{
                                                    plugins: {
                                                        legend: {
                                                            position: 'top',
                                                            align: 'start',
                                                        }
                                                    }
                                                }} />
                                            </li>
                                            <li class="page-item" style={{ marginTop: 10, }}>
                                                <div class="card card-body bg-primary bg-opacity-10 text-primary border-0">
                                                    <h5 class="mb-1">{ans} %</h5>
                                                    <p class="mb-0">Result</p>
                                                </div>
                                                <br />
                                                <div class="card card-body bg-success bg-opacity-10 text-success border-0">
                                                    <h5 class="mb-1">{cans} </h5>
                                                    <p class="mb-0">Correct</p>
                                                </div>
                                                <br />
                                                <div class="card card-body bg-danger bg-opacity-10 text-danger border-0">
                                                    <h5 class="mb-1">{wans} </h5>
                                                    <p class="mb-0">Incorrect</p>
                                                </div>

                                            </li>
                                            <li class="page-item" style={{ marginLeft: 10, marginTop: 10, }}>
                                                <table class="table dashboard-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Correct</th>
                                                            <th>Incorrect</th>
                                                            <th>No. of Q's</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            category.map((obj) => {
                                                                return (
                                                                    <tr>
                                                                        <td>
                                                                            <span
                                                                                class="text-main" onClick={() => { handleObj(obj) }}>{obj.name}</span>

                                                                        </td>
                                                                        <td>{obj.ans}</td>
                                                                        <td>{obj.wrng}</td>
                                                                        <td>{obj.ans + obj.wrng}</td>

                                                                    </tr>
                                                                )
                                                            })
                                                        }

                                                    </tbody>
                                                </table>
                                            </li>
                                            <li class="page-item" style={{ marginLeft: 25, paddingTop: 5, }}>
                                                {/* Speciality: <select value={selCat} onChange={(obj) => {
                                                    var tlinedata = category.filter((b) => b.id == obj.target.value)
                                                    setCat(obj.id)
                                                    // console.log('obj',tlinedata[0]);
                                                    // console.log(category);
                                                    
                                                }}>
                                                    {
                                                        cates.map((obj) => {
                                                            return (
                                                                <option value={obj.id}>{obj.name}</option>
                                                            )
                                                        })

                                                    }
                                                </select> */}
                                                {/* {gdata && <Line data={gdata} style={{ marginTop: 5, }} options={{
                                                    plugins: {
                                                        legend: {
                                                            position: 'top',
                                                            align: 'start',
                                                        }
                                                    }
                                                }} />} */}
                                                <Bar
                                                    pointStyle="star"
                                                    data={{
                                                        labels: bar_data.labels,
                                                        responsive: true,
                                                        offset: true,
                                                        datasets: [

                                                            {
                                                                label: "Correct",
                                                                pointStyle: "rectRounded",
                                                                backgroundColor: "#e8f3ee",
                                                                barThickness: 40,
                                                                categoryPercentage: 1,
                                                                data: bar_data.ansData.dataSet //From API
                                                            },

                                                            {
                                                                label: "Incorrect",
                                                                backgroundColor: "#fbeaec",
                                                                barThickness: 40,
                                                                categoryPercentage: 1,
                                                                pointStyle: "triangle",
                                                                data: bar_data.wrongData.dataSet //From API
                                                            },
                                                            {
                                                                label: "Unanswered",
                                                                backgroundColor: "#e6f0ff",
                                                                barThickness: 40,
                                                                categoryPercentage: 1,
                                                                pointStyle: "triangle",
                                                                data: bar_data.unsenData.dataSet //From API
                                                            }
                                                        ]
                                                    }}
                                                    height={220}
                                                    options={{
                                                        offsetGridLines: true,
                                                        drawTicks: true,
                                                        layout: {
                                                            padding: {
                                                                top: 30,
                                                                right: 40,
                                                                bottom: 40
                                                            }
                                                        },
                                                        legend: {
                                                            display: true,
                                                            position: "right",
                                                            align: "start",
                                                            labels: {
                                                                usePointStyle: true
                                                            }
                                                        },
                                                        responsive: true,
                                                        maintainAspectRatio: false,
                                                        scales: {
                                                            x: {
                                                                stacked: true,
                                                            },
                                                            y: {
                                                                stacked: true,
                                                            },
                                                        },
                                                    }}
                                                />
                                            </li>
                                        </ul>
                                    </nav>

                                </div>
                            </div>


                            {/* <div class="modal fade" id="explanation" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                                aria-labelledby="CalculatorModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered modal-lg">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <p class="modal-title mb-0" id="CalculatorModalLabel">Explanation</p>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <p style={{ fontSize: 16 }} dangerouslySetInnerHTML={{ __html: question.explanation }}></p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </main>
            {/* <!-- Modal for Check answer --> */}
            <div class="modal fade" id="CheckAnswerModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                aria-labelledby="CheckAnswerModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <p class="modal-title mb-0" id="CheckAnswerModalLabel">Check Answer</p>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body small">
                            <p class="fw-bold mb-3">Your first response is used for grading in Tutor mode. You can attempt the
                                question again in this quiz, but your graded response will not change.</p>
                            <div class="form-container">
                                <div class="d-flex justify-content-between align-items-center mb-4">
                                    <button class="btn btn-light text-main">Do not check answer</button>
                                    <button class="btn btn-main">Check Answer</button>
                                </div>

                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Do not display this message again
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Modal for calculator --> */}
            <div class="modal fade" id="CalculatorModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                aria-labelledby="CalculatorModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <p class="modal-title mb-0" id="CalculatorModalLabel">Calculator</p>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            Calculator
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Modal for Lab Values --> */}
            <div class="modal fade" id="LabValuesModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                aria-labelledby="LabValuesModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <p class="modal-title mb-0" id="LabValuesModalLabel">LabValues</p>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            LabValues
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Modal for Note --> */}
            <div class="modal fade" id="NoteModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                aria-labelledby="NoteModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <p class="modal-title mb-0 d-block" id="NoteModalLabel">
                                Note
                            </p>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-container">
                                <p class="text-muted small">Your note is automatically saved.</p>
                                <textarea class="form-control mt-3 mb-0" placeholder="Note" rows="20"></textarea>
                            </div>
                        </div>
                        <div class="modal-footer text-start">
                            <button type="button" class="btn btn-light text-main btn-sm" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Modal for Lab Values --> */}
            <div class="modal fade" id="GradeModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                aria-labelledby="GradeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <p class="modal-title mb-0" id="GradeModalLabel">Submit for grading?</p>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body small">
                            <div class="progress mb-3" style={{ height: 7 + "px" }}>
                                <div class="progress-bar" role="progressbar" aria-label="Example 1px high" style={{ width: 25 + '%' }}
                                    aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <strong class="mb-2">You’ve answered 1 out of 10 questions.</strong>
                            <p class="mb-0">You cannot answer or change your response to questions after submitting.</p>
                        </div>
                        <div class="modal-footer">
                            <div class="form-container d-flex justify-content-between align-items-center w-100">
                                <button class="btn btn-light text-main">Cancel</button>
                                <button class="btn btn-main">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="offcanvas offcanvas-end" tabindex="-1" data-bs-backdrop="static" id="QuestionListCanvas"
                aria-labelledby="QuestionListCanvasLabel">
                <div class="offcanvas-header">
                    <p class="offcanvas-title" id="QuestionListCanvasLabel">Question List</p>
                    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <table class="table table-hover dashboard-table">
                        <thead>
                            <tr>
                                <th>Que. No.</th>
                                <th>Question</th>
                                <th>Subject</th>
                                <th>Status</th>
                                <th>Difficulty</th>
                                <th>Mark</th>
                                <th>Note</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>A female patient presents with multiple trichelemmomas and hamartomatous polyps throughout
                                    the gastrointestinal tract. She is also at increased risk of breast and thyroid cancer.</td>
                                <td>Rheumatic and Systemic Disease</td>
                                <td>Answered</td>
                                <td>Moderate</td>
                                <td><i class='bx bx-flag' ></i></td>
                                <td>A female patient presents with multiple trichelemmomas and hamartomatous polyps throughout
                                    the gastrointestinal tract. </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <Footer />

        </React.Fragment >
    )
}
export default DemoReview;