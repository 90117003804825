import React, { useState, useEffect } from "react";
import Dashboard from "./Dashboard";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Settings() {

    //const id = localStorage.getItem('info');
    const [user, setUser] = useState([]);
    const [name, setFname] = useState('');
    const [mob, setMob] = useState('');
    const [em, setEm] = useState('');
    const [jobprofile, setJobprofile] = useState('');
    const [hospitalname, setHospitalName] = useState('');
    const [hospitaladdress, setHospitalAddress] = useState('');
    const [accountholder, setAccountHolder] = useState('');
    const [accountnumber, setAccountNumber] = useState('');
    const [ifsccode, setIfscCode] = useState('');
    const [contactnumber, setContactNumber] = useState('');
    const [bankname, setBankName] = useState('');
    const [termsconditions, setTermsConditions] = useState(false);
    const [termsError, setTermsError] = useState(false);
    const [nameError, setNameError] = useState(false)
    const [mobError, setMobError] = useState(false)
    const [emError, setEmError] = useState(false)
    const [jobError, setJobError] = useState(false)
    const [hospitalnameError, setHospitalnameError] = useState(false)
    const [hospitaladdressError, setHospitaladdressError] = useState(false)
    const [accountholderError, setAccountHolderError] = useState(false)
    const [accountnumberError, setAccountNumberError] = useState(false)
    const [ifscError, setIfscError] = useState(false)
    const [contactnumberError, setContactNumberError] = useState(false)
    const [banknameError, setBankNameError] = useState(false)
    const [passError, setPassError] = useState(false)
    const [datavalue, setDatavalue] = useState([]);
    const [cem, setCem] = useState('');
    const [pass, setPass] = useState('');
    const [cpass, setCpass] = useState('');
    const [prof, setProf] = useState('');
    const [pract, setPract] = useState('');
    const [subdata, setSubData] = useState([])

    function fetchSubs() {
        const id = localStorage.getItem('info');
        axios.get('https://entmcq.vertextechnosys.com/api/fetchSubscription/' + id)
            .then((res) => {
                const data = res.data.sub;
                setSubData(data);
            })
    }

    function fetchUser() {
        const id = localStorage.getItem('info');
        axios.get('https://api.entmcq.com/api/profile',{headers:{"Authorization" : `Bearer ${id}`}})
            .then((res) => {
                const data = res.data[0];
                console.log(data);
                setFname(data.name);
                setEm(data.email);
                setMob(data.contact);
                //setContactNumber(data.contact);
                setJobprofile(data.job_profile);
                var hdetails = JSON.parse(data.hospital_details);
                setHospitalName(hdetails[0].hospitalname);
                setHospitalAddress(hdetails[0].hospitaladdress)
                var bdetails = JSON.parse(data.bank_details);
                setBankName(bdetails[0].bankname);
                setAccountHolder(bdetails[0].accountholder)
                setContactNumber(bdetails[0].contactnumber);
                setIfscCode(bdetails[0].ifsccode);
                setAccountNumber(bdetails[0].accountnumber);


            })
    }

    function AddLibrary(urlOfTheLibrary) {
        const script = document.createElement('script');
        script.src = urlOfTheLibrary;
        script.async = true;
        document.body.appendChild(script);
    }
    function handleClick(val) {
        console.log(val);
        localStorage.setItem('quiz_id', val);
        window.location.href = '/dashboard/quiz'
    }
    function AddCC(urlOfTheLibrary) {
        const script = document.createElement('link');
        script.href = urlOfTheLibrary;
        script.rel = "stylesheet";
        script.type = "text/css";
        document.head.appendChild(script);
    }

    function handleClick() {
        var uid = localStorage.getItem('info');
        axios.get('https://entmcq.vertextechnosys.com/api/resetquiz/' + uid)
            .then((res) => {
                toast('Reset Successfull');
            })
    }

    useEffect(() => {
        // fetchSubs();
        fetchUser();
    }, []);
    return (
        <React.Fragment>
            <ToastContainer />
            <Dashboard nm="Dashboard | Settings" />
            <main>
                <nav>
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                        <button class="nav-link active" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile"
                            type="button" role="tab" aria-controls="nav-profile" aria-selected="true">
                            <div class="d-flex justify-content-between align-items-center">
                                <i class='bx bx-user-circle'></i><span class="d-none d-lg-block ms-2">profile</span>
                            </div>
                        </button>
                        {/* <button class="nav-link" id="nav-products-tab" data-bs-toggle="tab" data-bs-target="#nav-products"
                            type="button" role="tab" aria-controls="nav-products" aria-selected="false">
                            <div class="d-flex justify-content-between align-items-center">
                                <i class='bx bxs-briefcase-alt-2' ></i><span class="d-none d-lg-block ms-2">my products</span>
                            </div>
                        </button> */}
                        {/* <button class="nav-link" id="nav-reset-tab" data-bs-toggle="tab" data-bs-target="#nav-reset"
                    type="button" role="tab" aria-controls="nav-reset" aria-selected="false">
                    <div class="d-flex justify-content-between align-items-center">
                        <i class='bx bx-lock' ></i><span class="d-none d-lg-block ms-2">reset password</span>
                    </div>
                </button>
                <button class="nav-link" id="nav-access-tab" data-bs-toggle="tab" data-bs-target="#nav-access"
                    type="button" role="tab" aria-controls="nav-access" aria-selected="false">
                    <div class="d-flex justify-content-between align-items-center">
                        <i class='bx bxs-key' ></i><span class="d-none d-lg-block ms-2">access code</span>
                    </div>
                </button> */}
                    </div>
                </nav>
                <div class="form-container">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-profile" role="tabpanel"
                            aria-labelledby="nav-profile-tab" tabindex="0">
                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="card plain-card">
                                        <div class="card-header">Profile</div>
                                        <div class="card-body">
                                            <div class="mb-3">
                                                <div class="form-label">Email ID</div>
                                                <input type="email" class="form-control" placeholder="Email ID" value={em} onChange={(em) => { setEm(em.target.value) }} />
                                            </div>
                                            <div class="mb-3">
                                                <div class="form-label">First Name</div>
                                                <input type="text" class="form-control" placeholder="First Name" value={name} onChange={(fname) => { setFname(fname.target.value) }} />
                                            </div>
                                            {/* <div class="mb-3">
                                        <div class="form-label">Last Name</div>
                                        <input type="text" class="form-control" placeholder="Last Name" value={lname} onChange={(lname)=>{setLname(lname.target.value)}}/>
                                    </div> */}
                                            <div class="mb-3">
                                                <label class="form-label">Mobile Number </label>
                                                <input type="text" class="form-control" placeholder="Mobile Number" value={mob} onChange={(mob) => { setMob(mob.target.value) }} />
                                            </div>
                                            <div class="mb-3">
                                                <label class="form-label">Mobile Number</label>
                                                <input type="text" class="form-control" placeholder="Mobile Number" value={mob} onChange={(mob) => {
                                                    setMob(mob.target.value)
                                                }} />
                                            </div>
                                            <div class="mb-3">
                                                <label class="form-label">Job Profile</label>
                                                <input type="text" class="form-control" placeholder="Job Profile" value={jobprofile} onChange={(jobprofile) => {
                                                    setJobprofile(jobprofile.target.value)
                                                    setJobError(false)
                                                }} />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="card plain-card">
                                        <div class="card-header">Hospital Details</div>
                                        <div class="card-body">

                                            <div class="mb-3">
                                                <label class="form-label">Name<span class="text-danger">* {hospitalnameError ? ("Invalid Name") : ("")}</span></label>
                                                <div class="input-group">
                                                    <input type="text" class="form-control" placeholder="Name" value={hospitalname} onChange={(hospitalname) => {
                                                        setHospitalName(hospitalname.target.value)
                                                        setHospitalnameError(false)
                                                    }} />
                                                    {/* <button class="btn"><i class="bi bi-eye"></i></button> */}
                                                </div>
                                            </div>
                                            <div class="mb-3">
                                                <label class="form-label">Address <span
                                                    class="text-danger">* {hospitaladdressError ? ("Invalid Address") : ("")}</span></label>
                                                <div class="input-group">
                                                    <textarea type="" class="form-control"
                                                        placeholder="Address" value={hospitaladdress}
                                                        onChange={(hospitaladdress) => {
                                                            setHospitalAddress(hospitaladdress.target.value)
                                                            setHospitaladdressError(false)
                                                        }} />

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="card plain-card">
                                        <div class="card-header">Bank Details</div>
                                        <div class="card-body">


                                            <div class="mb-3">
                                                <label class="form-label">Account Holder Name<span
                                                    class="text-danger">* {accountholderError ? ("Invalid Account Holder Name") : ("")}</span></label>
                                                <div class="input-group">
                                                    <input type="text" class="form-control"
                                                        placeholder="Account Holder Name" value={accountholder} onChange={(accountholder) => {
                                                            setAccountHolder(accountholder.target.value)
                                                            setAccountHolderError(false)
                                                        }} />
                                                    {/* <button class="btn"><i class="bi bi-eye"></i></button> */}
                                                </div>
                                            </div>
                                            <div class="mb-3">
                                                <label class="form-label">Account Number<span class="text-danger">* {accountnumberError ? ("Invalid Account Number") : ("")}</span></label>
                                                <div class="input-group">
                                                    <input type="text" class="form-control" placeholder="Account Number" value={accountnumber} onChange={(accountnumber) => {
                                                        setAccountNumber(accountnumber.target.value)
                                                        setAccountNumberError(false)
                                                    }} />
                                                    {/* <button class="btn"><i class="bi bi-eye"></i></button> */}
                                                </div>
                                            </div>
                                            <div class="mb-3">
                                                <label class="form-label">IFSC Code<span class="text-danger">* {ifscError ? ("Invalid IFSC") : ("")}</span></label>
                                                <div class="input-group">
                                                    <input type="text" class="form-control" placeholder="IFSC Code" value={ifsccode} onChange={(ifsccode) => {
                                                        setIfscCode(ifsccode.target.value)
                                                        setIfscError(false)
                                                    }} />
                                                    {/* <button class="btn"><i class="bi bi-eye"></i></button> */}
                                                </div>
                                            </div>
                                            <div class="mb-3">
                                                <label class="form-label">Mobile Number<span
                                                    class="text-danger">* {contactnumberError ? ("Invalid Contact Number") : ("")}</span></label>
                                                <div class="input-group">
                                                    <input type="text" class="form-control"
                                                        placeholder="Mobile Number" value={contactnumber} onChange={(contactnumber) => {
                                                            setContactNumber(contactnumber.target.value)
                                                            setContactNumberError(false)
                                                        }} />
                                                    {/* <button class="btn"><i class="bi bi-eye"></i></button> */}
                                                </div>
                                            </div>
                                            <div class="mb-3">
                                                <label class="form-label">Bank Name<span class="text-danger">* {banknameError ? ("Invalid Bank Name") : ("")}</span></label>
                                                <div class="input-group">
                                                    <input type="text" class="form-control" placeholder="Bank Name" value={bankname} onChange={(bankname) => {
                                                        setBankName(bankname.target.value)
                                                        setBankNameError(false)
                                                    }} />

                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-products" role="tabpanel" aria-labelledby="nav-products-tab"
                            tabindex="0">
                            <div class="row" id="course-list">
                                <div class="col-sm-6">
                                    <div class="card plain-card">
                                        <div class="card-header">My Products</div>
                                        {/* <div class="card-header bg-success bg-opacity-10 text-success fw-normal">Active</div> */}
                                        <div class="card-body">
                                            <div class="list-group">

                                                {
                                                    subdata.map((obj) => {
                                                        return (
                                                            <div class="list-group-item d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <p class="fw-bold mb-0">{obj.sub_code}</p>
                                                                    <p class="mb-0">Expires {obj.sub_edate}</p>
                                                                </div>
                                                                <button type="button" class="btn btn-main btn-sm" onClick={handleClick}>Reset</button>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {/* <div class="list-group-item d-flex justify-content-between align-items-center">
                                            <div>
                                                <p class="fw-bold mb-0">Dermatology</p>
                                                <p class="mb-0">Expires Sep 5, 2022</p>
                                            </div>
                                            <button type="button" class="btn btn-main btn-sm">Reset</button>
                                        </div>
                                        <div class="list-group-item d-flex justify-content-between align-items-center">
                                            <div>
                                                <p class="fw-bold mb-0">Dermatology</p>
                                                <p class="mb-0">Expires Sep 5, 2022</p>
                                            </div>
                                            <button type="button" class="btn btn-main btn-sm">Reset</button>
                                        </div> */}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-reset" role="tabpanel" aria-labelledby="nav-reset-tab"
                            tabindex="0">
                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="card plain-card">
                                        <div class="card-header">
                                            Reset Password
                                            <p class="small text-muted fw-normal mb-0">All Fields are <i>compulsory.</i></p>
                                        </div>
                                        <div class="card-body">
                                            <div class="mb-3">
                                                <label class="form-label">Current Password</label>
                                                <div class="input-group">
                                                    <input type="password" class="form-control" placeholder="Current Password" />
                                                    <button type="button" class="btn btn-light"><i class='bx bxs-show'></i></button>
                                                </div>
                                            </div>
                                            <div class="mb-3">
                                                <label class="form-label">New Password</label>
                                                <div class="input-group">
                                                    <input type="password" class="form-control" placeholder="New Password" />
                                                    <button type="button" class="btn btn-light"><i class='bx bxs-show'></i></button>
                                                </div>
                                            </div>
                                            <div class="mb-3">
                                                <label class="form-label">Retype Password</label>
                                                <div class="input-group">
                                                    <input type="password" class="form-control" placeholder="Retype Password" />
                                                    <button type="button" class="btn btn-light"><i class='bx bxs-show'></i></button>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <button type="button" class="btn btn-light text-main">Cancel</button>
                                                <button type="button" class="btn btn-main">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-access" role="tabpanel" aria-labelledby="nav-access-tab"
                            tabindex="0">
                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="card plain-card">
                                        <div class="card-header">Redeem a Product Access Code
                                            <p class="mb-0 mt-1 fw-normal small text-muted">If you have purchased a BoardVitals plan that has yet to be activated, please input your access code below to begin your subscription</p>
                                        </div>
                                        <div class="card-body">
                                            <div class="mb-3">
                                                <label class="form-label">Enter Access Code</label>
                                                <input type="text" class="form-control" placeholder="Enter Access Code" />
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <button type="button" class="btn btn-light text-main">Cancel</button>
                                                <button type="button" class="btn btn-main">Get Access</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {AddLibrary('/assets/js/main.js')}
        </React.Fragment>
    )
}

export default Settings;