import logo from './logo.svg';

import Home from './components/Home';

import About from './components/About';
import Login from './components/Login';
import Register from './components/Register';
// import Dashboard from './components/Dashboard/Dashboard';
import UHome from './components/UserDashboard/Home';
// import PerformanceTimeline from './components/Dashboard/PerformanceTimeline';
import PerformanceTimeline from './components/UserDashboard/PerformanceTimeline';
import PerformanceSubject from './components/UserDashboard/PerformanceSubject';
// import Performance from './components/Dashboard/Performance';
import Quizzes from './components/Dashboard/Quizzes';
// import Settings from './components/Dashboard/Setting';
import Logout from './components/Dashboard/Logout';
import Category from './components/Category';
import Qb from './components/Qb';
import Subscribe from './components/Subscribe';
import CustomQuiz from './components/UserDashboard/CustomQuiz';
import UserQuiz from './components/UserDashboard/UserQuiz';
import QuizPage from './components/UserDashboard/QuizPage';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MyQuizzes from './components/UserDashboard/MyQuizzes';
import QuickQuiz from './components/UserDashboard/QuickQuiz';
import Categories from './components/categories';
import Student from './components/Student';
import TimerTest from './components/UserDashboard/TimerTest';
import Settings from './components/UserDashboard/Setting';
import MockQuiz from './components/UserDashboard/MockQuiz';
import NoSub from './components/UserDashboard/NoSub';
import QuestionerRegister from './components/QuestionerRegister';

import AddQuestion from './components/QuestionerDashboard/AddQuestion';
import ViewQuestion from './components/QuestionerDashboard/ViewQuestion';
import QuestionEdit from './components/QuestionerDashboard/QuestionEdit';
import QuestionerDashboard from './components/QuestionerDashboard/QuestionerDashboard';
import QSettings from './components/QuestionerDashboard/Setting';
import AccountActivate from './components/AccountActivate';
import QuestionerActivate from './components/QuestionerActivate';
import ReviewPage from './components/UserDashboard/ReviewPage';
import QuestionActivate from './components/QuestionActivate';
import QuestionInactive from './components/QuestionInactive';
import ViewQuestionStatus from './components/QuestionerDashboard/ViewQuestionStatus';
import Prepare from './components/Prepare';
import Termsconditions from './components/Termsconditions';
import Privacypolicy from './components/Privacypolicy';
import Dataprotection from './components/Dataprotection';
import DemoQuiz from './components/Demo/DemoQuiz';
import DemoReview from './components/Demo/DemoReview';
import Contact from './components/Contact';
import Pricing from './components/Pricing';
import Packages from './components/Packages';
import PaymentPage from './components/PaymentPage';
import ThankYou from './components/ThankYou';
import { useEffect } from 'react';
import QuestionerLogin from './components/QuestionerLogin';
import UserLightBox from './components/UserLightBox';
import AutherLaunch from './components/AutherLaunch';
import Error from './components/Error';


function App() {
  useEffect(() => {

    return (() => {
      console.log('closing application please wait');
      localStorage.setItem('close', 'true');
    })
  }, [])
  return (
    <BrowserRouter>
      <Routes>
        <Route path='*' element={<Error />} />
        <Route path='/' element={<Home />} />
        <Route path='about-us' element={<About />} />
        <Route path='medical-student' element={<Student />} />
        <Route path='specialist' element={<Category />} />
        <Route path='specialists/:txt' element={<Categories />} />
        <Route path='specialist/:id' element={<Qb />} />
        <Route path='subscribe' element={<Subscribe />} />
        <Route path='login' element={<Login />} />
        <Route path='register' element={<Register />} />
        <Route path='contact-us' element={<Contact />} />
        <Route path='dashboard' element={<UHome />} />
        <Route path='dashboard/performance-timeline' element={<PerformanceTimeline />} />
        <Route path='dashboard/performance' element={<PerformanceSubject />} />
        <Route path='dashboard/quizzes' element={<Quizzes />} />
        <Route path='dashboard/settings' element={<Settings />} />
        <Route path='dashboard/custom-quiz' element={<UserQuiz />} />
        <Route path='dashboard/mock-quiz' element={<MockQuiz />} />
        <Route path='dashboard/quiz' element={<QuizPage />} />
        <Route path='logout' element={<Logout />} />
        <Route path='/dashboard/my-quiz' element={<MyQuizzes />} />
        <Route path='/dashboard/quick-quiz' element={<QuickQuiz />} />
        <Route path='/timer' element={<TimerTest />} />
        <Route path='/no-subscription' element={<NoSub />} />
        <Route path='/questioner-register' element={<QuestionerRegister />} />
        <Route path="questioner_dashboard" element={<QuestionerDashboard />} />
        <Route path='add_questions' element={<AddQuestion />} />
        <Route path='view_questions' element={<ViewQuestion />} />
        <Route path='questions/:status' element={<ViewQuestionStatus />} />
        <Route path='edit_question/:qid' element={<QuestionEdit />} />
        <Route path='questioner/profile' element={<QSettings />} />
        <Route path='activate/:id' element={<AccountActivate />} />
        <Route path='questioner_activated/:id' element={<QuestionerActivate />} />
        <Route path='dashboard/review' element={<ReviewPage />} />
        <Route path='question_activated/:id' element={<QuestionActivate />} />
        <Route path='question_inctive/:id' element={<QuestionInactive />} />
        <Route path='preparing-exam' element={<Prepare />} />
        <Route path='terms-conditions' element={<Termsconditions />} />
        <Route path='data-protection-policy' element={<Dataprotection />} />
        <Route path='privacy-policy' element={<Privacypolicy />} />
        <Route path='trial-quiz' element={<DemoQuiz />} />
        <Route path='trial-review' element={<DemoReview />} />
        <Route path='pricing' element={<Packages />} />
        <Route path='payment' element={<PaymentPage />} />
        <Route path='thank-you' element={<ThankYou />} />
        <Route path='question-auther-login' element={<QuestionerLogin />} />
        <Route path='launch' element={<UserLightBox/>} />
        <Route path='author-launch' element={<AutherLaunch/>} />

      </Routes>


    </BrowserRouter>
  )
}

export default App;
