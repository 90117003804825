import React from "react";
import { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
function ThankYou() {
    const scods = JSON.parse(localStorage.getItem('scodedata'))
    const paypal = JSON.parse(localStorage.getItem('response'))
    let expdt = new Date(new Date().getTime() + scods.days * 24 * 60 * 60 * 1000)
    const date = expdt.getDate();
    const month = expdt.getMonth() + 1;
    const year = expdt.getFullYear();
    let separator = "/";

    const exp = `${date < 10 ? `0${date}` : date}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${year}`;
    useEffect(() => {
        console.log(paypal.purchase_units[0].amount.value);
    })
    return (
        <React.Fragment>
            <div class="container-fluid">
                <div class="row">
                    <Header />

                </div>
            </div>

            <div class="container-fluid" id="aboutus">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-sm-12 d-flex justify-content-between">
                                    <h1>Thank you</h1>
                                    {/* <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb mb-0" id="breadcrumb">
                                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                                            <li class="breadcrumb-item active" aria-current="page">Thank you for payment</li>
                                        </ol>
                                    </nav> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container pb-5" id="main-content">
                <div class="row">
                    <div class="col-lg-6 mb-5 mb-lg-0 pricingTable">
                        <div class="bg-white p-5 rounded-lg shadow">
                            <h1 class="h6 text-uppercase font-weight-bold mb-2">Order ID : {paypal.id}</h1>
                            <h1 class="h6 text-uppercase font-weight-bold mb-2">Package : {scods.scode}</h1>
                            <h2 class="h6 font-weight-bold">£ {paypal.purchase_units[0].amount.value}<span class="text-small font-weight-normal ml-2"> / {scods.days} days</span></h2>
                            <h2 class="h6 text-uppercase font-weight-bold mb-2">Exp. On : {exp}</h2>
                            <h2 class="h6 font-weight-bold">Payment status : {paypal.status}</h2>
                        </div>
                    </div>

                    <div class="col-sm-12">
                        <p style={{ textAlign: 'justify', fontSize: 15, lineHeight: 2, }}>
                            <br /><br />Thank you for subscribing to ENT MCQ, the ONLY revision website dedicated to the FRCS (OLR-HNS) Section One Examination.<br />

                            We wish you all the best for your learning journey and upcoming examination.<br />

                            If you have any questions or would like to contact us please email us on support@entmcq.com or complete a contact us form on our website.<br />

                            Please note your subscription is only valid for the period of time you paid for, after that you will not have access to the question bank unless you renew your subscription.<br /><br />

                            Good Luck!<br /><br />
                            <button className="btn login text-white" type="button" style={{ backgroundColor: '#0d5c63' }}
                                onClick={() => {
                                    window.location.href = "/login";
                                }}
                            >continue to Login</button><br />
                        </p>

                    </div>

                </div>
            </div>
            <Footer />

        </React.Fragment>
    )
}

export default ThankYou;