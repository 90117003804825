import React, { useState, useEffect } from "react";
import Dashboard from "./Dashboard";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Settings() {

    //const id = localStorage.getItem('info');
    const [user, setUser] = useState([]);
    const [subdata, setSubData] = useState([]);
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [mob, setMob] = useState('');
    const [em, setEm] = useState('');
    const [cem, setCem] = useState('');
    const [pass, setPass] = useState('');
    const [cpass, setCpass] = useState('');
    const [prof, setProf] = useState('');
    const [days,setDays] = useState(() => {
        const st = localStorage.getItem("remaindays");
        return st || 0
    });
    const [pract, setPract] = useState('');
    const [isResetShow, setResetShow] = useState(false);
    const [isRenewShow, setRenewShow] = useState(false);

    function fetchSubs() {
        const id = localStorage.getItem('info');
        axios.get('https://api.entmcq.com/api/fetchSubscription', { headers: { "Authorization": `Bearer ${id}` } })
            .then((res) => {
                const data = res.data.sub;
                setSubData(data);
            })
    }

    function fetchUser() {
        const id = localStorage.getItem('info');
        axios.get('https://api.entmcq.com/api/profile', { headers: { "Authorization": `Bearer ${id}` } })
            .then((res) => {
                const data = res.data[0];
                console.log(data);
                var nms = data.name.split(' ');
                setFname(nms[0]);
                setLname(nms[1]);
                setEm(data.email);
                setMob(data.mob)
                setProf(data.prof);
                setPract(data.area);

            })
    }

    function AddLibrary(urlOfTheLibrary) {
        const script = document.createElement('script');
        script.src = urlOfTheLibrary;
        script.async = true;
        document.body.appendChild(script);
    }
    function handleClick(val) {
        console.log(val);
        localStorage.setItem('quiz_id', val);
        window.location.href = '/dashboard/quiz'
    }
    function AddCC(urlOfTheLibrary) {
        const script = document.createElement('link');
        script.href = urlOfTheLibrary;
        script.rel = "stylesheet";
        script.type = "text/css";
        document.head.appendChild(script);
    }

    function handleClick() {
        /* var uid = localStorage.getItem('info');
        axios.get('https://entmcq.vertextechnosys.com/api/resetquiz/' + uid)
            .then((res) => {
                console.log(res.data);
                toast('Reset Successfull');
            }) */
        setResetShow(true)
    }
    function handleClose() {
        setResetShow(false)
    }

    function handleYes() {
        var uid = localStorage.getItem('info');
        axios.get('https://api.entmcq.com/api/resetquiz', {headers:{"Authorization" : `Bearer ${uid}`}})
            .then((res) => {
                setResetShow(false)
                console.log(res.data);
                toast('Reset Successfull');
            })

    }

    function handleRenew() {
        /* var uid = localStorage.getItem('info');
        axios.get('https://entmcq.vertextechnosys.com/api/resetquiz/' + uid)
            .then((res) => {
                console.log(res.data);
                toast('Reset Successfull');
            }) */
        setRenewShow(true)
    }
    function handleRenewClose() {
        setRenewShow(false)
    }

    function handleRenewYes() {
        setRenewShow(false)
        window.location.href="/subscribe"
        
    }

    useEffect(() => {
        fetchSubs();
        fetchUser();
    }, []);
    return (
        <React.Fragment>
            <ToastContainer />
            <Dashboard nm="Dashboard | Settings" />
            <Modal show={isResetShow} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title ><p>Reset Qustion Bank</p></Modal.Title>
                </Modal.Header>
                <Modal.Body><p style={{ textAlign: "justify" }}>By confirming you wish to reset the question bank, you acknowledge that ALL your data for this subscription will be reset. This includes previous answers, performance data and all questions will be reset to unattempted’</p></Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleYes}>
                        Reset
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={isRenewShow} onHide={handleRenewClose}>
                <Modal.Header closeButton>
                    <Modal.Title ><p>Renew Subscription</p></Modal.Title>
                </Modal.Header>
                <Modal.Body><p style={{ textAlign: "justify" }}>Thank you for choosing to renew your subscription plan with us. We hope you are enjoying the website and app and we continue to wish you all the best for your upcoming examination. Please note the renewal date will be from your subscription end date, not today, so as to maximise the length of your subscription and access to our website and app.
                <br/><br/>Days Remain : {days}
                </p></Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleRenewClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleRenewYes}>
                        Renew
                    </Button>
                </Modal.Footer>
            </Modal>
            <main>
                <nav>
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                        <button class="nav-link active" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile"
                            type="button" role="tab" aria-controls="nav-profile" aria-selected="true">
                            <div class="d-flex justify-content-between align-items-center">
                                <i class='bx bx-user-circle'></i><span class="d-none d-lg-block ms-2">profile</span>
                            </div>
                        </button>
                        <button class="nav-link" id="nav-products-tab" data-bs-toggle="tab" data-bs-target="#nav-products"
                            type="button" role="tab" aria-controls="nav-products" aria-selected="false">
                            <div class="d-flex justify-content-between align-items-center">
                                <i class='bx bxs-briefcase-alt-2' ></i><span class="d-none d-lg-block ms-2">my subscription</span>
                            </div>
                        </button>
                        {/* <button class="nav-link" id="nav-reset-tab" data-bs-toggle="tab" data-bs-target="#nav-reset"
                    type="button" role="tab" aria-controls="nav-reset" aria-selected="false">
                    <div class="d-flex justify-content-between align-items-center">
                        <i class='bx bx-lock' ></i><span class="d-none d-lg-block ms-2">reset password</span>
                    </div>
                </button>
                <button class="nav-link" id="nav-access-tab" data-bs-toggle="tab" data-bs-target="#nav-access"
                    type="button" role="tab" aria-controls="nav-access" aria-selected="false">
                    <div class="d-flex justify-content-between align-items-center">
                        <i class='bx bxs-key' ></i><span class="d-none d-lg-block ms-2">access code</span>
                    </div>
                </button> */}
                    </div>
                </nav>
                <div class="form-container">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-profile" role="tabpanel"
                            aria-labelledby="nav-profile-tab" tabindex="0">
                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="card plain-card">
                                        <div class="card-header">Profile</div>
                                        <div class="card-body">

                                            <div class="mb-3">
                                                <div class="form-label">First Name</div>
                                                <input type="text" class="form-control" placeholder="First Name" value={fname} onChange={(fname) => { setFname(fname.target.value) }} />
                                            </div>
                                            <div class="mb-3">
                                                <div class="form-label">Last Name</div>
                                                <input type="text" class="form-control" placeholder="Last Name" value={lname} onChange={(lname) => { setLname(lname.target.value) }} />
                                            </div>
                                            <div class="mb-3">
                                                <div class="form-label">Email ID</div>
                                                <input type="email" class="form-control" placeholder="Email ID" value={em} onChange={(em) => { setEm(em.target.value) }} />
                                            </div>
                                            <div class="mb-3">
                                                <label class="form-label">Mobile Number </label>
                                                <input type="text" class="form-control" placeholder="Mobile Number" value={mob} onChange={(mob) => { setMob(mob.target.value) }} />
                                            </div>
                                            <div class="mb-3">
                                                <div class="form-label">Region of Current Practice</div>
                                                <select class="form-select" value={prof} onChange={prof => setProf(prof.target.value)}>

                                                    <option value="Region (UK)">Region (UK)</option>
                                                    <option value="International">International</option>
                                                </select>
                                            </div>
                                            <div class="mb-3">
                                                <div class="form-label">Area of Practice</div>
                                                {prof == "Region (UK)" ? (<select class="form-select" value={pract} onChange={pract => setPract(pract.target.value)}>

                                                    <option value="East Midlands">East Midlands</option>
                                                    <option value="East of England">East of England</option>
                                                    <option value="North East">North East</option>
                                                    <option value="North West">North West</option>
                                                    <option value="Scotland">Scotland</option>
                                                    <option value="South West (Peninsula)">South West (Peninsula)</option>
                                                    <option value="South West (Severn)">South West (Severn)</option>
                                                    <option value="London">London</option>
                                                    <option value="Kent, Surrey, Sussex">Kent, Surrey, Sussex</option>
                                                    <option value="Thames Valley">Thames Valley</option>
                                                    <option value="Wessex">Wessex</option>
                                                    <option value="West Midlands">West Midlands</option>
                                                    <option value="Yorkshire and the Humber">Yorkshire and the Humber</option>
                                                    <option value="Other">Other</option>

                                                </select>)
                                                    : (<input type="text" class="form-control" placeholder="Country of Practice" value={pract} onChange={(e) => setPract(e.target.value)} />)
                                                }
                                            </div>
                                            {/* <div class="d-flex justify-content-between">
                                        <button type="button" class="btn btn-light text-main">Cancel</button>
                                        <button type="button" class="btn btn-main">Save</button>
                                    </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-products" role="tabpanel" aria-labelledby="nav-products-tab"
                            tabindex="0">
                            <div class="row" id="course-list">
                                <div class="col-sm-6">
                                    <div class="card plain-card">
                                        <div class="card-header">My subscription</div>
                                        {/* <div class="card-header bg-success bg-opacity-10 text-success fw-normal">Active</div> */}
                                        <div class="card-body">
                                            <div class="list-group">

                                                {
                                                    subdata.map((obj) => {
                                                        let dt = new Date()
                                                        let edt = new Date(obj.sub_edate);
                                                        var time_difference = edt.getTime() - dt.getTime();

                                                        //calculate days difference by dividing total milliseconds in a day  
                                                        var days_difference = parseInt(time_difference / (1000 * 60 * 60 * 24));
                                                        if(days_difference <= 5){
                                                            console.log(days_difference);
                                                        }
                                                        return (
                                                            <div class="list-group-item d-flex justify-content-between align-items-center">

                                                                {dt.getTime() <= edt.getTime() ?
                                                                    <>
                                                                        <div>
                                                                            <p class="fw-bold mb-0">{obj.sub_code}</p>
                                                                            <p class="mb-0">Renew on {edt.getDate()}/{edt.getMonth() + 1}/{edt.getFullYear()}</p>
                                                                        </div>
                                                                        <div>
                                                                            <button type="button" class="btn btn-main btn-sm" onClick={() => { handleClick() }}>Reset Question Bank</button><br /><br />
                                                                            {days <= 5 && <button type="button" class="btn btn-main btn-sm" onClick={() => { handleRenew() }} style={{backgroundColor:'#188ccc'}}>Renew Question Bank</button>}
                                                                        </div>

                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div>
                                                                            <p class="fw-bold mb-0">{obj.sub_code}</p>
                                                                            <p class="mb-0">Expired on {edt.getDate()}/{edt.getMonth() + 1}/{edt.getFullYear()}</p>
                                                                        </div>

                                                                    </>
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {/* <div class="list-group-item d-flex justify-content-between align-items-center">
                                            <div>
                                                <p class="fw-bold mb-0">Dermatology</p>
                                                <p class="mb-0">Expires Sep 5, 2022</p>
                                            </div>
                                            <button type="button" class="btn btn-main btn-sm">Reset</button>
                                        </div>
                                        <div class="list-group-item d-flex justify-content-between align-items-center">
                                            <div>
                                                <p class="fw-bold mb-0">Dermatology</p>
                                                <p class="mb-0">Expires Sep 5, 2022</p>
                                            </div>
                                            <button type="button" class="btn btn-main btn-sm">Reset</button>
                                        </div> */}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-reset" role="tabpanel" aria-labelledby="nav-reset-tab"
                            tabindex="0">
                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="card plain-card">
                                        <div class="card-header">
                                            Reset Password
                                            <p class="small text-muted fw-normal mb-0">All Fields are <i>compulsory.</i></p>
                                        </div>
                                        <div class="card-body">
                                            <div class="mb-3">
                                                <label class="form-label">Current Password</label>
                                                <div class="input-group">
                                                    <input type="password" class="form-control" placeholder="Current Password" />
                                                    <button type="button" class="btn btn-light"><i class='bx bxs-show'></i></button>
                                                </div>
                                            </div>
                                            <div class="mb-3">
                                                <label class="form-label">New Password</label>
                                                <div class="input-group">
                                                    <input type="password" class="form-control" placeholder="New Password" />
                                                    <button type="button" class="btn btn-light"><i class='bx bxs-show'></i></button>
                                                </div>
                                            </div>
                                            <div class="mb-3">
                                                <label class="form-label">Retype Password</label>
                                                <div class="input-group">
                                                    <input type="password" class="form-control" placeholder="Retype Password" />
                                                    <button type="button" class="btn btn-light"><i class='bx bxs-show'></i></button>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <button type="button" class="btn btn-light text-main">Cancel</button>
                                                <button type="button" class="btn btn-main">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-access" role="tabpanel" aria-labelledby="nav-access-tab"
                            tabindex="0">
                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="card plain-card">
                                        <div class="card-header">Redeem a Product Access Code
                                            <p class="mb-0 mt-1 fw-normal small text-muted">If you have purchased a BoardVitals plan that has yet to be activated, please input your access code below to begin your subscription</p>
                                        </div>
                                        <div class="card-body">
                                            <div class="mb-3">
                                                <label class="form-label">Enter Access Code</label>
                                                <input type="text" class="form-control" placeholder="Enter Access Code" />
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <button type="button" class="btn btn-light text-main">Cancel</button>
                                                <button type="button" class="btn btn-main">Get Access</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {AddLibrary('/assets/js/main.js')}
        </React.Fragment>
    )
}

export default Settings;