
import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Dashboard(props) {
    const [subjects, setSubjects] = useState([])
    const breadcrumb = props.nm
    //const [sid,setSid] = useState('');
    const [logstatus, setLogstatus] = useState(() => {
        const st = localStorage.getItem("login");
        return st || ""
    });
    const [uid, setUid] = useState(() => {
        const st = localStorage.getItem("info");
        return st || ""
    });

    const [sid, setSid] = useState(() => {
        const st = localStorage.getItem("subid");
        return st || ""
    });

    function isLog() {
        if (logstatus == "") {
            window.location.href = "/login";
        }
        else {
            const sts = localStorage.getItem('status');
            if (sts) {
                toast('Login Successfull');
                localStorage.removeItem('status');
            }
            const msg = localStorage.getItem('msg')
            if (msg) {
                toast(msg);
                localStorage.removeItem('msg');
            }
        }
    }

    function AddCC(urlOfTheLibrary) {
        const script = document.createElement('link');
        script.href = urlOfTheLibrary;
        script.rel = "stylesheet";
        script.type = "text/css";
        document.head.appendChild(script);
    }
    function handleQb(e) {
        if (e.target.value != "All")
            localStorage.setItem('subid', e.target.value)
        else {
            localStorage.setItem('subid', "");
        }
        setSid(e.target.value);

        console.log(e.target.value)
        window.location.reload();
    }
    useEffect(() => {
        isLog()

    }, [])
    return (
        <React.Fragment>
            {AddCC('/assets/css/Sidebar.css')}
            <ToastContainer />
            <header className="header">
                <div className="header__container border-bottom">
                    <img src="/assets/images/ENT-Final-Logo.png" alt="" className="header__img" />
                    <a href="/" className="header__logo">
                        <img src="/assets/images/ENT-Final-Logo.png" alt="" />
                    </a>

                    {/* <div>
                        <a href="#" className="btn btn-outline-secondary btn-sm">
                            <i className='bx bx-help-circle'></i>
                        </a>
                    </div> */}
                    <div className="header__toggle">
                        <i className="bx bx-menu" id="header-toggle"></i>
                    </div>
                </div>
                <div className="breadcrumb__container">
                    <div className="d-none d-lg-block">
                        <div className="row w-100 justify-content-between align-items-center">
                            <div className="col-sm-6">
                                <div className="d-flex align-items-center">
                                    <div className="border-end border-secondary pe-3 me-3 fw-bold">{breadcrumb}</div>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0">
                                            <li className="breadcrumb-item"><a href="/dashboard"></a></li>
                                            {/* <!-- <li className="breadcrumb-item active" aria-current="page">Library</li> --> */}
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                {/* <div className="d-flex align-items-center">
                            <div className="header__search me-3">
                                <select className="header__input">
                                    <option value="">Browse Question</option>
                                </select>
                            </div>
                            <div className="header__search searchbox">
                                <input type="search" placeholder="Search" className="header__input"/>
                                <i className="bx bx-search header__icon"></i>
                            </div>
                        </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div id="sidebar">
                <div className="nav" id="navbar">
                    <nav className="nav__container">
                        <div>
                            <a href="#" className="nav__link nav__logo">
                                <i className="bx bxs-disc nav__icon"></i>
                                <span className="nav__logo-name">ENTMCQ</span>
                            </a>
                            <div className="nav__list">
                                <div className="nav__items">
                                    <h3 className="nav__subtitle">Menu</h3>
                                    <a href="/questioner_dashboard" className="nav__link active">
                                        <i className="bx bx-home nav__icon"></i>
                                        <span className="nav__name">Dashboard</span>
                                    </a>
                                    <div className="nav__dropdown">
                                        <a href="#" className="nav__link">
                                            <i className='bx bx-test-tube nav__icon'></i>
                                            <span className="nav__name">Questions</span>
                                            <i className="bx bx-chevron-down nav__icon nav__dropdown-icon"></i>
                                        </a>
                                        <div className="nav__dropdown-collapse">
                                            <div className="nav__dropdown-content">
                                                <a href="/view_questions" className="nav__dropdown-item">View All</a>
                                                {/* <a href="/questions/active" className="nav__dropdown-item">View Accepted</a>
                                                <a href="/questions/inactive" className="nav__dropdown-item">View Declined</a>
                                                <a href="/questions/pending" className="nav__dropdown-item">View Pending</a> */}
                                                <a href="/add_questions" className="nav__dropdown-item">Add Questions</a>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="nav__items">
                                    <h3 className="nav__subtitle">Profile</h3>
                                    <div className="nav__dropdown">
                                        <a href="#" className="nav__link">
                                            <i className="bx bx-cog nav__icon"></i>
                                            <span className="nav__name">Settings</span>
                                            <i className="bx bx-chevron-down nav__icon nav__dropdown-icon"></i>
                                        </a>
                                        <div className="nav__dropdown-collapse">
                                            <div className="nav__dropdown-content">
                                                <a href="/questioner/profile" className="nav__dropdown-item">Profile</a>
                                                {/* <a href="questioner/profile" className="nav__dropdown-item">My Earning</a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a href="/logout" className="nav__link nav__logout">
                            <i className="bx bx-log-out nav__icon"></i>
                            <span className="nav__name">Log Out</span>
                        </a>
                    </nav>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Dashboard;