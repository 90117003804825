import "./Pricing.css";
import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { BiCheckboxSquare } from "react-icons/bi";

function Pricing() {
  function AddCC(urlOfTheLibrary) {
    const script = document.createElement('link');
    script.href = urlOfTheLibrary;
    script.rel ="stylesheet";
    script.type="text/css";
    document.head.appendChild(script);
  }
  return (
    <React.Fragment>
      {AddCC('/assets/css/pricing.css')}
      {AddCC('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css')}
      <div class="container-fluid">
        <div class="row">
          <Header />

        </div>
      </div>
      {/* <div class="container-fluid" id="aboutus">
        <div class="row">
          <div class="col-sm-12">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-sm-12 d-flex justify-content-between">
                  <h1>Services Available</h1>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0" id="breadcrumb">
                      <li class="breadcrumb-item"><a href="/">Home</a></li>
                      <li class="breadcrumb-item active" aria-current="page">Subscribe</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <section>
        <div class="container py-5">

          <header class="text-center mb-5 text-white">
            <div class="row">
              <div class="col-lg-7 mx-auto" style={{color:'black'}}>
                <h1>Services Available</h1>
                <p>Prepare For The FRCS(ORL-HNS) Section-I Examination.</p>
              </div>
            </div>
          </header>



          <div class="row text-center align-items-end">

            <div class="col-lg-4 mb-5 mb-lg-0 pricingTable">
              <div class="bg-white p-5 rounded-lg shadow">
                <h1 class="h6 text-uppercase font-weight-bold mb-4">Basic</h1>
                <h2 class="h1 font-weight-bold">£199<span class="text-small font-weight-normal ml-2">/ month</span></h2>

                <div class="custom-separator my-4 mx-auto bg-primary-color"></div>

                <ul class="list-unstyled my-5 text-small text-left">
                  <li class="mb-3">
                    <i class="fa fa-check mr-2 text-primary"></i> Lorem ipsum dolor sit amet</li>
                  <li class="mb-3">
                    <i class="fa fa-check mr-2 text-primary"></i> Sed ut perspiciatis</li>
                  <li class="mb-3">
                    <i class="fa fa-check mr-2 text-primary"></i> At vero eos et accusamus</li>
                  <li class="mb-3 text-muted">
                    <i class="fa fa-times mr-2"></i>
                    <del>Nam libero tempore</del>
                  </li>
                  <li class="mb-3 text-muted">
                    <i class="fa fa-times mr-2"></i>
                    <del>Sed ut perspiciatis</del>
                  </li>
                  <li class="mb-3 text-muted">
                    <i class="fa fa-times mr-2"></i>
                    <del>Sed ut perspiciatis</del>
                  </li>
                </ul>
                <a href="#" class="btn btn-primary btn-block p-2 shadow rounded-pill">Subscribe</a>
              </div>
            </div>
            <div class="col-lg-4 mb-5 mb-lg-0">
              <div class="bg-white p-5 rounded-lg shadow">
                <h1 class="h6 text-uppercase font-weight-bold mb-4">Pro</h1>
                <h2 class="h1 font-weight-bold">£399<span class="text-small font-weight-normal ml-2">/ month</span></h2>

                <div class="custom-separator my-4 mx-auto bg-primary-color"></div>

                <ul class="list-unstyled my-5 text-small text-left font-weight-normal">
                  <li class="mb-3">
                    <i class="fa fa-check mr-2 text-primary"></i> Lorem ipsum dolor sit amet</li>
                  <li class="mb-3">
                    <i class="fa fa-check mr-2 text-primary"></i> Sed ut perspiciatis</li>
                  <li class="mb-3">
                    <i class="fa fa-check mr-2 text-primary"></i> At vero eos et accusamus</li>
                  <li class="mb-3">
                    <i class="fa fa-check mr-2 text-primary"></i> Nam libero tempore</li>
                  <li class="mb-3">
                    <i class="fa fa-check mr-2 text-primary"></i> Sed ut perspiciatis</li>
                  <li class="mb-3 text-muted">
                    <i class="fa fa-times mr-2"></i>
                    <del>Sed ut perspiciatis</del>
                  </li>
                </ul>
                <a href="#" class="btn btn-primary btn-block p-2 shadow rounded-pill">Subscribe</a>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="bg-white p-5 rounded-lg shadow">
                <h1 class="h6 text-uppercase font-weight-bold mb-4">Enterprise</h1>
                <h2 class="h1 font-weight-bold">£899<span class="text-small font-weight-normal ml-2">/ month</span></h2>

                <div class="custom-separator my-4 mx-auto bg-primary"></div>

                <ul class="list-unstyled my-5 text-small text-left font-weight-normal">
                  <li class="mb-3">
                    <i class="fa fa-check mr-2 text-primary"></i> Lorem ipsum dolor sit amet</li>
                  <li class="mb-3">
                    <i class="fa fa-check mr-2 text-primary"></i> Sed ut perspiciatis</li>
                  <li class="mb-3">
                    <i class="fa fa-check mr-2 text-primary"></i> At vero eos et accusamus</li>
                  <li class="mb-3">
                    <i class="fa fa-check mr-2 text-primary"></i> Nam libero tempore</li>
                  <li class="mb-3">
                    <i class="fa fa-check mr-2 text-primary"></i> Sed ut perspiciatis</li>
                  <li class="mb-3">
                    <i class="fa fa-check mr-2 text-primary"></i> Sed ut perspiciatis</li>
                </ul>
                <a href="#" class="btn btn-primary btn-block p-2 shadow rounded-pill">Subscribe</a>
              </div>
            </div>

          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  )
}

export default Pricing;
