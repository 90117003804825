import React, { useState, useEffect } from "react";
import Dashboard from "./Dashboard";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import axios from "axios";
var cnt = 0;
var CryptoJS = require("crypto-js");


ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale,
    LinearScale,
    PointElement,
    LineElement, Title,);



function Home() {
    const [ans, setAns] = useState(0);
    const [unsen, setUnsen] = useState(0);
    const [wrong, setWrong] = useState(0);
    const [cntans, setCntAns] = useState(0);
    const [cntunsen, setCntUnsen] = useState(0);
    const [cntwrong, setCntWrong] = useState(0);
    const [quiz, setQuiz] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const [wpercentage, setWPercentage] = useState(0);
    const [upercentage, setUPercentage] = useState(0);
    const name = localStorage.getItem('name').split(" ");
    const [uid, setUid] = useState(() => {
        const st = localStorage.getItem("info");
        return st || ""
    });
    const [scode, setScode] = useState(() => {
        const st = localStorage.getItem("scode");
        return st || "trial"
    });
    function fetchData() {
        const id = localStorage.getItem('info');
        //const id=5;
        axios.get('https://api.entmcq.com/api/fetchQuiz', { headers: { "Authorization": `Bearer ${uid}` } })
            .then((res) => {
                const data = res.data;
                setCntAns(data.ans)
                setCntUnsen(data.unsen);
                setCntWrong(data.wrong);
                localStorage.setItem("ans", JSON.stringify(data));
                console.log(data)
                if (data.length > 0) {
                    console.log(data.ans)

                }
                var p = (parseFloat(data.ans) / parseFloat(parseFloat(data.total))) * 100;
                var uper = (parseFloat(data.unsen) / parseFloat(parseFloat(data.total))) * 100;
                var wp = (parseFloat(data.wrong) / parseFloat(parseFloat(data.total))) * 100;

                if (isNaN(p)) {
                    setPercentage(parseInt(p));
                    setAns(0);
                }

                else
                    setAns(p.toFixed());
                if (isNaN(uper))
                    setUnsen(0);
                else
                    setUnsen(uper.toFixed());
                if (isNaN(wp))
                    setWrong(0)
                else
                    setWrong(wp.toFixed());

                //console.log(percentage);
                //console.log(data.length)
            })
    }
    function fetchQuiz() {
        const id = localStorage.getItem('info');
        // const sid = localStorage.getItem('scode')
        // if(!sid){
        //     window.location.href="/no-subscription";
        // }
        // console.log(scode);
        // console.log('https://entmcq.vertextechnosys.com/api/fetchQuizzes/'+sid+'--'+id)
        axios.get('https://api.entmcq.com/api/fetchQuizzes', { headers: { "Authorization": `Bearer ${uid}` } })
            .then((res) => {
                const data = res.data;
                var temp = []
                cnt = 0;
                data.map((obj) => {
                    cnt++;
                    if (cnt <= 5)
                        temp.push(obj);
                })
                setQuiz(temp);
            })
    }
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Line Chart',
            },
        },
    };
    const data = {
        labels: ['Correct', 'Incorrect', 'Unanswered'],
        datasets: [
            {
                label: 'Performance',
                data: [ans, wrong, unsen],
                backgroundColor: [
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(13, 110, 253, 0.2)',
                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    'rgba(255, 99, 132, 1)',
                    'rgba(13, 110, 253, 1)',


                ],
                borderWidth: 1,
            },
        ],
    };

    function handleClick(val) {
        console.log(val);
        localStorage.setItem('quiz_id', val);
        window.location.href = '/dashboard/quiz'
    }
    function handleUnlock() {
        var sid = localStorage.getItem("subid");
        console.log("sid", sid);
        if (sid != null) {
            var id = CryptoJS.AES.encrypt(sid, 'QB@123').toString();
            id = id.replace(/\//g, '__')
            window.location.href = "/subscribe"
        }
        else {
            window.location.href = '/specialist';
        }

    }
    function fetchSubjects() {
        axios.get('https://api.entmcq.com/api/fetchSubscription', { headers: { "Authorization": `Bearer ${uid}` } })
            .then((res) => {
                const data = res.data.sub;
                // console.log(data)

                if (data.length > 0) {
                    let dt = new Date()
                    let edt = new Date(data[0].sub_edate);
                    var time_difference = edt.getTime() - dt.getTime();

                    //calculate days difference by dividing total milliseconds in a day  
                    var days_difference = parseInt(time_difference / (1000 * 60 * 60 * 24));
                    localStorage.setItem('remaindays',days_difference);
                    if (days_difference>0) {
                        localStorage.setItem('scode', data[0].sub_code);
                        fetchQB(data[0].sub_code)
                        // window.location.reload();
                    } else {
                        localStorage.setItem('scode', 'no');
                        window.location.href = "/no-subscription";
                    }
                }
                else {
                    localStorage.setItem('scode', 'no');
                    window.location.href = "/no-subscription";
                }
                //setSubjects(res.data.cate);
            })
    }
    function fetchQB(scode) {
        axios.get('https://api.entmcq.com/api/fetchQbData/' + scode, { headers: { "Authorization": `Bearer ${uid}` } })
            .then((resp) => {
                var data = resp.data;
                console.log(data);
                data.map((obj) => {
                    if (obj.qname == 'mock') {
                        var ndata = JSON.parse(obj.sub_id);
                        var n = 0;
                        ndata.map((nobj) => {
                            n += parseInt(nobj.n);
                        })
                        var info = { scode: scode, total: n, tm: obj.time }
                        localStorage.setItem('mock', JSON.stringify(info));
                        console.log(info);
                    }
                    if (obj.qname == 'Quick') {
                        var ndata = JSON.parse(obj.sub_id);
                        var n = 0;
                        ndata.map((nobj) => {
                            n += parseInt(nobj.n);
                        })
                        var info = { scode: scode, total: n, tm: obj.time }
                        localStorage.setItem('quick', JSON.stringify(info));
                    }
                })
            })
    }
    function quizMeta(id) {
        // const qid = localStorage.getItem('subid');
        // const uid = localStorage.getItem('info');
        // axios.get('https://api.entmcq.com/api/fetchSubjectQuiz/' + id,{headers:{"Authorization" : `Bearer ${uid}`}})
        //     .then((resp) => {
        //         console.log(resp.data);
        //         setShow(true)
        //         setSubInfo(resp.data);
        //         setAns(resp.data[0].ans)
        //         setUnsen(resp.data[0].unsen)
        //         setWrong(resp.data[0].wrong)
        //         setTotal(resp.data[0].total);
        //         setTitle(resp.data[0].subname)
        //         // console.log(resp.data);
        //     })

        const qid = id;
        const uid = localStorage.getItem('info');
        axios.get('https://api.entmcq.com/api/fetchQzQs/' + qid, { headers: { "Authorization": `Bearer ${uid}` } })
            .then((res) => {
                const data = res.data;
                // console.log(data);
                localStorage.setItem("qdata", JSON.stringify(data[0].quiz_details))
                localStorage.setItem("bank", JSON.stringify(data[1].bank));
                window.location.href = "/dashboard/review"
                // localStorage.setItem(data[0].quiz_details)
                // var bankdata = data[1].bank;
            })
    }
    useEffect(() => {
        fetchSubjects();
        fetchData();
        fetchQuiz();
    }, []);
    function AddLibrary(urlOfTheLibrary) {
        const script = document.createElement('script');
        script.src = urlOfTheLibrary;
        script.async = true;
        document.body.appendChild(script);
    }
    return (
        <React.Fragment>
            <Dashboard nm={"Welcome to your Dashboard, " + name[0]} />
            <main>
                {scode == "Trial" && (<div class="card card-body bg-danger border-0 bg-opacity-10 text-danger mb-3">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="demo-version">
                            <p class="mb-0">You are don't have active subscription.</p>
                            <h5 class="mb-0">Unlock Full Access</h5>
                        </div>
                        <button type="button" class="btn btn-danger btn-sm" onClick={() => { handleUnlock() }}>Unlock Now</button>
                    </div>
                </div>)}

                <div class="row mb-3 gx-2">
                    <div class="col-sm-5">
                        <div class="card plain-card h-100">
                            <div class="card-header">Start Quiz</div>
                            <div class="card-body mb-4">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="quiz-details">
                                        <p>Custom Quiz</p>
                                        <small>Select your subjects, no. of questions, and more.</small>
                                    </div>
                                    <a href="/dashboard/custom-quiz" class="btn btn-main">Start Quiz</a>
                                </div>
                            </div>
                            <hr class="my-0" />
                            <div class="card-body mb-4">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="quiz-details">
                                        <p>Exam Mode Quiz</p>
                                        <small>Random questions, all subjects, test mode.</small>
                                    </div>
                                    <a href="/dashboard/mock-quiz" class="btn btn-main">Mock Quiz</a>
                                </div>
                            </div>
                            <hr class="my-0" />
                            <div class="card-body mb-4">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="quiz-details">
                                        <p>Quick Start Quiz</p>
                                        <small>10 unanswered questions, all subjects, tutor mode.</small>
                                    </div>
                                    <a href="/dashboard/quick-quiz" class="btn btn-main">Start Quiz</a>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-sm-4">


                            </div>
                            <div class="col-sm-4">



                            </div>
                            <div class="col-sm-4">


                            </div>
                        </div>
                    </div>
                    <div class="col-sm-7">
                        <div class="card plain-card h-100">
                            <div class="card-header">Question Status</div>
                            <div class="card-body text-center">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="card card-body bg-success bg-opacity-10 text-success border-0 mb-2">
                                            <p class="mb-1">{ans} % </p>
                                            <p class="mb-0">Correct</p>
                                        </div>
                                        <div class="card card-body bg-danger bg-opacity-10 text-danger border-0 mb-2">
                                            <p class="mb-1">{wrong} % </p>
                                            <p class="mb-0">Incorrect</p>
                                        </div>
                                        <div class="card card-body bg-primary bg-opacity-10 text-primary border-0 mb-2">
                                            <p class="mb-1">{unsen} % </p>
                                            <p class="mb-0">Unanswered</p>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="card card-body bg-success bg-opacity-10 text-success border-0 mb-2">
                                            <p class="mb-1">{cntans}  </p>
                                            <p class="mb-0">Correct</p>
                                        </div>
                                        <div class="card card-body bg-danger bg-opacity-10 text-danger border-0 mb-2">
                                            <p class="mb-1">{cntwrong}  </p>
                                            <p class="mb-0">Incorrect</p>
                                        </div>
                                        <div class="card card-body bg-primary bg-opacity-10 text-primary border-0 mb-2">
                                            <p class="mb-1">{cntunsen}  </p>
                                            <p class="mb-0">Unanswered</p>
                                        </div>
                                    </div>
                                    {/* <div class="col-sm-2">
                                        
                                    </div> */}
                                    <div class="col-sm-4">
                                        <div class="plain-card h-40">
                                            <div class="card-header">Score</div>
                                            <div class="card-body">

                                                <Doughnut data={data} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div class="col-sm-3">
                        <div class="card plain-card h-100">
                            <div class="card-header">Score</div>
                            <div class="card-body">

                                <Doughnut data={data} />
                            </div>
                        </div>
                    </div> */}
                </div>
                <div class="row gx-2">
                    <div class="col-sm-9">
                        <div class="card h-100 plain-card">
                            <div class="card-header">My Recent Quizzes</div>
                            <div class="card-body">
                                <table class="table dashboard-table">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Created</th>
                                            <th>Correct</th>
                                            <th>Incorrect</th>
                                            <th>Unanswered</th>
                                            <th>No. of Q's</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            quiz.map((obj) => {
                                                cnt++
                                                return (
                                                    <tr>
                                                        <td>
                                                            <p class="mb-2"><b>[{obj.mode}]</b> {obj.qname}</p>
                                                            {/* <div class="progress" style={{height: 5}}>
                                                            <div class="progress-bar" role="progressbar" aria-label="Example 1px high"
                                                                style={{width: percentage+'%'}} aria-valuenow="25" aria-valuemin="0"
                                                                aria-valuemax="100"></div>
                                                        </div> */}
                                                        </td>
                                                        <td>{obj.dt}</td>
                                                        <td>{obj.ans}</td>
                                                        <td>{obj.wrong}</td>
                                                        <td>{obj.unsen}</td>
                                                        <td>{obj.nqs}</td>
                                                        <td>
                                                            {obj.status == 'completed' ? (<button type="button"
                                                                class="btn bg-success bg-opacity-10 text-main btn-sm" onClick={() => {
                                                                    quizMeta(obj.id)
                                                                }}>Summary</button>) : (<button type="button"
                                                                    class="btn bg-main-light text-main btn-sm" onClick={() => { handleClick(obj.id) }}>Continue</button>)}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        {/* <tr>
                                    <td>
                                        <p class="mb-2"><b>[Test]</b> Bioanalysis (ABB) 08-03-2022 Trial</p>
                                        <div class="progress" style={{height: 5}}>
                                            <div class="progress-bar" role="progressbar" aria-label="Example 1px high"
                                                style={{width: 25+'%'}} aria-valuenow="25" aria-valuemin="0"
                                                aria-valuemax="100"></div>
                                        </div>
                                    </td>
                                    {/* <td>Aug 3, 2022 8:19AM</td> 
                                    <td>3</td>
                                    <td>3</td>
                                    <td>3</td>
                                    <td>3</td>
                                    <td>
                                        <button type="button"
                                            class="btn bg-main-light text-main btn-sm">Continue</button>
                                    </td>
                                </tr> */}
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer text-end">
                                <a href="/dashboard/my-quiz">See More</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="card h-100 plain-card">
                            <div class="card-header">Performance</div>
                            <div class="card-body">
                                <Line data={data} />
                            </div>
                            <div class="card-footer text-end">
                                <a href="/dashboard/performance-timeline">Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {AddLibrary('assets/js/main.js')}
        </React.Fragment>
    )
}
export default Home;